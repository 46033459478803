import { Grid, Typography, useTheme, Link, Button } from '@mui/material'
import { IoWarningOutline } from 'react-icons/io5'
import { FormDefaultState, learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import { MdNavigateBefore } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { FormType } from '../utils/sharedTypes'
import { Dispatch, SetStateAction, useEffect } from 'react'

type NotFundableProps = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const NotFundable = ({ form, setForm }: NotFundableProps) => {
  const navigate = useNavigate()
  const theme = useTheme()

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        steps: prevState.steps.filter((step) => step.url !== 'recapitulatif'),
      }
    })

    return () => {
      setForm((prevState) => {
        return {
          ...prevState,
          steps: FormDefaultState.steps,
        }
      })
    }
  }, [])

  const phoneNumber = form.data.theme === 'sante' ? santePhoneNumber : learningPhoneNumber

  return (
    <>
      <Grid
        container
        sx={{ background: theme.palette.warning.light, p: 2, borderRadius: '4px', marginTop: '10px' }}
        wrap="nowrap"
      >
        <Grid item sx={{ mr: 1 }}>
          <IoWarningOutline style={{ color: theme.palette.warning.main }} size="24" />
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="body1" sx={{ pb: 1, color: theme.palette.warning.dark, fontWeight: 'bold' }}>
              Votre profession ne vous permet pas de bénéficier d’un financement pour cette formation.
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.warning.dark }}>
              Notre équipe d’accompagnement va vous contacter pour étudier d’autres solutions de financement.
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.warning.dark }}>
              Vous pouvez aussi directement nous joindre au{' '}
              <Link href={`tel:+33${phoneNumber.slice(1)}`}>{phoneNumber.match(/.{2}/g)?.join(' ')}</Link> ou à
              l’adresse <Link href="mailto:help@walter-learning.com">help@walter-learning.com</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          disabled={location.pathname.includes('/information')}
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate(-1)
          }}
        >
          Retour
        </Button>
      </Grid>
    </>
  )
}

export default NotFundable
