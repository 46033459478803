import { useEffect, useState } from 'react'
import { Stepper as MuiStepper, Step, StepLabel, Typography, styled, useTheme, useMediaQuery } from '@mui/material'
import StepConnector from '@mui/material/StepConnector'
import { MdCheck } from 'react-icons/md'
import { StepIconProps } from '@mui/material/StepIcon'
import { StepType } from '../utils/sharedTypes'

const StepLineConnector = styled(StepConnector)(() => ({
  marginLeft: '0!important',
  paddingLeft: 3,
  marginRight: 10,
}))

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.primary.main,
  }),
  '& .StepIcon-completedIcon': {
    color: theme.palette.success.main,
    alignItems: 'left',
    marginLeft: '-4px',
    zIndex: 1,
    fontSize: 18,
  },
  '& .StepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}))

const StepIcon = (props: StepIconProps) => {
  const theme = useTheme()
  const { active, completed, className } = props
  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <MdCheck className="StepIcon-completedIcon" />
      ) : (
        <div
          className="StepIcon-circle"
          style={{ background: active ? theme.palette.primary.dark : theme.palette.grey[200] }}
        />
      )}
    </StepIconRoot>
  )
}

type StepperPropsType = {
  steps: StepType[]
}

const Stepper = ({ steps }: StepperPropsType) => {
  const [activeStep, setActiveStep] = useState(0)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'))

  useEffect(() => {
    let index = 0
    steps.map((step, i) => {
      if (location.pathname.includes(step.url)) {
        index = i
      }
    })
    setActiveStep(index)
  }, [location.pathname, steps])

  return (
    <MuiStepper
      sx={{ mt: { xs: 2, md: 4 } }}
      activeStep={activeStep}
      orientation={isSmall ? 'horizontal' : 'vertical'}
      connector={<StepLineConnector />}
    >
      {steps.map((step, index) => (
        <Step key={step.label} completed={index < activeStep}>
          <StepLabel StepIconComponent={StepIcon}>
            <Typography
              variant="body1"
              sx={{
                ml: { xs: 0, md: 1 },
                color: activeStep === index ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.6)',
                display: { xs: 'none', sm: 'block' },
              }}
            >
              {step.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  )
}

export default Stepper
