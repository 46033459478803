import { Divider, Grid, Typography, Button, useTheme } from '@mui/material'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { FormType } from '../utils/sharedTypes'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { FormDefaultState } from '../utils/constants'
import CpfStatus from './cpf/CpfStatus'

type CpfPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const CPF_IDN_Status = ['Oui', 'Non', 'Je ne sais pas']

const Cpf = ({ form, setForm }: CpfPropsType) => {
  const theme = useTheme()

  const handleChange = (key: string, value: string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        steps: prevState.steps.filter((step) => step.url !== 'recapitulatif'),
      }
    })

    return () => {
      setForm((prevState) => {
        return {
          ...prevState,
          steps: FormDefaultState.steps,
        }
      })
    }
  }, [])

  return (
    <Grid>
      <Grid>
        <Typography variant="body1" fontWeight="500">
          Disposez-vous d'un compte Identité Numérique La Poste ?
        </Typography>
        <Typography variant="caption">
          Le compte Identité Numérique La Poste est indispensable pour vous inscrire à une formation en utilisant votre
          CPF
        </Typography>
        <Grid item container spacing={1} sx={{ justifyContent: 'flex-start', mt: 2, mb: 4 }}>
          {CPF_IDN_Status.map((idn) => {
            return (
              <Grid item key={idn}>
                <Button
                  variant={form.data.cpf_idn_status === idn ? 'contained' : 'outlined'}
                  fullWidth
                  onClick={() => {
                    handleChange('cpf_idn_status', idn)
                  }}
                  sx={{
                    height: 56,
                  }}
                >
                  {idn}
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      {CPF_IDN_Status.filter((idn) => idn !== 'Oui').includes(form.data.cpf_idn_status) && (
        <Grid container sx={{ background: theme.palette.info.light, p: 2, borderRadius: '4px', mb: 4 }} wrap="nowrap">
          <Grid item sx={{ mr: 1 }}>
            <IoMdInformationCircleOutline style={{ color: 'white' }} size="24" />
          </Grid>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1" sx={{ pb: 1, color: theme.palette.info.contrastText, fontWeight: 'bold' }}>
                L'Identité Numérique La Poste, c'est quoi ?
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.info.contrastText }}>
                Depuis 2022, dans le cadre de la lutte anti-fraude, les inscriptions sur le CPF requièrent une
                authentification renforcée. Pour utiliser votre CPF, vous devez donc disposer d'un compte Identité
                Numérique auprès de La Poste. La démarche ci-dessous vous indique comment faire.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      <>
        <Divider sx={{ my: 2.5 }} />

        <Grid container direction="column">
          <CpfStatus form={form} setForm={setForm} />
        </Grid>
      </>
    </Grid>
  )
}

export default Cpf
