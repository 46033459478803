import Bugsnag from '@bugsnag/js'
import { Button, CircularProgress, Grid, Modal, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { BsPlusLg } from 'react-icons/bs'
import { MdNavigateBefore } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { DPCContract, Formtitle, InvoiceForm, Layout, Rac, StripeContract, StripeModal } from '../components'
import FundingSummary from '../components/FundingSummary'
import ProductSummary from '../components/ProductsSummary'
import { client } from '../utils/client'
import { FormType, NormalFormData } from '../utils/sharedTypes'
import AlmaContract from '../components/AlmaContract'

type RecapPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

declare const window: Window & {
  Alma: Alma
}

type Alma = {
  InPage: {
    initialize: (data: AlmaInitialize) => AlmaInPage
  }
}

type AlmaInitialize = {
  merchantId?: string
  amountInCents?: number
  installmentsCount?: number
  selector: string
  environment?: 'PROD' | 'TEST'
}

type AlmaInPage = {
  startPayment: (data: AlmaStartPayment) => void
  unmount: () => void
}

type AlmaStartPayment = {
  paymentId: string
  onPaymentSucceeded: () => void
  onPaymentRejected: () => void
  onUserCloseModal: () => void
}

const Recap = ({ form, setForm }: RecapPropsType) => {
  const [stripeClientSecret, setStripeClientSecret] = useState<string>()
  const [inPage, setInPage] = useState<AlmaInPage | undefined>(undefined)
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [billingInfoComplete, setBillingInfoComplete] = useState(
    Boolean(form.data.invoice_company_name || (form.data.invoice_first_name && form.data.invoice_last_name)) &&
      Boolean(form.data.invoice_street && form.data.invoice_postal_code && form.data.invoice_city),
  )
  const [paymentUrl, setPaymentUrl] = useState('')
  const [openFailedPaymentModal, setOpenFailedPaymentModal] = useState(false)

  const hasProducts = form.data.products.length > 0 && form.data.product === null
  const listing_price = hasProducts
    ? form.data.funding_organism_registrations.reduce((acc, r) => acc + r.product_listing_price, 0)
    : form.data.product
    ? form.data.product.listing_price
    : 0

  const shouldPay =
    form.data.funding_organism?.code == 'ALMA' ||
    form.data.funding_organism?.code == 'STRIPE' ||
    (form.data.personal_funding !== null && form.data.personal_funding > 0) ||
    (form.data.personal_funding === null && Boolean(form.data.default_personal_funding))

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (stripeClientSecret) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [stripeClientSecret])

  const session = form.sessions.find((session) => session.global_id === form.data.session?.global_id)

  const submitForm = () => {
    console.log('submitForm', form.data.funding_organism?.code)
    console.log('shouldPay', shouldPay)
    setLoading(true)
    if (shouldPay) {
      if (form.data.funding_organism?.code == 'STRIPE') {
        client
          .post(`/registration/${form.data.global_id}/create_stripe_checkout_session/`, form.data)
          .then((resp: { data: { clientSecret: string } }) => {
            console.log('resp.data.clientSecret', resp.data.clientSecret)
            setStripeClientSecret(resp.data.clientSecret)
          })
          .catch((err) => console.error(err))
      } else if (form.data.funding_organism?.code == 'BANK_TRANSFER') {
        client
          .patch(`/registration/${form.data.global_id}/submit/`, form.data)
          .then((resp) => {
            if (resp.status === 200) {
              navigate({ pathname: './../virement', search: location.search })
            }
          })
          .catch((err: Error) => {
            Bugsnag.notify(err)
            console.error(err)
          })
          .finally(() => {
            setLoading(false)
          })
      } else {
        client
          .post(`/registration/${form.data.global_id}/pay_alma/`, form.data)
          .then((resp) => {
            const data = resp.data as NormalFormData
            try {
              inPage?.startPayment({
                paymentId: data.alma_payment_id,
                onPaymentSucceeded: () => {
                  console.log('onPaymentSucceeded')
                  navigate({ pathname: './../alma', search: `${location.search}&success` })
                },
                onPaymentRejected: () => {
                  console.log('onPaymentRejected')
                  navigate({ pathname: './../alma', search: `${location.search}&cancel` })
                },
                onUserCloseModal: () => {
                  console.log('onUserCloseModal')
                  navigate({ pathname: './../alma', search: `${location.search}&cancel` })
                },
              })
            } catch (e) {
              window.open(data.alma_payment_url, '_self')
              setPaymentUrl(data.alma_payment_url)
              setOpenFailedPaymentModal(true)
            }
          })
          .catch((err: Error) => {
            Bugsnag.notify(err)
            console.error(err)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    } else {
      console.log('ELSE')
      client
        .patch(`/registration/${form.data.global_id}/submit/`, form.data)
        .then((resp) => {
          if (resp.status === 200) {
            navigate({ pathname: './../recommandation', search: location.search })
          }
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const refetchRegistration = () => {
    client
      .get(`/registration/${form.data.global_id}/`)
      .then((resp) => {
        const data = resp.data as NormalFormData
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              ...data,
            },
          }
        })
        setReload(!reload)
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const isSubmitDisabled = () => {
    if (loading) return true
    if (shouldPay && (!billingInfoComplete || !inPage) && form.data.funding_organism?.code !== 'STRIPE') {
      return true
    }

    if (form.data.funding_organism?.code === 'STRIPE') {
      return !(form.data.stripe_contract_signed_at && billingInfoComplete && form.data.stripe_cgu_cgv)
    }

    if (form.data.funding_organism?.code === 'FIFPL') {
      return !(
        form.data.perso_program &&
        form.data.perso_cgu_cgv &&
        form.data.perso_signature &&
        form.data.perso_checkbox
      )
    }

    if (form.data.funding_organism?.code === 'DPC') {
      return !(form.data.dpc_cgu_cgv && form.data.dpc_contract_signed_at)
    }

    return false
  }

  useEffect(() => {
    if (
      form.data.funding_organism?.code === 'ALMA' ||
      (form.data.personal_funding !== null && Boolean(form.data.personal_funding)) ||
      (form.data.personal_funding === null && Boolean(form.data.default_personal_funding))
    ) {
      const externalScript = document.createElement('script')
      externalScript.src = 'https://cdn.jsdelivr.net/npm/@alma/in-page@2.x/dist/index.umd.js'
      externalScript.async = true
      externalScript.onload = () => {
        let amount = listing_price
        if (form?.data?.personal_funding) {
          amount = form.data.personal_funding
        } else if (form?.data?.default_personal_funding) {
          amount = form.data.default_personal_funding
        }
        const inPage = window.Alma.InPage.initialize({
          amountInCents: Math.round(amount * 100),
          installmentsCount: form.data.alma_installments_count ?? 1,
          selector: '#alma-in-page',
          environment: import.meta.env.VITE_ENV === 'PRODUCTION' ? 'PROD' : 'TEST',
        })
        setInPage(inPage)
      }
      document.head.appendChild(externalScript)
    }
  }, [form.data.funding_organism?.code, form.data.personal_funding, form.data.default_personal_funding, reload])

  return (
    <Layout
      form={form.data}
      steps={
        form.data.funding_organism?.code === 'STRIPE' ? form.steps.filter((s) => s.url !== 'financement') : form.steps
      }
    >
      <Modal
        open={openFailedPaymentModal}
        sx={{
          p: { xs: 1, md: 20 },
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
              sm: '90%',
              md: '600px',
            },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 5,
            borderRadius: '4px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              background: 'white',
              width: '100%',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                position: 'relative',
                top: 0,
                justifyContent: 'end',
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 40,
                  height: 40,
                  position: 'absolute',
                  background: 'white',
                  borderRadius: '50%',

                  top: -15,
                  transform: 'rotate(45deg)',

                  cursor: 'pointer',
                  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
                }}
                onClick={() => {
                  setOpenFailedPaymentModal(false)
                }}
              >
                <BsPlusLg />
              </Grid>
            </Grid>

            <Grid container direction="column" alignItems="center" item sx={{ maxHeight: '100%', overflow: 'scroll' }}>
              <Typography variant="h5" textAlign="center" fontWeight="600" mb={2}>
                Paiement
              </Typography>
              <Typography variant="body1" mb={2}>
                Vous allez être redirigé sur le site de paiement
              </Typography>

              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  window.open(paymentUrl, '_self')
                }}
              >
                Continuer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {(form.data.funding_organism?.code === 'ALMA' ||
        (form.data.personal_funding !== null && Boolean(form.data.personal_funding)) ||
        (form.data.personal_funding === null && Boolean(form.data.default_personal_funding))) && (
        <div
          id="alma-in-page"
          style={{
            width: 0,
            height: 0,
            display: 'none',
          }}
        />
      )}

      <Formtitle
        title="Récapitulatif"
        subtitle="Retrouvez ci-dessous le récapitulatif de votre inscription, vérifiez bien les informations indiquées avant de continuer"
      />
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">
              <i>Tous les prix indiqués ci-dessous sont TTC</i>
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="end">
            <Button variant="contained" size="medium" onClick={refetchRegistration}>
              Rafraichir
            </Button>
          </Grid>
        </Grid>

        <Grid container direction="column" spacing={4} mt={1}>
          <Grid item container>
            <Typography variant="h5" fontWeight="500" mb={3}>
              Vos informations personnelles
            </Typography>

            <Grid item container direction="row" justifyContent="space-between">
              <Typography variant="body2">NOM, Prénom</Typography>
              <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                {form.data.last_name?.toUpperCase() || '-'}, {form.data.first_name || '-'}
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between">
              <Typography variant="body2">Email</Typography>
              <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                {form.data.email}
              </Typography>
            </Grid>
            {form.data.dpc_rpps && (
              <Grid item container direction="row" justifyContent="space-between">
                <Typography variant="body2">N° RPPS</Typography>
                <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
                  {form.data.dpc_rpps}
                </Typography>
              </Grid>
            )}
          </Grid>

          {session && (
            <Grid item container direction="column">
              <Typography variant="h5" fontWeight="500" mb={3}>
                Votre session de formation
              </Typography>
              <Grid item>
                <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                  <Grid item>
                    <Typography variant="body2">Début le</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {session
                        ? form.sessions.indexOf(session) === 0 && new Date(session.starting_date) <= new Date()
                          ? "Dès aujourd'hui"
                          : new Date(session.starting_date).toLocaleDateString('fr', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric',
                            })
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2">A terminer avant le</Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      {session
                        ? session.display_ending_date
                          ? new Date(session.display_ending_date).toLocaleDateString('fr', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric',
                            })
                          : new Date(session.ending_date).toLocaleDateString('fr', {
                              day: '2-digit',
                              month: 'long',
                              year: 'numeric',
                            })
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {form.data.funding_organism?.code !== 'STRIPE' && (
            <Grid item container direction="column">
              <Typography variant="h5" fontWeight="500" mb={3}>
                Financement
              </Typography>
              <FundingSummary form={form} />
            </Grid>
          )}
        </Grid>
        {form.data.funding_organism?.code === 'FIFPL' && (
          <Grid item>
            <Rac form={form} setForm={setForm} />
          </Grid>
        )}
        {form.data.funding_organism?.code === 'DPC' && (
          <Grid item>
            <DPCContract form={form} setForm={setForm} reload={reload} />
          </Grid>
        )}
        {(form.data.funding_organism?.code === 'ALMA' || form.data.funding_organism?.code === 'BANK_TRANSFER') && (
          <Grid item>
            <AlmaContract form={form} setForm={setForm} reload={reload} />
          </Grid>
        )}

        {form.data.funding_organism?.code === 'STRIPE' && (
          <>
            {form.data.products.length > 0 && (
              <Grid sx={{ mt: 6 }}>
                <ProductSummary products={form.data.products} />
              </Grid>
            )}
            <Grid sx={{ mt: 6 }}>
              <StripeContract form={form} setForm={setForm} reload={reload} />
            </Grid>
          </>
        )}

        {shouldPay && (
          <Grid item my={5}>
            <InvoiceForm form={form} setForm={setForm} setBillingInfoComplete={setBillingInfoComplete} />
          </Grid>
        )}
        <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            variant="outlined"
            disabled={location.pathname.includes('/information')}
            sx={{
              height: {
                xs: 45,
              },
            }}
            startIcon={<MdNavigateBefore />}
            onClick={() => {
              navigate(-1)
            }}
          >
            Retour
          </Button>

          <Button variant="contained" onClick={submitForm} disabled={isSubmitDisabled()}>
            {loading ? <CircularProgress color="inherit" size={20} /> : shouldPay ? 'Payer' : 'Valider'}
          </Button>
        </Grid>
      </Grid>
      <StripeModal stripeClientSecret={stripeClientSecret} open={open} setOpen={setOpen} />
    </Layout>
  )
}

export default Recap
