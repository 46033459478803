import { Grid, Typography, useTheme, Link, Divider, useMediaQuery } from '@mui/material'
import { FormType } from '../../utils/sharedTypes'
import CallForm from '../CallForm'
import { useState, useRef } from 'react'
import IDNLogo from '../../assets/IDNLogo.png'
import IDNVerify from '../../assets/IDNVerify.png'

type CpfStatusNoPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const CpfStatusNo = ({ form, setForm }: CpfStatusNoPropsType) => {
  const tutorialRef = useRef<HTMLIFrameElement>(null)
  const [validated, setValidated] = useState(false)
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Grid>
        <Typography variant="body1" fontWeight="500">
          Créer votre Identité Numérique La Poste
        </Typography>
        <Typography variant="caption">
          N'hésitez pas à{' '}
          <Link
            onClick={() =>
              tutorialRef.current?.scrollIntoView({
                behavior: 'smooth',
              })
            }
          >
            visualiser le tuto vidéo Identité Numérique La Poste
          </Link>
        </Typography>
      </Grid>

      <Grid container direction="column" sx={{ mt: 4 }}>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="500">
              1
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="500">
              Munissez vous d'un document d'identité en cours de validité (carte d'identité, passeport, titre de séjour
              de plus de 5 ans)
            </Typography>

            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>

        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="500">
              2
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="500">
              Téléchargez l'application Identité Numérique par La Poste sur votre smartphone
            </Typography>
            <Typography variant="caption">
              Depuis votre téléphone, rendez-vous sur votre magasin d'application «&#160;<b>App Store</b>&#160;» ou
              «&#160;<b>Google Play</b>&#160;» et recherchez l'application «&#160;<b>L'Identité Numérique La Poste</b>
              &#160;»
            </Typography>
            <Grid
              container
              alignItems="center"
              sx={{
                mt: 1,
                mb: isMediumScreen ? -1.5 : 0,
              }}
            >
              <img
                src={IDNLogo}
                alt="Logo Identité Numérique"
                style={{
                  width: 47,
                  height: 47,
                  marginBottom: 5.6,
                  marginRight: 1,
                }}
              />

              {isMediumScreen && (
                <>
                  <a
                    href="https://apps.apple.com/fr/app/lidentit%C3%A9-num%C3%A9rique-la-poste/id1434857287?itsct=apps_box_badge&amp;itscg=30200"
                    target="_blank"
                  >
                    <img
                      alt="Download on the App Store"
                      style={{
                        width: 160,
                        height: 48,
                      }}
                      src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?size=250x83&amp;releaseDate=1538524800"
                    />
                  </a>
                  <div
                    style={{
                      width: 160,
                    }}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=fr.laposte.idn&hl=en&gl=US&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                    >
                      <img
                        alt="Disponible sur Google Play"
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                      />
                    </a>
                  </div>
                </>
              )}
            </Grid>
            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="500">
              3
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="500">
              Dans l'application, laissez-vous guider dans le processus de création de compte Identité Numérique
            </Typography>

            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>

        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="500">
              4
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography
              variant="body1"
              fontWeight="500"
              sx={{
                mb: '10px',
              }}
            >
              Choisissez la vérification d'identité par Courrier Recommandé Electronique
            </Typography>
            <img
              src={IDNVerify}
              alt="Vérification d'identité par Courrier Recommandé Electronique"
              style={{
                // maxWidth: '300px',
                width: '100%',
                objectFit: 'contain',
                marginTop: 5,
              }}
            />
            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>

        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="500">
              5
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="500">
              Prenez rendez-vous avec l'un de nos conseillers pour poursuivre le processus d'inscription
            </Typography>

            <CallForm form={form} setForm={setForm} validated={validated} setValidated={setValidated} />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2.5 }} />
        <Grid sx={{ mt: 2 }}>
          <Typography
            variant="body1"
            fontWeight="500"
            sx={{
              mb: 1,
            }}
          >
            Vous êtes perdu(e) ? Visionnez le tutoriel vidéo ci-dessous !
          </Typography>
          <Typography variant="body2">Pour vous accompagner pas à pas dans votre inscription</Typography>
        </Grid>
        <Grid item className="container" sx={{ mt: 2.5 }}>
          <iframe
            style={{
              borderRadius: '4px',
            }}
            height="100%"
            width="100%"
            src="https://iframe.mediadelivery.net/embed/252149/fce2a922-b768-4e4c-b039-a7662342f629"
            frameBorder="0"
            ref={tutorialRef}
          />
        </Grid>
        <Divider sx={{ my: 3 }} />
      </Grid>
    </>
  )
}

export default CpfStatusNo
