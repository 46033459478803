import { useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, Typography } from '@mui/material'

type StripeFormPropsType = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const StripeForm = ({ setOpen }: StripeFormPropsType) => {
  const stripe = useStripe()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const params = new URL(location.href).searchParams
  const global_id = params.get('global_id')
  const [submited, setSubmited] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmited(true)
    if (!stripe || !elements || !global_id) {
      console.log('!stripe || !elements || !global_id')
      throw Error('Stripe or elements undefined')
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/sante/stripe/?global_id=${global_id}`,
      },
    })

    if (error) {
      setSubmited(false)
      setErrorMessage(error?.message ?? '')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        justifyContent: 'space-between',
        flexDirection: 'column',
        display: 'flex',
        height: '100%',
      }}
    >
      <div>
        <Typography variant="h6" sx={{ mb: 5 }}>
          Enregistrer vos coordonnées bancaires
        </Typography>
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        <PaymentElement />
      </div>
      <div style={{ justifyContent: 'center', display: 'flex', paddingTop: 5 }}>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false)
          }}
          sx={{ marginRight: '10px' }}
        >
          Annuler
        </Button>
        <Button variant="contained" type="submit" disabled={!stripe || submited}>
          Enregistrer
        </Button>
      </div>
    </form>
  )
}

export default StripeForm
