import { Button, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FormType } from '../../utils/sharedTypes'
import CallForm from '../CallForm'

type CpfStatusDontKnowPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const CpfStatusDontKnow = ({ form, setForm }: CpfStatusDontKnowPropsType) => {
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState<'Prendre rendez-vous' | 'Être rappelé'>('Prendre rendez-vous')

  return (
    <Grid>
      {!validated && (
        <>
          <Typography variant="body1" fontWeight="500">
            Vous avez besoin d'aide & préférez être accompagné(e) par un conseiller ?
          </Typography>
          <Grid item container spacing={1} sx={{ justifyContent: 'flex-start', mt: 2, mb: 4 }}>
            {['Prendre rendez-vous', 'Être rappelé'].map((idn) => {
              return (
                <Grid item key={idn}>
                  <Button
                    variant={state === idn ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => {
                      setState(idn as 'Prendre rendez-vous' | 'Être rappelé')
                    }}
                    sx={{
                      height: 56,
                    }}
                  >
                    {idn}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
          <Divider
            sx={{
              my: 2.5,
            }}
          />
          <Grid
            container
            direction="column"
            sx={{
              mb: 2,
            }}
          >
            {state === 'Prendre rendez-vous' ? (
              <>
                <Typography variant="body1" fontWeight="500">
                  Prenez rendez-vous avec un de nos conseillers
                </Typography>
                <Typography variant="caption">
                  pour poursuivre et finaliser votre inscription à la formation (prévoir 20min)
                </Typography>
              </>
            ) : (
              <Typography variant="body1" fontWeight="500">
                Confirmez votre numéro de téléphone ci-dessous, un conseiller vous appellera dans les prochaines minutes
                pour vous aider
              </Typography>
            )}
          </Grid>
        </>
      )}
      <CallForm
        form={form}
        setForm={setForm}
        instantCall={state !== 'Prendre rendez-vous'}
        validated={validated}
        setValidated={setValidated}
      />
    </Grid>
  )
}
export default CpfStatusDontKnow
