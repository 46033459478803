import {
  Grid,
  Typography,
  useTheme,
  Paper,
  Divider,
  TextField,
  Autocomplete,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
} from '@mui/material'
import { MdPhoneEnabled } from 'react-icons/md'
import { FormDefaultState } from '../../utils/constants'
import { FormType, AlertType } from '../../utils/sharedTypes'
import { useEffect, useState } from 'react'
import { client } from '../../utils/client'
// import { exercices } from '../../utils/constants'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'
import { useParams } from 'react-router-dom'
import { isStepValid } from '../../utils/misc'
import { ExternalInscriptionProductType, ProfessionType } from '../../utils/sharedTypes'
import Bugsnag from '@bugsnag/js'

type DPCInscriptionFormPropsType = {
  products: ExternalInscriptionProductType[]
  setClose: () => void
  refetch: boolean
  setRefetch: (args: boolean) => void
}

const DPCInscriptionForm = ({ products, setClose, refetch, setRefetch }: DPCInscriptionFormPropsType) => {
  const { provider_id } = useParams()
  const theme = useTheme()
  const [form, setForm] = useState<FormType>(FormDefaultState)
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          funding_organism: { code: 'DPC' },
        },
      }
    })
  }, [])

  useEffect(() => {
    //GET PROFESSIONS
    if (form.data.product && form.data.product.product_code) {
      client
        .get(`/registration/content/professions/?product=${form.data.product.product_code.toLowerCase()}`)
        .then((resp) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const data = resp.data.results as ProfessionType[]
          let reorderedData = data
          const indexOfOther = data?.findIndex((p) => p.code === 'unknown')
          if (indexOfOther !== -1) {
            reorderedData = data.slice(indexOfOther + 1)
            reorderedData.push(data[indexOfOther])
          }
          setForm((prevState) => {
            return {
              ...prevState,
              professions: reorderedData,
            }
          })
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [form.data.product?.product_code])

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'email':
          if (!emailValidator(value as string)) {
            errors['email'] = 'Veuillez renseigner un e-mail valide'
          }
          break
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        case 'first_name':
          if ((value as string).length === 0) {
            errors['first_name'] = 'Veuillez renseigner un prénom'
          }
          break
        case 'last_name':
          if ((value as string).length === 0) {
            errors['last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  const submit = () => {
    if (provider_id) {
      client
        .post(`registration/external/${provider_id}/`, form.data)
        .then(() => {
          setAlert({ severity: 'success', message: 'Inscription enregistrée' })
          setRefetch(!refetch)
          setClose()
        })
        .catch((err) => {
          setAlert({ severity: 'error', message: "Erreur lors de l'enregistrement" })
          console.error(err)
        })
    } else {
      setAlert({ severity: 'error', message: 'Provider inconnu' })
    }
  }

  return (
    <Grid container item xs={10} md={8} sx={{ maxHeight: '100%', overflow: 'scroll' }}>
      <Paper sx={{ background: 'white', p: theme.spacing(2), width: '100%', height: '100%' }}>
        <Typography variant="h6">Nouvelle inscription DPC</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid item container spacing={2}>
          {form.data.product && (
            <Grid item xs={12}>
              <Autocomplete
                disableClearable
                disablePortal
                id="formation"
                value={form.data.product}
                getOptionLabel={(option) =>
                  option.dpc_action_number ? `${option?.name} - ${option.dpc_action_number}` : option?.name
                }
                onChange={(_, value) => {
                  if (value) {
                    handleChange('product', value as Record<string, any>)
                  }
                }}
                options={products}
                renderInput={(params) => <TextField {...params} required label="Formation" />}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Prénom"
              variant="outlined"
              id="first_name"
              required
              value={form.data.first_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('first_name', e?.target.value)
              }}
              error={Boolean(form.errors.first_name)}
              helperText={form.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nom"
              id="last_name"
              variant="outlined"
              required
              value={form.data.last_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('last_name', e?.target.value)
              }}
              error={Boolean(form.errors.last_name)}
              helperText={form.errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              disablePortal
              id="profession"
              value={form.professions.filter((p) => p?.code === form.data.profession.code)[0] || null}
              getOptionLabel={(option) => option?.name}
              onChange={(_, value) => {
                if (value) {
                  handleChange('profession', { code: value.code })
                }
              }}
              options={form.professions}
              renderInput={(params) => <TextField {...params} required label="Profession" />}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Autocomplete
              disablePortal
              disableClearable
              value={exercices.filter((p) => p.code === form.data.exercice)[0] || null}
              id="exercice"
              onChange={(_, value) => {
                if (value) {
                  handleChange('exercice', value.code)
                }
              }}
              options={exercices}
              renderInput={(params) => <TextField {...params} required label="Mode d'exercice" />}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              label="Adresse e-mail"
              id="email"
              variant="outlined"
              required
              value={form.data.email}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('email', e?.target.value)
              }}
              error={Boolean(form.errors.email)}
              helperText={form.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="tel"
              fullWidth
              id="mobile_phone"
              label="N° de mobile"
              variant="outlined"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MdPhoneEnabled size="20" />
                  </InputAdornment>
                ),
              }}
              value={form.data.mobile_phone}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('mobile_phone', e?.target.value)
              }}
              error={Boolean(form.errors.mobile_phone)}
              helperText={form.errors.mobile_phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Numéro RPPS"
              id="dpc_rpps"
              variant="outlined"
              required
              value={form.data.dpc_rpps}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('dpc_rpps', e?.target.value.replace(/[^0-9]/g, ''))
              }}
              error={Boolean(form.errors.dpc_rpps)}
              helperText={form.errors.dpc_rpps}
              disabled={form.data.dpc_submitted}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} sx={{ my: 2 }}>
          <Grid item container xs={12}>
            <Button
              fullWidth
              disabled={!isStepValid('external_inscription_dpc', form)}
              variant="contained"
              sx={{
                height: {
                  xs: 45,
                },
              }}
              onClick={() => {
                submit()
              }}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default DPCInscriptionForm
