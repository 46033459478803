import { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { EnterpriseFormData, EnterpriseFormType, ParamsType } from '../utils/sharedTypes'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { EnterpriseFormDefaultState } from '../utils/constants'
import { EnterpriseConfirmation, EnterpriseContract, EnterpriseInformation, EnterpriseTutor } from '../pages'

export default function EnterpriseRoutes() {
  const [params, setParams] = useState<ParamsType>({})
  const [form, setForm] = useState<EnterpriseFormType>(EnterpriseFormDefaultState)

  useEffect(() => {
    const searchObj = window.location.search ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}
    setParams(searchObj)
    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          utm_campaign: searchObj.utm_campaign || null,
          utm_source: searchObj.utm_source || null,
          utm_medium: searchObj.utm_medium || null,
          utm_content: searchObj.utm_content || null,
          utm_term: searchObj.utm_term || null,
          adset_id: searchObj.adset_id || null,
          entrypoint_url: decodeURIComponent(searchObj.entrypoint_url) || null,
        },
      }
    })
  }, [])

  useEffect(() => {
    if (params.global_id) {
      client
        .get(`/apprenticeship/enterprise/${params.global_id}/`)
        .then((resp) => {
          const data = resp.data as Partial<EnterpriseFormData>
          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                ...data,
              },
            }
          })
        })
        .catch((err: Error) => {
          console.error(err)
          Bugsnag.notify(err)
        })
    }
  }, [params.global_id])

  useEffect(() => {
    if (form.data.apprenticeship_type == 'ContratPro') {
      setForm((prevState) => {
        return {
          ...prevState,

          steps: prevState.steps.map((step) => {
            if (step.url === 'maitre-apprentissage') {
              return {
                ...step,
                label: 'Tuteur',
              }
            }
            return step
          }),
        }
      })

      if (form.data.cerfa_classification === '')
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              cerfa_classification: 'Secrétaire médical(e) diplômé(e)',
            },
          }
        })

      if (form.data.cerfa_level === '')
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              cerfa_level: '4',
            },
          }
        })

      if (form.data.cerfa_hierarchical_coefficient === '')
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              cerfa_hierarchical_coefficient: '209',
            },
          }
        })
    }
  }, [form.data.apprenticeship_type])

  return (
    <Routes>
      <Route path="information" element={<EnterpriseInformation form={form} setForm={setForm} />} />
      <Route path="maitre-apprentissage" element={<EnterpriseTutor form={form} setForm={setForm} />} />
      <Route path="contrat" element={<EnterpriseContract form={form} setForm={setForm} />} />
      <Route path="confirmation" element={<EnterpriseConfirmation />} />
      <Route path="*" element={<Navigate to="information" replace />} />
    </Routes>
  )
}
