import {
  Grid,
  Typography,
  useTheme,
  Paper,
  Divider,
  TextField,
  Autocomplete,
  InputAdornment,
  Button,
  Snackbar,
  Alert,
} from '@mui/material'
import { MdPhoneEnabled } from 'react-icons/md'
import { FormDefaultState } from '../../utils/constants'
import { FormType, AlertType } from '../../utils/sharedTypes'
import { useEffect, useState } from 'react'
import { client } from '../../utils/client'
// import { exercices } from '../../utils/constants'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'
import { useParams } from 'react-router-dom'
import { isStepValid } from '../../utils/misc'
import { ExternalInscriptionProductType } from '../../utils/sharedTypes'
// Date
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

type CPFInscriptionFormPropsType = {
  products: ExternalInscriptionProductType[]
  setClose: () => void
  refetch: boolean
  setRefetch: (args: boolean) => void
}

const CPFInscriptionForm = ({ products, setClose, refetch, setRefetch }: CPFInscriptionFormPropsType) => {
  const { provider_id } = useParams()
  const theme = useTheme()
  const [form, setForm] = useState<FormType>(FormDefaultState)
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          funding_organism: { code: 'CPF' },
          profession: { code: 'unknown' },
        },
      }
    })
  }, [])

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'email':
          if (!emailValidator(value as string)) {
            errors['email'] = 'Veuillez renseigner un e-mail valide'
          }
          break
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        case 'first_name':
          if ((value as string).length === 0) {
            errors['first_name'] = 'Veuillez renseigner un prénom'
          }
          break
        case 'last_name':
          if ((value as string).length === 0) {
            errors['last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break

        case 'cpf_price':
          if (Number(value) < 0) {
            errors['cpf_price'] = 'Veuillez renseigner un prix valide'
          }

          if (
            form.data.product &&
            (Number(value) < Math.floor(Number(form.data.product.listing_price * 0.5)) ||
              Number(value) > Number(form.data.product.listing_price))
          ) {
            errors['cpf_price'] = `Veuillez renseigner un prix valide (entre ${Math.floor(
              form.data.product.listing_price * 0.5,
            )}€ et ${form.data.product.listing_price}€)`
          }

          if (value && !value.toString().endsWith('49') && !value.toString().endsWith('99')) {
            errors['cpf_price'] = 'Le prix doit se terminer par 49 ou 99'
          }
          break

        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  const nextThirthyBusinessDays = Array.from({ length: 30 }, (_, i) => {
    const tmp = new Date()
    tmp.setDate(tmp.getDate() + i)
    return tmp
  }).filter((day) => {
    return day.getDay() !== 0 && day.getDay() !== 6
  })

  const listOfDays = nextThirthyBusinessDays.filter((_, index) => {
    return index < 14
  })

  const submit = () => {
    if (provider_id) {
      client
        .post(`registration/external/${provider_id}/`, form.data)
        .then(() => {
          setAlert({ severity: 'success', message: 'Inscription enregistrée' })
          setRefetch(!refetch)
          setClose()
        })
        .catch((err) => {
          setAlert({ severity: 'error', message: "Erreur lors de l'enregistrement" })
          console.error(err)
        })
    } else {
      setAlert({ severity: 'error', message: 'Provider inconnu' })
    }
  }

  return (
    <Grid container item xs={10} md={8} sx={{ maxHeight: '100%', overflow: 'scroll' }}>
      <Paper sx={{ background: 'white', p: theme.spacing(2), width: '100%', height: '100%' }}>
        <Typography variant="h6">Nouvelle inscription CPF</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid item container spacing={2}>
          {form.data.product && (
            <Grid item xs={12}>
              <Autocomplete
                disableClearable
                disablePortal
                id="formation"
                value={form.data.product}
                getOptionLabel={(option) => `${option?.name} - ${option?.product_code}`}
                onChange={(_, value) => {
                  if (value) {
                    handleChange('product', value as Record<string, any>)
                  }
                }}
                options={products}
                renderInput={(params) => <TextField {...params} required label="Formation" />}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Prénom"
              variant="outlined"
              id="first_name"
              required
              value={form.data.first_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('first_name', e?.target.value)
              }}
              error={Boolean(form.errors.first_name)}
              helperText={form.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nom"
              id="last_name"
              variant="outlined"
              required
              value={form.data.last_name}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('last_name', e?.target.value)
              }}
              error={Boolean(form.errors.last_name)}
              helperText={form.errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              label="Adresse e-mail"
              id="email"
              variant="outlined"
              required
              value={form.data.email}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('email', e?.target.value)
              }}
              error={Boolean(form.errors.email)}
              helperText={form.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="tel"
              fullWidth
              id="mobile_phone"
              label="N° de mobile"
              variant="outlined"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MdPhoneEnabled size="20" />
                  </InputAdornment>
                ),
              }}
              value={form.data.mobile_phone}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('mobile_phone', e?.target.value)
              }}
              error={Boolean(form.errors.mobile_phone)}
              helperText={form.errors.mobile_phone}
            />
          </Grid>
          <Grid item xs={12}>
            <DateField
              id="cpf_starting_date"
              label="Date début de session"
              format="DD/MM/YYYY"
              disablePast
              required
              value={form.data.cpf_starting_date ? dayjs(form.data.cpf_starting_date) : null}
              onChange={(value) => {
                if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                  handleChange('cpf_starting_date', dayjs(value).format('YYYY-MM-DD'))

                  if (!dayjs(value).isValid()) {
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        errors: {
                          ...prevState.errors,
                          cpf_starting_date: 'Veuillez entrer une date de session valide',
                        },
                      }
                    })
                  }

                  if (dayjs(value).isBefore(dayjs(listOfDays[listOfDays.length - 1]), 'days')) {
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        errors: {
                          ...prevState.errors,
                          cpf_starting_date: "La date de session doit être au moins 14 jours ouvrés après aujourd'hui",
                        },
                      }
                    })
                  }
                }
              }}
              fullWidth
              helperText={form.errors.cpf_starting_date}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={Boolean(form.errors.cpf_starting_date)}
                    helperText={form.errors.cpf_starting_date}
                  />
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Prix"
              id="price"
              variant="outlined"
              required
              value={form.data.cpf_price}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                const regex = new RegExp(/[^0-9]/, 'g')
                handleChange('cpf_price', e?.target.value.replace(regex, ''))
              }}
              error={Boolean(form.errors.cpf_price)}
              helperText={form.errors.cpf_price}
              disabled={!form.data.product?.product_code}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} sx={{ my: 2 }}>
          <Grid item container xs={12}>
            <Button
              fullWidth
              disabled={!isStepValid('external_inscription_cpf', form)}
              variant="contained"
              sx={{
                height: {
                  xs: 45,
                },
              }}
              onClick={() => {
                submit()
              }}
            >
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Grid>
  )
}

export default CPFInscriptionForm
