import { useEffect } from 'react'
import { Divider, Grid, Typography, useTheme } from '@mui/material'
import { CallUs, Formtitle } from '../../components'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import { BsCheck } from 'react-icons/bs'
import { ReactComponent as WalterLearning } from '../../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../../assets/WalterSanteColor.svg'
import { ReactComponent as Retour } from '../../assets/Retour.svg'
import { learningPhoneNumber } from '../../utils/constants'

const Confirmation = () => {
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }} ref={animateRef}>
      <Grid item xs={12} md={6} sx={{ mt: { xs: 15, md: 0 } }}>
        <Grid container justifyContent="center">
          <Grid
            item
            sx={{
              background: theme.palette.success.main,
              height: 80,
              width: 80,
              borderRadius: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BsCheck size="40px" style={{ color: 'white' }} />
          </Grid>
        </Grid>
        <Formtitle
          title={'Merci, nous vous enverrons les documents à relire très prochainement'}
          subtitle={
            "Une fois les différents documents validés, vous recevrez un lien pour signer électroniquement l'ensemble des pièces du dossier."
          }
          align="center"
        />
      </Grid>
      <Grid
        item
        container
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          pt: 2,
          pl: { xs: 2, md: 4 },
          display: 'flex',
        }}
      >
        <Grid item container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {location.pathname.includes('/sante') ? (
              <WalterSanteColor
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com/sante', '_self')
                }}
              />
            ) : (
              <WalterLearning
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com', '_self')
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: 40, mx: 2 }} />
          </Grid>
          <Grid
            item
            sx={{
              // flexGrow: { xs: 1, sm: 0 },
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              location.pathname.includes('/sante')
                ? window.open('https://walter-learning.com/sante', '_self')
                : window.open('https://walter-learning.com', '_self')
            }}
          >
            <Retour />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Retour au site
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={learningPhoneNumber} />
      </Grid>
    </Grid>
  )
}

export default Confirmation
