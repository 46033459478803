import { useEffect, useState } from 'react'
import {
  Grid,
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Button,
  useTheme,
  Link,
  Snackbar,
  Alert,
  Breadcrumbs,
} from '@mui/material'
import AdminLayout from '../../components/admin/AdminLayout'
import { client, refreshToken } from '../../utils/client'
import { useParams } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import { InscriptionType, AlertType } from '../../utils/sharedTypes'
import { Field, FIFPLFields, DPCFields } from '../../components'

const Registration = () => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })
  const [registration, setRegistration] = useState<InscriptionType>()
  const { id } = useParams()

  const getRegistration = () => {
    if (id) {
      client
        .get(`/registration/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access') || ''}`,
          },
        })
        .then((resp) => {
          const data = resp.data as InscriptionType
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setRegistration(data)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
          refreshToken(getRegistration)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (id) {
      getRegistration()
    }
  }, [])

  const validate = (id: number) => {
    client
      .patch(
        `registration/admin/${id}/validate/`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` } },
      )
      .then((resp) => {
        if (resp.status === 200) {
          setAlert({ severity: 'info', message: 'Inscription validée' })
          getRegistration()
        } else {
          setAlert({ severity: 'error', message: 'Erreur lors de la validation' })
        }
      })
      .catch((err: Error) => {
        setAlert({ severity: 'error', message: 'Erreur lors de la validation' })
        refreshToken(() => {
          validate(id)
        })
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const discard = (id: number) => {
    client
      .patch(
        `registration/admin/${id}/discard/`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` } },
      )
      .then((resp) => {
        if (resp.status === 200) {
          setAlert({ severity: 'info', message: 'Inscription refusée' })
          getRegistration()
        } else {
          setAlert({ severity: 'error', message: 'Erreur lors du refus' })
        }
      })
      .catch((err: Error) => {
        setAlert({ severity: 'error', message: 'Erreur lors du refus' })
        refreshToken(() => {
          discard(id)
        })
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  if (isLoading) {
    return (
      <AdminLayout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sx={{
            height: 'calc(100vh - 64px)',
          }}
        >
          <CircularProgress />
        </Grid>
      </AdminLayout>
    )
  }

  if (!registration) {
    return (
      <AdminLayout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sx={{
            height: 'calc(100vh - 64px)',
          }}
        >
          Inscription inconnue
        </Grid>
      </AdminLayout>
    )
  }

  return (
    <AdminLayout>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/admin">
            Admin
          </Link>
          <Link underline="hover" color="primary" href={`/admin/registration/${registration.id}`}>
            Inscription n°{registration.id}
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={9} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid item container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="h4">Inscription n° {registration.id}</Typography>
              <Typography variant="caption">
                créé le{' '}
                {registration.created_at
                  ? new Date(registration.created_at).toLocaleDateString('fr', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  : '-'}
              </Typography>
              {registration.submitted_at && (
                <Typography variant="caption">
                  soumis le{' '}
                  {new Date(registration.submitted_at).toLocaleDateString('fr', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </Typography>
              )}
              {registration.validated_at && registration.validated_by && (
                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                  validé le{' '}
                  {new Date(registration.validated_at).toLocaleDateString('fr', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}{' '}
                  par {registration.validated_by.first_name} {registration.validated_by.last_name}
                </Typography>
              )}
              {registration.discarded_at && registration.discarded_by && (
                <Typography variant="caption" sx={{ textAlign: 'center' }}>
                  refusé le{' '}
                  {new Date(registration.discarded_at).toLocaleDateString('fr', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}{' '}
                  par {registration.discarded_by.first_name} {registration.discarded_by.last_name}
                </Typography>
              )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h6">Client</Typography>
                <Field label="Prénom" value={registration.first_name} />
                <Field label="Nom de famille" value={registration.last_name} />
                <Field label="Date de naissance" type="date" value={registration.birthdate} />
                <Field label="Profession" value={registration?.profession?.name} />
                <Field label="Exercice" value={registration.exercice} />
                <Field label="Email" value={registration.email} />
                <Field label="Mobile" value={registration.mobile_phone} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Produit</Typography>
                <Field label="Nom" value={registration.product?.name} />
                <Field label="Nom de code" value={registration.product?.product_code} />
                <Field label="Prix" type="currency" value={registration.product?.listing_price} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Financement / Session</Typography>
                {registration && registration?.funding_organism?.code === 'FIFPL' && (
                  <FIFPLFields registration={registration} theme={theme} />
                )}
                {registration && registration?.funding_organism?.code === 'DPC' && (
                  <DPCFields registration={registration} theme={theme} />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Marketing</Typography>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
                    Source
                  </Typography>
                  <Link href={registration.entrypoint_url || '#'} target="_blank">
                    {registration.entrypoint_url ? registration.entrypoint_url : '-'}
                  </Link>
                </Grid>
                <Field label="utm_campaign" value={registration.utm_campaign} />
                <Field label="utm_content" value={registration.utm_content} />
                <Field label="utm_medium" value={registration.utm_medium} />
                <Field label="utm_source" value={registration.utm_source} />
                <Field label="utm_term" value={registration.utm_term} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">CRM</Typography>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
                    sf_opportunity_id
                  </Typography>
                  {registration.sf_opportunity_id ? (
                    <Link
                      href={`https://walterlearning.lightning.force.com/lightning/r/Opportunity/${registration.sf_opportunity_id}/view`}
                      target="_blank"
                    >
                      {registration.sf_opportunity_id}
                    </Link>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </Grid>

                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
                    sf_task_id
                  </Typography>
                  {registration.sf_task_id ? (
                    <Link
                      href={`https://walterlearning.lightning.force.com/lightning/r/Task/${registration.sf_task_id}/view`}
                      target="_blank"
                    >
                      {registration.sf_task_id}
                    </Link>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {!(registration.funding_organism?.code === 'DPC') && (
              <Grid item sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  disabled={registration.validated === true}
                  color="success"
                  onClick={(e) => {
                    if (id) {
                      e.preventDefault()
                      e.stopPropagation()
                      validate(parseInt(id))
                    }
                  }}
                >
                  Valider
                </Button>
                <Button
                  variant="outlined"
                  disabled={registration.validated === true || registration.discarded === true}
                  color="error"
                  onClick={(e) => {
                    if (id) {
                      e.preventDefault()
                      e.stopPropagation()
                      discard(parseInt(id))
                    }
                  }}
                >
                  Refuser
                </Button>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AdminLayout>
  )
}

export default Registration
