import { Grid, Typography, useTheme, AppBar, Toolbar, Button, Modal, TextField } from '@mui/material'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import config from '../../utils/config'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { client } from '../../utils/client'

type AdminLayoutPropsType = {
  children: React.ReactNode
}

const AdminLayout = ({ children }: AdminLayoutPropsType) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [saleModal, setSaleModal] = useState(false)
  const [sfID, setSfID] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const logOut = () => {
    client
      .get('/auth/logout/')
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
        localStorage.removeItem('isLoggedIn')
        navigate('/login')
      })
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Walter-Inscription - Admin</title>
      </Helmet>
      <Grid container justifyContent="end" sx={{ minHeight: '100vh' }}>
        <AppBar position="static" sx={{ background: theme.palette.background.paper }}>
          <Toolbar>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => {
                navigate('/admin')
              }}
            >
              {config.APP_NAME}
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => {
                setSaleModal(true)
              }}
              sx={{ mr: 1 }}
            >
              Enregistrer une vente
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={logOut}>
              Se déconnecter
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container sx={{ p: 2, minHeight: { xs: `calc(100vh - 56px)`, sm: `calc(100vh - 64px)` } }}>
          {children}
        </Grid>
      </Grid>
      <Modal
        open={saleModal}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
        onClose={() => {
          setSaleModal(false)
        }}
      >
        <form
          style={{ display: 'flex' }}
          onSubmit={(e) => {
            e.preventDefault()
            navigate(`sf/task/${sfID}`)
          }}
        >
          <Grid
            item
            container
            display="flex"
            sx={{
              background: 'white',
              p: 3,
              width: '100%',
              borderRadius: 1,
            }}
            spacing={1}
          >
            <Grid item sx={{ width: '100%', justifyContent: 'center' }}>
              <TextField
                label={'Salesforce task ID'}
                value={sfID}
                fullWidth
                variant="outlined"
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  setSfID(e.target.value)
                }}
              />
            </Grid>
            <Grid container item sx={{ width: '100%', justifyContent: 'center' }}>
              <Button type="submit" fullWidth variant="contained">
                Aller
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </HelmetProvider>
  )
}

export default AdminLayout
