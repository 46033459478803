import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Modal, Grid } from '@mui/material'
import StripeForm from './StripeForm'
import { StripePublicKey } from '../utils/constants'
type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  stripeClientSecret?: string
  open: boolean
}

const stripePromise = loadStripe(StripePublicKey)

export default function StripeModal({ stripeClientSecret, open, setOpen }: Props) {
  const options = {
    clientSecret: stripeClientSecret,
    appearance: {},
  }

  return (
    <Modal
      open={open}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        flexDirection="column"
        sx={{
          background: 'white',
          padding: '20px',
        }}
      >
        <Elements stripe={stripePromise} options={options}>
          <StripeForm setOpen={setOpen} />
        </Elements>
      </Grid>
    </Modal>
  )
}
