import { Grid, Typography, Divider, Link, Switch, FormControlLabel, Checkbox, useTheme } from '@mui/material'
import { FormType } from '../utils/sharedTypes'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ModalContract from './ModalContract'
import { MdOutlineZoomIn } from 'react-icons/md'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'

type RacPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Rac = ({ form, setForm }: RacPropsType) => {
  const [documents, setDocuments] = useState<{ [key: string]: string }>({})
  const [modal, setModal] = useState<{
    open: boolean
    html_document: string
    has_button: boolean
  }>({
    open: false,
    html_document: '',
    has_button: false,
  })
  const theme = useTheme()
  const handleChange = (key: string, value: number | string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    client
      .get(`/registration/${form.data.global_id}/cgu/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            cgu: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/alma_cgv/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            cgv: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/alma_contract/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            contract: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }, [form.data.funding_organism?.code])

  return (
    <Grid container direction="column">
      <Grid container direction="column">
        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          sx={{
            p: 2,
            background: form.data.perso_program ? `${theme.palette.success.main}1A` : 'transparent',
            border: form.data.perso_program
              ? `1px solid ${theme.palette.success.main}1A`
              : '1px solid rgba(0,0,0,0.12)',
            borderRadius: '4px',
            mt: 2,

            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
        >
          <Grid container direction="column">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Consultez{' '}
              <Link href={form.data.product?.program_url} target="_blank">
                le programme de la formation
              </Link>
            </Typography>
          </Grid>

          <Divider
            sx={{
              width: {
                md: 0,
                xs: '100%',
              },

              height: {
                md: 0,
                xs: '1px',
              },

              my: {
                md: 0,
                xs: 2,
              },
            }}
          />

          <Grid item container justifyContent="end">
            <FormControlLabel
              required
              value={form.data.perso_program}
              control={
                <Switch
                  color="success"
                  checked={form.data.perso_program}
                  onChange={(e) => handleChange('perso_program', e.target.checked)}
                />
              }
              sx={{
                fontWeight: form.data.perso_program ? 500 : 400,
              }}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: form.data.perso_program ? 500 : 400,
                  }}
                >
                  Lu et approuvé
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          flexWrap="nowrap"
          alignItems="center"
          sx={{
            p: 2,
            background: form.data.perso_cgu_cgv ? `${theme.palette.success.main}1A` : 'transparent',
            border: form.data.perso_cgu_cgv
              ? `1px solid ${theme.palette.success.main}1A`
              : '1px solid rgba(0,0,0,0.12)',
            borderRadius: '4px',
            mt: 2,

            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
        >
          <Grid container direction="column">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Acceptez les{' '}
              <Link
                onClick={() => {
                  setModal({
                    open: true,
                    html_document: documents.cgv,
                    has_button: false,
                  })
                }}
              >
                Conditions Générales de Vente
              </Link>{' '}
              et les{' '}
              <Link
                onClick={() => {
                  setModal({
                    open: true,
                    html_document: documents.cgu,
                    has_button: false,
                  })
                }}
              >
                Conditions Générales d'Utilisation
              </Link>
            </Typography>
          </Grid>

          <Divider
            sx={{
              width: {
                md: 0,
                xs: '100%',
              },

              height: {
                md: 0,
                xs: '1px',
              },

              my: {
                md: 0,
                xs: 2,
              },
            }}
          />
          <Grid item container justifyContent="end">
            <FormControlLabel
              required
              value={form.data.perso_cgu_cgv}
              control={
                <Switch
                  color="success"
                  checked={form.data.perso_cgu_cgv}
                  onChange={(e) => handleChange('perso_cgu_cgv', e.target.checked)}
                />
              }
              sx={{
                fontWeight: form.data.perso_cgu_cgv ? 500 : 400,
              }}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: form.data.perso_cgu_cgv ? 500 : 400,
                  }}
                >
                  Lu et approuvé
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Grid
          sx={{
            width: '100%',
            height: '300px',
            mt: 2,
            py: 6,
            px: 10,
            borderRadius: '4px 4px 0 0',
            background: 'rgba(0,0,0,0.54)',

            overflow: 'hidden',
            position: 'relative',

            cursor: 'pointer',
          }}
          onClick={() => {
            setModal({
              open: true,
              html_document: documents.contract,
              has_button: true,
            })
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.54)',
              position: 'absolute',
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                width: 60,
                height: 60,
                borderRadius: '4px',
                background: 'white',
              }}
            >
              <MdOutlineZoomIn size="30px" />
            </Grid>
          </Grid>
          <Grid
            sx={{
              background: 'white',
              borderRadius: '4px',
              py: 2,
              px: 4,
            }}
            dangerouslySetInnerHTML={{
              __html: documents.contract?.replace(/margin/g, ''),
            }}
          ></Grid>
        </Grid>
        <Grid
          container
          flexWrap="nowrap"
          sx={{
            p: 2,
            background: form.data.perso_signature ? `${theme.palette.success.main}1A` : 'transparent',
            border: form.data.perso_signature
              ? `1px solid ${theme.palette.success.main}1A`
              : '1px solid rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            cursor: 'pointer !important',

            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
          onClick={() =>
            setModal({
              open: true,
              html_document: documents.contract,
              has_button: true,
            })
          }
        >
          <Grid container direction="column">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Signez votre contrat de formation
            </Typography>
            <Typography variant="caption">Je lis et signe le contrat de formation</Typography>
          </Grid>

          <Divider
            sx={{
              width: {
                md: 0,
                xs: '100%',
              },

              height: {
                md: 0,
                xs: '1px',
              },

              my: {
                md: 0,
                xs: 2,
              },
            }}
          />
          <Grid item container justifyContent="end">
            <FormControlLabel
              required
              control={<Switch color="success" disabled checked={form.data.perso_signature} />}
              sx={{
                fontWeight: form.data.perso_signature ? 500 : 400,
              }}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: form.data.perso_signature ? 500 : 400,
                  }}
                >
                  Lu et approuvé
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 4.5 }} />

        <Grid item container alignItems="start" wrap="nowrap">
          <Checkbox
            id="perso_checkbox"
            checked={form.data.perso_checkbox}
            onClick={() => {
              handleChange('perso_checkbox', !form.data.perso_checkbox)
            }}
          />
          <Typography variant="body2">
            Je demande l'accès à ma formation à la date de son premier jour et renonce à mon droit de rétractation. Je
            comprends que les conditions d'annulation décrites dans les CGV s'appliquent.
          </Typography>
        </Grid>
      </Grid>

      <ModalContract
        modal={modal}
        setModal={setModal}
        isSigningUp={() => {
          if (!modal.has_button) return
          handleChange('perso_signature', true)
          handleChange('alma_contract', documents.contract)
          handleChange('alma_contract_signed_at', new Date().toISOString())
          setModal({
            open: false,
            html_document: '',
            has_button: false,
          })
        }}
      />
    </Grid>
  )
}

export default Rac
