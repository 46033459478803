import { ExternalInscriptionType } from '../../utils/sharedTypes'
import { Chip, Theme, Tooltip } from '@mui/material'

const StatusLabel = ({ registration }: { registration: ExternalInscriptionType; theme?: Theme }) => {
  switch (registration.status) {
    case 'Submitted':
      return (
        <Tooltip title="Pas encore inscrit sur le financeur" placement="left">
          <Chip label="En attente" color="primary" variant="outlined" />
        </Tooltip>
      )
    case 'Registered':
      return (
        <Tooltip
          title="L'inscription a été confirmée par l'organisme financeur mais le lien magique n'a pas encore été cliqué"
          placement="left"
        >
          <Chip label="Inscrit" color="primary" variant="outlined" />
        </Tooltip>
      )
    case 'Validated':
      return (
        <Tooltip
          title="L'inscription a été confirmée par l'organisme financeur et le lien magique a été cliqué"
          placement="left"
        >
          <Chip label="Inscription validée" color="success" variant="outlined" />
        </Tooltip>
      )
    case 'Running': {
      let tooltip = "La session a débuté et l'apprenant a commencé sa formation"

      if (registration.case_status === 'Pas encore commencé') {
        tooltip = "La session a débuté mais l'apprenant n'a pas encore commencé sa formation"
      }

      return (
        <Tooltip title={tooltip} placement="left">
          <Chip
            label={registration.case_status ? registration.case_status : 'En cours'}
            color="success"
            variant="outlined"
          />
        </Tooltip>
      )
    }
    case 'Unsubscribed':
      return (
        <Tooltip title="Désinscription" placement="left">
          <Chip label="Désinscrit" color="error" variant="outlined" />
        </Tooltip>
      )
    case 'Interrupted':
      return (
        <Tooltip title="0€ facturable en fin de FC" placement="left">
          <Chip label="Interrompue" color="error" variant="outlined" />
        </Tooltip>
      )
    case 'TerminatedSession': {
      let tooltip = 'La session est terminée'

      if (registration.case_status === 'Absent') {
        tooltip = "La session est terminée et l'apprenant ne s'est pas connecté"
      } else if (registration.case_status === 'Terminé partiellement') {
        tooltip = "La session est terminée et l'apprenant a validé partiellement son parcours"
      } else if (registration.case_status === 'Terminé') {
        tooltip = "La session est terminée et l'apprenant a validé son parcours"
      }

      return (
        <Tooltip title={tooltip} placement="left">
          <Chip
            label={registration.case_status ? registration.case_status : 'Terminé'}
            color="success"
            variant="outlined"
          />
        </Tooltip>
      )
    }
    default:
      return <Chip label="Inconnu" color="warning" variant="outlined" />
  }
}

export default StatusLabel
