import Bugsnag from '@bugsnag/js'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Formtitle, Layout } from '../../components'
import { client } from '../../utils/client'
import { isEnterpriseStepValid } from '../../utils/misc'
import { EnterpriseFormData, EnterpriseFormType } from '../../utils/sharedTypes'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'

type TutorPropsType = {
  form: EnterpriseFormType
  setForm: React.Dispatch<React.SetStateAction<EnterpriseFormType>>
}

export default function Tutor({ form, setForm }: TutorPropsType) {
  const navigate = useNavigate()

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/enterprise/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../contrat/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: keyof EnterpriseFormData, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'cerfa_tutor_last_name':
        case 'cerfa_tutor_first_name':
        case 'cerfa_tutor_current_job':
        case 'cerfa_tutor_highest_diploma':
          if (!value) {
            errors[key] = 'Ce champ ne peut pas être vide.'
          }
          break

        case 'cerfa_tutor_email':
          if (!emailValidator(value as string)) {
            errors[key] = 'Veuillez entrer une adresse email valide.'
          }
          break

        case 'cerfa_tutor_mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors[key] = 'Veuillez entrer un numéro de téléphone valide.'
          }
          break

        case 'cerfa_tutor_birth_date':
          if (!dayjs(value as string).isValid()) {
            errors[key] = 'Veuillez entrer une date de naissance valide.'
          }
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title={`Informations sur le ${
          form.data.apprenticeship_type === 'ContratPro' ? 'tuteur' : "maître d'apprentissage"
        }`}
        subtitle={`Veuillez renseigner les informations sur le ${
          form.data.apprenticeship_type === 'ContratPro' ? 'tuteur' : "maître d'apprentissage"
        }`}
      />
      <Grid item container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom de naissance"
            variant="outlined"
            id="cerfa_tutor_last_name"
            required
            value={form.data.cerfa_tutor_last_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_last_name', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_last_name)}
            helperText={form.errors.cerfa_tutor_last_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Prénom"
            variant="outlined"
            id="cerfa_tutor_first_name"
            required
            value={form.data.cerfa_tutor_first_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_first_name', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_first_name)}
            helperText={form.errors.cerfa_tutor_first_name}
          />
        </Grid>
        <Grid item xs={12}>
          <DateField
            fullWidth
            id="cerfa_tutor_birth_date"
            label="Date de naissance"
            format="DD/MM/YYYY"
            disableFuture
            value={form.data.cerfa_tutor_birth_date ? dayjs(form.data.cerfa_tutor_birth_date) : null}
            onChange={(value) => {
              //HACKY
              if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                handleChange('cerfa_tutor_birth_date', dayjs(value).format('YYYY-MM-DD'))
              }
            }}
            slots={{
              textField: (params) => (
                <TextField
                  id="birthdate_textField"
                  {...params}
                  fullWidth
                  onBlur={() => {
                    if (!dayjs(form.data.cerfa_tutor_birth_date).isValid()) {
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            cerfa_tutor_birth_date: 'Veuillez entrer une date de naissance valide',
                          },
                        }
                      })
                    }
                  }}
                  error={Boolean(form.errors.cerfa_tutor_birth_date)}
                  helperText={form.errors.cerfa_tutor_birth_date}
                />
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Adresse email"
            variant="outlined"
            id="cerfa_tutor_email"
            required
            value={form.data.cerfa_tutor_email}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_email', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_email)}
            helperText={form.errors.cerfa_tutor_email}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Téléphone mobile"
            variant="outlined"
            id="cerfa_tutor_mobile_phone"
            required
            value={form.data.cerfa_tutor_mobile_phone}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_mobile_phone', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_mobile_phone)}
            helperText={form.errors.cerfa_tutor_mobile_phone}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Emploi occupé"
            variant="outlined"
            id="cerfa_tutor_current_job"
            required
            value={form.data.cerfa_tutor_current_job}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_current_job', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_current_job)}
            helperText={form.errors.cerfa_tutor_current_job}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Diplôme ou titre le plus élevé obtenu"
            variant="outlined"
            id="cerfa_tutor_highest_diploma"
            required
            value={form.data.cerfa_tutor_highest_diploma}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_tutor_highest_diploma', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_tutor_highest_diploma)}
            helperText={form.errors.cerfa_tutor_highest_diploma}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel>Niveau diplôme / titre le + élevé obtenu</InputLabel>
            <Select
              fullWidth
              label="Niveau diplôme / titre le + élevé obtenu"
              variant="outlined"
              id="cerfa_tutor_highest_level_code"
              value={form.data.cerfa_tutor_highest_level_code}
              onChange={(e) => {
                handleChange('cerfa_tutor_highest_level_code', e?.target.value)
              }}
            >
              <MenuItem value="3">CAP, BEP</MenuItem>
              <MenuItem value="4">Baccalauréat</MenuItem>
              <MenuItem value="5">DEUG, BTS, DUT, DEUST</MenuItem>
              <MenuItem value="6">Licence, licence professionnelle, BUT, Maîtrise libérale</MenuItem>
              <MenuItem value="7">
                Master, diplôme d'études approfondies, diplôme d'études supérieures spécialisées, diplôme d’ingénieur
              </MenuItem>
              <MenuItem value="8">Doctorat, habilitation à diriger des recherches</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate({ pathname: './../information/', search: location.search })
          }}
        >
          Précédent
        </Button>

        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isEnterpriseStepValid('tutor', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}
