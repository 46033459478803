import axios from 'axios'
import config from './config'
import Cookies from 'js-cookie'
import { RefreshTokenRespDataType } from './sharedTypes'

export const client = axios.create({
  baseURL: config.CRM_URL[import.meta.env.VITE_ENV as keyof typeof config.CRM_URL],
  timeout: 10000,
})

export const clientWithCredentials = axios.create({
  baseURL: config.CRM_URL[import.meta.env.VITE_ENV as keyof typeof config.CRM_URL],
  timeout: 20000,
  withCredentials: true,
  headers: {
    'X-CSRFToken': Cookies.get('csrftoken'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const logOut = () => {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
  localStorage.removeItem('isLoggedIn')
  window.open('/login')
}

export const refreshToken = (cb: () => void) => {
  clientWithCredentials
    .post('auth/refresh/')
    .then((resp) => {
      if (resp.status === 200) {
        const data = resp.data as RefreshTokenRespDataType
        localStorage.setItem('access', data.access)
        localStorage.setItem('refresh', data.refresh)
        localStorage.setItem('isLoggedIn', 'true')
        cb()
      } else {
        logOut()
      }
    })
    .catch((err: Error) => {
      console.error('refreshToken', err)
      logOut()
    })
}
