import { useEffect, useState } from 'react'
import {
  Grid,
  CircularProgress,
  Paper,
  Link,
  Typography,
  Divider,
  Button,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material'
import { client } from '../../../utils/client'
import AdminLayout from '../../../components/admin/AdminLayout'
import Bugsnag from '@bugsnag/js'
import { useParams } from 'react-router-dom'
import { TaskType, AlertType } from '../../../utils/sharedTypes'
import { EditableField } from '../../../components'
import { SFTypeLMSTypeMapping } from '../../../utils/constants'

type AdditionalFields = {
  session_id?: string
  completion_rate?: string
  reference?: string
  comment?: string
}

const Task = () => {
  const { task_id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })
  const [product, setProduct] = useState<{ sf_id: string; lms_product_code: string } | undefined>()
  const [task, setTask] = useState<TaskType>()
  const [editedTask, setEditedTask] = useState<TaskType & AdditionalFields>()

  const fetchTask = () => {
    if (task_id) {
      client
        .get(`salesforce/tasks_legacy/${task_id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` },
        })
        .then((resp) => {
          const data = resp.data as TaskType
          console.log('task', data)
          setTask(data)
          setEditedTask(data)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error('err', err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    fetchTask()
  }, [])

  useEffect(() => {
    client
      .get(`salesforce/products/?page_size=500`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` },
      })
      .then((resp) => {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = resp?.data.results as Record<string, string>[]
        const prod = data.find((p) => p.sf_id === task?.product)

        if (prod) {
          setProduct({ lms_product_code: prod?.lms_product_code, sf_id: prod.sf_id })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error('err', err)
      })
  }, [task])

  if (isLoading) {
    return (
      <AdminLayout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sx={{
            height: 'calc(100vh - 64px)',
          }}
        >
          <CircularProgress />
        </Grid>
      </AdminLayout>
    )
  }

  if (!task || !editedTask) {
    return (
      <AdminLayout>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sx={{
            height: 'calc(100vh - 64px)',
          }}
        >
          Task inconnue
        </Grid>
      </AdminLayout>
    )
  }

  const handleChange = (value: string, key: string) => {
    if (editedTask) {
      setEditedTask((prevState) => {
        if (!prevState) return
        return {
          ...prevState,
          [key]: value,
        }
      })
    }
  }

  const handleSubmit = () => {
    editedTask.type = SFTypeLMSTypeMapping[editedTask.type as keyof typeof SFTypeLMSTypeMapping] || 'other'
    console.log('editedTask', editedTask)
    if (product) {
      editedTask.product = product?.lms_product_code
    } else {
      setAlert({
        severity: 'error',
        message: 'Produit inconnu!',
      })
      return
    }

    setIsLoading(true)
    client
      .post('registration/admin/register/', editedTask, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` },
      })
      .then((resp) => {
        if (resp.status === 200) {
          setAlert({
            severity: 'success',
            message: 'Vente enregistrée',
          })
          fetchTask()
        } else {
          setAlert({
            severity: 'error',
            message: 'Vente non-enregistrée',
          })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        setAlert({
          severity: 'error',
          message: 'Vente non-enregistrée',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const submitIsDisabled = () => {
    const requiredFields: (keyof TaskType | keyof AdditionalFields)[] = [
      'sf_id',
      'first_name',
      'last_name',
      'email',
      'mobilephone',
      'type',
      'product',
      'starting_date',
      'ending_date',
      'part_orga',
      'completion_rate',
      'reference',
    ]

    if (JSON.stringify(task) === JSON.stringify(editedTask)) return true

    if (!product) return true

    if (!requiredFields.every((field) => Boolean(editedTask[field]))) return true

    return false
  }

  return (
    <AdminLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={9} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid item container direction="column" justifyContent="center" alignItems="center">
              <Typography variant="h4">
                <Link
                  href={task_id ? `https://walterlearning.lightning.force.com/lightning/r/Task/${task_id}/view` : '#'}
                  target="_blank"
                >
                  Task id: {task.sf_id}
                </Link>
              </Typography>

              <Typography variant="caption">
                créé le{' '}
                {task.created_date
                  ? new Date(task.created_date).toLocaleDateString('fr', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  : '-'}
              </Typography>
              <Typography variant="caption">Sale: {task.owner?.representation}</Typography>
              <Typography variant="caption">Statut: {task.status}</Typography>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h6">Client</Typography>
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Prénom"
                      value={editedTask.first_name}
                      onChange={(value) => {
                        handleChange(value, 'first_name')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Nom de famille"
                      value={editedTask.last_name}
                      onChange={(value) => {
                        handleChange(value, 'last_name')
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Email"
                      value={editedTask.email}
                      onChange={(value) => {
                        handleChange(value, 'email')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Téléphone portable"
                      value={editedTask.mobilephone}
                      onChange={(value) => {
                        handleChange(value, 'mobilephone')
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Session</Typography>
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" mt={1}>
                      <Grid item flexGrow={1} ml={1}>
                        <TextField
                          required
                          focused
                          InputProps={{
                            readOnly: true,
                          }}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.Mui-focused fieldset': {
                                borderWidth: '1px',
                                borderColor: 'rgba(0, 0, 0, 0.38)',
                                background: 'rgba(239, 239, 239, 0.3)',
                              },
                            },
                          }}
                          label={'Produit'}
                          value={product?.lms_product_code || product?.sf_id}
                          defaultValue={product?.lms_product_code || product?.sf_id}
                          error={Boolean(!product)}
                          helperText={!product && 'Produit inconnu'}
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      select={true}
                      label="Type de session"
                      value={SFTypeLMSTypeMapping[editedTask.type as keyof typeof SFTypeLMSTypeMapping] || 'other'}
                      values={Object.values(SFTypeLMSTypeMapping).filter(
                        (val, index) => Object.values(SFTypeLMSTypeMapping).indexOf(val) === index,
                      )}
                      onChange={(value) => {
                        const val = Object.keys(SFTypeLMSTypeMapping).find(
                          (key) => SFTypeLMSTypeMapping[key as keyof typeof SFTypeLMSTypeMapping] === value,
                        )
                        if (val) {
                          handleChange(val, 'type')
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Date de début"
                      type="date"
                      value={editedTask.starting_date}
                      onChange={(value) => {
                        handleChange(value, 'starting_date')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Date de fin"
                      type="date"
                      value={editedTask.ending_date}
                      onChange={(value) => {
                        handleChange(value, 'ending_date')
                      }}
                    />
                  </Grid>
                </Grid>
                <EditableField
                  editMode={true}
                  required={false}
                  label="Walter Session Id"
                  value={editedTask.session_id}
                  onChange={(value) => {
                    handleChange(value, 'session_id')
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Admin</Typography>
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      label="Montant"
                      type="currency"
                      value={editedTask.part_orga}
                      onChange={(value) => {
                        handleChange(value, 'part_orga')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      helperText="en %"
                      label="Taux de complétion"
                      value={editedTask.completion_rate}
                      onChange={(value) => {
                        handleChange(value, 'completion_rate')
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="row" xs={12}>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      helperText="(Ref dossier OPCO, numéro de devis)"
                      label="Référence"
                      value={editedTask.reference}
                      onChange={(value) => {
                        handleChange(value, 'reference')
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EditableField
                      editMode={true}
                      required={false}
                      label="Numéro de facture"
                      value={editedTask.invoice_number}
                      onChange={(value) => {
                        handleChange(value, 'invoice_number')
                      }}
                    />
                  </Grid>
                </Grid>
                <EditableField
                  editMode={true}
                  required={false}
                  multiline={true}
                  rows={4}
                  label="Commentaire"
                  value={editedTask.comment}
                  onChange={(value) => {
                    handleChange(value, 'comment')
                  }}
                />
              </Grid>
              <Grid item container xs={12} mt={2}>
                <Grid container item xs={12} justifyContent="center">
                  <Grid container justifyContent="center" spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="success"
                        disabled={submitIsDisabled() || isLoading}
                        onClick={handleSubmit}
                        sx={{ width: '156px' }}
                      >
                        {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Enregistrer'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </AdminLayout>
  )
}

export default Task
