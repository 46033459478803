import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormType } from '../utils/sharedTypes'
type InvoiceFormProps = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
  setBillingInfoComplete?: Dispatch<SetStateAction<boolean>>
}

const FixedMenuItem: React.FC<React.ComponentProps<typeof MenuItem>> = (props) => (
  <MenuItem style={{ whiteSpace: 'normal' }} {...props} />
)

export default function InvoiceForm({ form, setForm, setBillingInfoComplete }: InvoiceFormProps) {
  const [prospectType, setProspectType] = useState<string>(form.data.invoice_company_name ? 'company' : 'individual')

  const handleChange = (key: string, value: string | boolean | Record<string, string> | null) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'invoice_company_name':
          if (!value) {
            errors[key] = 'Le nom de la société est requis'
          }
          break

        case 'invoice_last_name':
          if (!value) {
            errors[key] = 'Le nom est requis'
          }
          break

        case 'invoice_first_name':
          if (!value) {
            errors[key] = 'Le prénom est requis'
          }
          break

        case 'invoice_street':
          if (!value) {
            errors[key] = "L'adresse est requise"
          }
          break

        case 'invoice_postal_code':
          if (!value) {
            errors[key] = 'Le code postal est requis'
          }
          break

        case 'invoice_city':
          if (!value) {
            errors[key] = 'La ville est requise'
          }
          break

        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    setBillingInfoComplete &&
      setBillingInfoComplete(
        Boolean(form.data.invoice_company_name || (form.data.invoice_first_name && form.data.invoice_last_name)) &&
          Boolean(form.data.invoice_street && form.data.invoice_postal_code && form.data.invoice_city),
      )
  }, [
    form.data.invoice_company_name,
    form.data.invoice_first_name,
    form.data.invoice_last_name,
    form.data.invoice_street,
    form.data.invoice_postal_code,
    form.data.invoice_city,
  ])

  return (
    <>
      <Typography mb={4} variant="h5">
        Adresse de facturation
      </Typography>
      <Grid item mb={2}>
        <FormControl fullWidth>
          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type-label"
            label="Type"
            onChange={(e) => {
              setProspectType(e.target.value)
              handleChange('invoice_company_name', '')
              handleChange('invoice_last_name', '')
              handleChange('invoice_first_name', '')
            }}
            value={prospectType}
          >
            <FixedMenuItem value="individual">Je paye en tant que particulier</FixedMenuItem>
            <FixedMenuItem value="company">Je paye en tant que société</FixedMenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item gap={2}>
        {prospectType === 'company' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nom de la société"
              onChange={(e) => handleChange('invoice_company_name', e.target.value)}
              value={form.data.invoice_company_name}
              error={Boolean(form.errors.invoice_company_name)}
              helperText={form.errors.invoice_company_name}
              required
            />
          </Grid>
        )}
        {prospectType === 'individual' && (
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nom"
                onChange={(e) => handleChange('invoice_last_name', e.target.value)}
                value={form.data.invoice_last_name}
                error={Boolean(form.errors.invoice_last_name)}
                helperText={form.errors.invoice_last_name}
                required
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Prénom"
                onChange={(e) => handleChange('invoice_first_name', e.target.value)}
                value={form.data.invoice_first_name}
                error={Boolean(form.errors.invoice_first_name)}
                helperText={form.errors.invoice_first_name}
                required
              />
            </Grid>
          </Grid>
        )}

        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Adresse"
              onChange={(e) => handleChange('invoice_street', e.target.value)}
              value={form.data.invoice_street}
              error={Boolean(form.errors.invoice_street)}
              helperText={form.errors.invoice_street}
              required
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Code postal"
              onChange={(e) => handleChange('invoice_postal_code', e.target.value)}
              value={form.data.invoice_postal_code}
              error={Boolean(form.errors.invoice_postal_code)}
              helperText={form.errors.invoice_postal_code}
              required
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Ville"
              onChange={(e) => handleChange('invoice_city', e.target.value)}
              value={form.data.invoice_city}
              error={Boolean(form.errors.invoice_city)}
              helperText={form.errors.invoice_city}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
