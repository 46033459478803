import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MdPhoneEnabled } from 'react-icons/md'
import { FormType } from '../utils/sharedTypes'
import { phoneNumberValidator } from '../utils/validators'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { useNavigate } from 'react-router-dom'

type CallFormPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
  validated: boolean
  setValidated: React.Dispatch<React.SetStateAction<boolean>>
  instantCall?: boolean
}

const CallForm = ({ form, setForm, validated, setValidated, instantCall = false }: CallFormPropsType) => {
  const navigate = useNavigate()
  const listOfDays = Array.from({ length: 30 }, (_, i) => {
    const tmp = new Date()
    tmp.setDate(tmp.getDate() + i + 1)
    return tmp
  }).filter((day, index) => {
    return day.getDay() !== 0 && day.getDay() !== 6 && index <= 6
  })

  const [date, setDate] = useState<{
    date: string
    time: string
  }>({
    date: listOfDays[0].toDateString(),
    time: '10:30',
  })

  const submitFinancement = () => {
    client
      .patch(`/registration/${form.data.global_id}/submit/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          setValidated(true)
          navigate({ pathname: './../recommandation', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string> | null) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    if (date.date && date.time && !instantCall) {
      const tmp = new Date(`${date.date} ${date.time}`)
      handleChange('appointment_dt', tmp.toISOString())
    } else if (instantCall) {
      handleChange('appointment_dt', null)
    }
  }, [date.date, date.time, instantCall])

  if (validated && form.data.appointment_dt) {
    return (
      <Grid
        sx={{
          border: '1px solid #E2E2E2',
          borderRadius: '4px',

          p: 2,
          mt: 2,
        }}
      >
        <Typography variant="body1" fontWeight="500">
          Votre demande a bien été prise en compte
        </Typography>
        <Typography variant="caption">
          {instantCall
            ? 'Un conseiller vous contactera dans les plus brefs délais pour finaliser votre inscription'
            : `Vous avez rendez-vous le ${new Date(form.data.appointment_dt).toLocaleString('fr-FR', {
                day: 'numeric',
                month: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}`}
        </Typography>
      </Grid>
    )
  }

  return (
    <Grid container direction="column">
      <TextField
        type="tel"
        fullWidth
        id="mobile_phone"
        label="N° de mobile"
        variant="outlined"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <MdPhoneEnabled size="20" />
            </InputAdornment>
          ),
        }}
        value={form.data.mobile_phone}
        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
          handleChange('mobile_phone', e?.target.value)
        }}
        error={Boolean(form.errors.mobile_phone)}
        helperText={form.errors.mobile_phone}
        sx={{
          mt: 2,
        }}
      />

      {instantCall ? (
        <Button
          variant="contained"
          sx={{
            mt: 2,
          }}
          size="large"
          disabled={Object.keys(form.errors).includes('mobile_phone')}
          onClick={submitFinancement}
        >
          Confirmer
        </Button>
      ) : (
        <>
          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <InputLabel>Jour</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              required
              value={date.date}
              label="Jour"
              onChange={(value) => {
                if (value) {
                  setDate((prevState) => {
                    return {
                      ...prevState,
                      date: value.target.value,
                    }
                  })
                }
              }}
            >
              {listOfDays.map((day, index) => {
                return (
                  <MenuItem value={day.toDateString()} key={index}>
                    {day.toLocaleString('fr', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              mt: 2,
            }}
          >
            <InputLabel>Créneaux disponibles</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              required
              value={date.time}
              label="Créneaux disponibles"
              onChange={(value) => {
                if (value) {
                  setDate((prevState) => {
                    return {
                      ...prevState,
                      time: value.target.value,
                    }
                  })
                }
              }}
            >
              {Array.from({ length: 15 }, (_, i) => {
                if (i === 0) return
                const hour = String(10 + Math.floor(i / 2))
                const minutes = i % 2 === 0 ? '00' : '30'
                const nextHour = String(10 + Math.floor((i + 1) / 2))
                const nextThirtyMinutes = (i + 1) % 2 === 0 ? '00' : '30'

                return (
                  <MenuItem value={`${hour}:${minutes}`} key={i}>
                    {`${hour}h${minutes}`} à {`${nextHour}h${nextThirtyMinutes}`}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {form.data.appointment_dt && (
            <Button
              variant="contained"
              sx={{
                mt: 2,
              }}
              size="large"
              disabled={Object.keys(form.errors).includes('mobile_phone')}
              onClick={submitFinancement}
            >
              Valider mon rendez-vous du{' '}
              {new Date(form.data.appointment_dt).toLocaleString('fr-FR', {
                day: 'numeric',
                month: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </Button>
          )}
        </>
      )}
    </Grid>
  )
}
export default CallForm
