import { Grid, Typography, useTheme, TextField, InputAdornment, Autocomplete } from '@mui/material'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useState } from 'react'

type ValueType = string | null | boolean | undefined | number
type TypeType = 'date' | 'boolean' | 'currency' | undefined
type FieldPropsType = {
  label: string
  onChange?: (value: string) => void
  editMode?: boolean
  required?: boolean
  multiline?: boolean
  select?: boolean
  values?: string[]
  rows?: number
  helperText?: string
  value: ValueType
  type?: TypeType
}

const EditableField = ({
  label,
  value,
  type,
  editMode = false,
  helperText,
  required = true,
  multiline = false,
  select = false,
  values = [],
  rows,
  onChange,
}: FieldPropsType) => {
  const theme = useTheme()
  const [error, setError] = useState<string>('')

  const formatedValue = (value: ValueType, type: TypeType): string => {
    if (!value) return '-'
    switch (type) {
      case 'date':
        if (!value || typeof value === 'boolean') return '-'
        return new Date(value).toLocaleDateString('fr')
      case 'currency':
        if (!value || typeof value !== 'number') return '-'
        return new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(value)
      default:
        return value.toString() || '-'
    }
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" mt={1}>
      {!editMode && (
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
          {label}
        </Typography>
      )}
      {editMode ? (
        <Grid item flexGrow={1} ml={1}>
          {type === 'date' ? (
            <DateField
              id={label}
              label={label}
              required={required}
              format="DD/MM/YYYY"
              value={value ? dayjs(value as string) : null}
              fullWidth
              variant="outlined"
              onChange={(value) => {
                //HACKY
                if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                  onChange && onChange(dayjs(value).format('YYYY-MM-DD'))
                }
              }}
              slots={{
                textField: (params) => (
                  <TextField
                    id={label}
                    {...params}
                    onBlur={() => {
                      if (!dayjs(value as string).isValid()) {
                        setError('Veuillez entrer une date valide')
                      }
                    }}
                    error={Boolean(error)}
                    helperText={error}
                  />
                ),
              }}
            />
          ) : select ? (
            <Autocomplete
              disableClearable
              disablePortal
              blurOnSelect
              id={label}
              value={value?.toString()}
              getOptionLabel={(option) => option}
              onChange={(_, value) => {
                if (value && onChange) onChange(value)
              }}
              isOptionEqualToValue={(option, value) => {
                return option === value.toString()
              }}
              options={values}
              renderInput={(params) => <TextField {...params} required={required} label={label} />}
            />
          ) : (
            <TextField
              label={label}
              select={select}
              value={value ? value : ''}
              multiline={multiline}
              rows={rows}
              fullWidth
              variant="outlined"
              required={required}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                setError('')
                onChange && onChange(e.target.value)
              }}
              onBlur={() => {
                if (required && !value) {
                  setError(`${label} est requis`)
                }
              }}
              error={Boolean(error)}
              helperText={error || helperText}
              InputProps={{
                startAdornment: type === 'currency' && <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          )}
        </Grid>
      ) : (
        <Typography variant="body2">{formatedValue(value, type)}</Typography>
      )}
    </Grid>
  )
}

export default EditableField
