import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Divider, Grid, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { BiError, BiTimer } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Retour } from '../assets/Retour.svg'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { CallUs, Formtitle } from '../components'
import { client } from '../utils/client'
import { learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import { FormType } from '../utils/sharedTypes'

type AlmaPropsType = {
  form: FormType
}

const Alma = ({ form }: AlmaPropsType) => {
  const [status, setStatus] = useState<'success' | 'cancel' | null>(null)
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries(searchParams.entries())

  const [retry, setRetry] = useState(0)
  const navigate = useNavigate()
  const theme = useTheme()
  const [animateRef] = useAutoAnimate<HTMLDivElement>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (form.data.global_id && retry <= 2 && 'success' in params) {
      setStatus('success')
      client
        .post(`/registration/${form.data.global_id}/validate_alma/`)
        .then((resp) => {
          if (resp.status === 200) {
            let url_theme = ''
            if (form.data.theme === 'sante') {
              url_theme = '/sante'
            }
            if (form.data.personal_funding && form.data.funding_organism?.code === 'DPC') {
              navigate(`${url_theme}/inscription_dpc/`)
            }
            navigate(`${url_theme}/recommandation/`)
          }
        })
        .catch((err: Error) => {
          console.error(err)
          setTimeout(() => {
            setRetry((prevState) => prevState + 1)
          }, 5000)
        })
    }
  }, [form.data.global_id, retry])

  useEffect(() => {
    if ('success' in params) {
      setStatus('success')
    }

    if ('cancel' in params) {
      setStatus('cancel')
    }
  }, [params])

  useEffect(() => {
    if (form.data.theme === 'sante') {
      navigate({
        pathname: '/sante/alma/',
        search: location.search,
      })
    } else {
      navigate({
        pathname: '/alma/',
        search: location.search,
      })
    }
  }, [form.data.theme])

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }} ref={animateRef}>
      {status === 'success' && (
        <Grid item xs={12} md={6} sx={{ display: 'block', mt: { xs: 15, md: 0 } }}>
          <Grid container justifyContent="center">
            <Grid
              item
              sx={{
                background: theme.palette.grey[500],
                height: 80,
                width: 80,
                borderRadius: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BiTimer size="40px" style={{ color: 'white' }} />
            </Grid>
          </Grid>
          <Formtitle
            title={'Votre paiement est en cours de traitement'}
            subtitle={
              "Notre équipe s'assure que votre paiement a bien été effectué. Cette étape peut prendre quelques secondes."
            }
            align="center"
          />
        </Grid>
      )}

      {status === 'cancel' && (
        <Grid item xs={12} md={6} sx={{ display: 'block', mt: { xs: 15, md: 0 } }}>
          <Grid container justifyContent="center">
            <Grid
              item
              sx={{
                background: theme.palette.error.main,
                height: 80,
                width: 80,
                borderRadius: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BiError size="40px" style={{ color: 'white' }} />
            </Grid>
          </Grid>
          <Formtitle
            title={'Votre paiement a été annulé'}
            subtitle={'Votre commande et le contrat signé sont annulés. Veuillez recommencer.'}
            align="center"
          />
        </Grid>
      )}

      <Grid
        item
        container
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          pt: 2,
          pl: { xs: 2, md: 4 },
          display: 'flex',
        }}
      >
        <Grid item container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {location.pathname.includes('/sante') ? (
              <WalterSanteColor
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com/sante', '_self')
                }}
              />
            ) : (
              <WalterLearning
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com', '_self')
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: 40, mx: 2 }} />
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              location.pathname.includes('/sante')
                ? window.open('https://walter-learning.com/sante', '_self')
                : window.open('https://walter-learning.com', '_self')
            }}
          >
            <Retour />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Retour au site
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={form.data.theme === 'sante' ? santePhoneNumber : learningPhoneNumber} />
      </Grid>
    </Grid>
  )
}

export default Alma
