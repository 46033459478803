import { Grid } from '@mui/material'
import { InscriptionsTable } from '../../components'
import AdminLayout from '../../components/admin/AdminLayout'

const Admin = () => {
  return (
    <AdminLayout>
      <Grid item xs={12}>
        <InscriptionsTable />
      </Grid>
    </AdminLayout>
  )
}

export default Admin
