import Bugsnag from '@bugsnag/js'
import { Button, Divider, Grid, TextField } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { client } from '../utils/client'
import { FormDefaultState } from '../utils/constants'
import { isStepValid } from '../utils/misc'
import { FormType } from '../utils/sharedTypes'
import DPCTuto from './DPCTuto'

type Props = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Dpc = ({ form, setForm }: Props) => {
  const navigate = useNavigate()
  const tutorialRef = useRef<HTMLDivElement>(null)
  const [copyLink, setCopyLink] = useState(false)

  const handleChange = (key: string, value: string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'dpc_rpps':
          if (!value) {
            errors['dpc_rpps'] = 'Veuillez renseigner votre numéro RPPS'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    if (copyLink) {
      setTimeout(() => {
        setCopyLink(false)
      }, 4000)
    }
  }, [copyLink])

  const submitFinancement = () => {
    client
      .patch(`/registration/${form.data.global_id}/submit/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          setForm((prevState) => {
            return {
              ...prevState,
              data: {
                ...prevState.data,
                dpc_submitted: true,
              },
            }
          })
          if (form.data.funding_organism?.code === 'DPC') {
            navigate({ pathname: './../pre-inscription/', search: location.search })
          } else if (form.data.personal_funding) {
            navigate({ pathname: './../recapitulatif/', search: location.search })
          }
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  useEffect(() => {
    if (form.data.dpc_submitted) {
      tutorialRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [form.data.dpc_submitted])

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        steps: prevState.steps.filter((step) => step.url !== 'recapitulatif'),
      }
    })

    return () => {
      setForm((prevState) => {
        return {
          ...prevState,
          steps: FormDefaultState.steps,
        }
      })
    }
  }, [])

  return (
    <Grid>
      <TextField
        fullWidth
        type="text"
        label="Numéro RPPS"
        id="dpc_rpps"
        variant="outlined"
        required
        value={form.data.dpc_rpps}
        onChange={(e: React.FocusEvent<HTMLInputElement>) => {
          handleChange('dpc_rpps', e?.target.value.replace(/[^0-9]/g, ''))
        }}
        error={Boolean(form.errors.dpc_rpps)}
        helperText={form.errors.dpc_rpps}
        disabled={form.data.dpc_submitted}
      />

      {form.data.dpc_submitted && (
        <>
          <Divider sx={{ my: 5 }} />
          <DPCTuto form={form} setForm={setForm} />
        </>
      )}
      <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          disabled={location.pathname.includes('/information')}
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            {
              !form.data.dpc_submitted
                ? navigate(-1)
                : setForm({ ...form, data: { ...form.data, dpc_submitted: false } })
            }
          }}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          disabled={!isStepValid('financement', form)}
          sx={{
            height: {
              xs: 45,
            },
          }}
          endIcon={form.data.dpc_submitted && <MdNavigateNext />}
          onClick={() => submitFinancement()}
        >
          {!form.data.dpc_submitted ? 'Soumettre' : 'Valider'}
        </Button>
      </Grid>
    </Grid>
  )
}

export default Dpc
