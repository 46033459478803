import { Grid, Typography } from '@mui/material'
import { FormType } from '../utils/sharedTypes'

type Props = {
  form: FormType
}

export default function FundingSummaryAlma({ form }: Props) {
  const hasProducts = form.data.products.length > 0 && form.data.product === null
  const listing_price = hasProducts
    ? form.data.products.reduce((acc, p) => acc + p.listing_price, 0)
    : form.data.product
    ? form.data.product.listing_price
    : 0

  return form.data.registration_type == 'PreConfiguredRegistration' ? (
    <>
      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item sx={{ maxWidth: '50%' }}>
          <Typography variant="body2">Prix {hasProducts ? 'total de mon parcours de' : 'de ma'} formation</Typography>
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(listing_price) || '-'}
        </Typography>
      </Grid>

      {form.data.default_personal_funding &&
        (form.data.personal_funding !== null ? form.data.personal_funding - form.data.default_personal_funding : 0) !=
          0 && (
          <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
            <Grid item sx={{ maxWidth: '50%' }}>
              <Typography variant="body2">Remise commerciale</Typography>
            </Grid>
            <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
              {Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
              }).format(
                form.data.personal_funding !== null
                  ? form.data.personal_funding - form.data.default_personal_funding
                  : 0,
              ) || '-'}
            </Typography>
          </Grid>
        )}

      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="body2">A payer aujourd'hui</Typography>
          {form.data.alma_installments_count !== 1 && (
            <Typography variant="caption">
              Puis{' '}
              {Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
              }).format(
                (form.data.personal_funding || form.data.default_personal_funding || listing_price) /
                  form.data.alma_installments_count,
              ) || '-'}{' '}
              pendant {form.data.alma_installments_count - 1} mois
            </Typography>
          )}
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(
            (form.data.personal_funding || form.data.default_personal_funding || listing_price) /
              form.data.alma_installments_count,
          ) || '-'}
        </Typography>
      </Grid>
    </>
  ) : (
    <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography variant="body2">Prix de la formation</Typography>
        {form.data.alma_installments_count !== 1 && (
          <Typography variant="caption">
            Puis{' '}
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }).format(
              (form.data.personal_funding || form.data.default_personal_funding || listing_price) /
                form.data.alma_installments_count,
            ) || '-'}{' '}
            pendant {form.data.alma_installments_count - 1} mois
          </Typography>
        )}
      </Grid>
      <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
        {Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 2,
        }).format(
          (form.data.personal_funding || form.data.default_personal_funding || listing_price) /
            form.data.alma_installments_count,
        ) || '-'}
        {form.data.alma_installments_count !== 1 && ` à régler dès aujourd'hui`}
      </Typography>
    </Grid>
  )
}
