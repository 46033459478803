import React from 'react'
import { ApprenticeshipFormType } from '../../utils/sharedTypes'
import { Formtitle, Layout } from '../../components'
import { Button, Grid, TextField } from '@mui/material'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'
import { isApprenticeshipStepValid } from '../../utils/misc'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'

type InformationPropsType = {
  form: ApprenticeshipFormType
  setForm: React.Dispatch<React.SetStateAction<ApprenticeshipFormType>>
}

function Enterprise({ form, setForm }: InformationPropsType) {
  const navigate = useNavigate()

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/public/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../profile/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'apprenticeship_company_name':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break
        case 'apprenticeship_company_street':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break
        case 'apprenticeship_company_postal_code':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break
        case 'apprenticeship_company_city':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break
        case 'apprenticeship_company_country':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break

        case 'apprenticeship_company_responsable_first_name':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break

        case 'apprenticeship_company_responsable_last_name':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break

        case 'apprenticeship_company_responsable_email':
          if (!emailValidator(value)) {
            errors[key] = 'Veuillez renseigner un e-mail valide'
          }
          break

        case 'apprenticeship_company_responsable_mobile_phone':
          if (!phoneNumberValidator(value)) {
            errors[key] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }
  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Votre entreprise d'accueil"
        subtitle="Remplissez le formulaire ci-dessous pour poursuivre votre inscription"
      />

      <Grid item container spacing={2}>
        <Grid item md={12}>
          <TextField
            fullWidth
            label="Nom de l'entreprise"
            variant="outlined"
            id="apprenticeship_company_name"
            required
            value={form.data.apprenticeship_company_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_name', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_name)}
            helperText={form.errors.apprenticeship_company_name}
          />
        </Grid>

        <Grid item md={12}>
          <TextField
            fullWidth
            label="Adresse"
            variant="outlined"
            id="apprenticeship_company_street"
            required
            value={form.data.apprenticeship_company_street}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_street', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_street)}
            helperText={form.errors.apprenticeship_company_street}
          />
        </Grid>

        <Grid item md={4}>
          <TextField
            fullWidth
            label="Code postal"
            variant="outlined"
            id="apprenticeship_company_postal_code"
            required
            value={form.data.apprenticeship_company_postal_code}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_postal_code', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_postal_code)}
            helperText={form.errors.apprenticeship_company_postal_code}
          />
        </Grid>

        <Grid item md={4}>
          <TextField
            fullWidth
            label="Ville"
            variant="outlined"
            id="apprenticeship_company_city"
            required
            value={form.data.apprenticeship_company_city}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_city', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_city)}
            helperText={form.errors.apprenticeship_company_city}
          />
        </Grid>

        <Grid item md={4}>
          <TextField
            fullWidth
            label="Pays"
            variant="outlined"
            id="apprenticeship_company_country"
            required
            value={form.data.apprenticeship_company_country}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_country', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_country)}
            helperText={form.errors.apprenticeship_company_country}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            label="Prénom du responsable"
            variant="outlined"
            id="apprenticeship_company_responsable_first_name"
            required
            value={form.data.apprenticeship_company_responsable_first_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_responsable_first_name', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_responsable_first_name)}
            helperText={form.errors.apprenticeship_company_responsable_first_name}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            label="Nom du responsable"
            variant="outlined"
            id="apprenticeship_company_responsable_last_name"
            required
            value={form.data.apprenticeship_company_responsable_last_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_responsable_last_name', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_responsable_last_name)}
            helperText={form.errors.apprenticeship_company_responsable_last_name}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            label="Email du responsable"
            variant="outlined"
            id="apprenticeship_company_responsable_email"
            required
            value={form.data.apprenticeship_company_responsable_email}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_responsable_email', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_responsable_email)}
            helperText={form.errors.apprenticeship_company_responsable_email}
          />
        </Grid>

        <Grid item md={6}>
          <TextField
            fullWidth
            label="Téléphone mobile du responsable"
            variant="outlined"
            id="apprenticeship_company_responsable_mobile_phone"
            required
            value={form.data.apprenticeship_company_responsable_mobile_phone}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('apprenticeship_company_responsable_mobile_phone', e?.target.value)
            }}
            error={Boolean(form.errors.apprenticeship_company_responsable_mobile_phone)}
            helperText={form.errors.apprenticeship_company_responsable_mobile_phone}
          />
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate({ pathname: './../information/', search: location.search })
          }}
        >
          Précédent
        </Button>

        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isApprenticeshipStepValid('enterprise', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}

export default Enterprise
