import { secondsToHMS } from '../utils/misc'
import { Field } from '.'
import { InscriptionType, ExternalInscriptionType } from '../utils/sharedTypes'
import { Grid, Theme, Typography, Link } from '@mui/material'

const FIFPLFields = ({
  registration,
  theme,
}: {
  registration: InscriptionType | ExternalInscriptionType
  theme: Theme
}) => {
  return (
    <Grid>
      <Field label="Mode de financement" value={registration.funding_organism?.code} />
      <Field label="Référence de la session" value={registration?.session?.reference} />
      <Field type="currency" label="Part organisme" value={registration?.sf_case?.organism_part} />
      <Field type="currency" label="Montant facturé" value={registration?.sf_case?.invoiced_amount} />
      <Field label="Début session" type="date" value={registration?.session?.starting_date} />
      <Field label="Fin session" type="date" value={registration?.session?.ending_date} />
      <Field
        label="Temps de connexion réalisé"
        value={secondsToHMS(registration?.sf_case?.walter_session_time || 0)}
        tooltip="Mis à jour toutes les heures"
      />
      <Field
        label="Taux de réalisation"
        value={
          registration?.sf_case
            ? registration?.sf_case?.realized_rate
              ? `${Math.round(registration?.sf_case?.realized_rate * 100)}%`
              : '0%'
            : '-'
        }
        tooltip="Taux calculé par rapport à la part organisme"
      />
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
          Attestation URSSAF
        </Typography>
        <Link href={registration.attestation_urssaf || '#'} target="_blank">
          {registration.attestation_urssaf ? 'Lien' : '-'}
        </Link>
      </Grid>
      {registration.attestation_urssaf && (
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: 250,
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          {registration.attestation_urssaf && (
            <object data={registration.attestation_urssaf} height="250px">
              <img src={registration.attestation_urssaf} height="250px" />
            </object>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default FIFPLFields
