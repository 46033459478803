import { Grid, Typography, useTheme, AppBar, Toolbar, IconButton, Menu, MenuItem, Modal, Button } from '@mui/material'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FunderType } from '../../utils/sharedTypes'
import { IoMdPersonAdd } from 'react-icons/io'
import { FaFileCsv } from 'react-icons/fa'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'

type ProviderLayoutPropsType = {
  children: React.ReactNode
  setRegistrationModal?: (arg: string) => void
  funding?: FunderType[]
}

const ITEM_HEIGHT = 48

const ProviderLayout = ({ children, setRegistrationModal, funding }: ProviderLayoutPropsType) => {
  const theme = useTheme()
  const { provider_id } = useParams()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [modal, setModal] = useState({
    open: false,
    isLoading: false,
  })
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>Gestion de mes inscriptions</title>
      </Helmet>
      <Modal
        open={modal.open}
        onClose={() =>
          setModal((prevState) => {
            return {
              ...prevState,
              open: false,
            }
          })
        }
        sx={{
          display: 'flex',
          mt: 10,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 660,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 5,
            borderRadius: '4px',
          }}
        >
          <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
            Un rapport sous format CSV va être envoyé à votre adresse email
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
            Vous allez recevoir un mail avec un lien pour télécharger le rapport dans quelques minutes, si vous ne
            recevez pas le mail, veuillez vérifier votre dossier de spam
          </Typography>

          <Button
            onClick={() =>
              setModal((prevState) => {
                return {
                  ...prevState,
                  open: false,
                }
              })
            }
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </Grid>
      </Modal>
      <Grid container justifyContent="end" sx={{ minHeight: '100vh' }}>
        <AppBar position="static" sx={{ background: theme.palette.background.paper }}>
          <Toolbar>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={() => {
                if (provider_id) {
                  navigate(`/providers/${provider_id}`)
                } else {
                  navigate('/')
                }
              }}
            >
              Mes inscriptions
            </Typography>

            <IconButton
              aria-label="csv"
              id="long-button"
              sx={{ color: theme.palette.primary.main, mr: 0.5 }}
              disabled={modal.isLoading}
              onClick={() => {
                if (!modal.isLoading && provider_id) {
                  setModal((prevState) => {
                    return {
                      ...prevState,
                      isLoading: true,
                    }
                  })
                  client
                    .post(`/registration/external/${provider_id}/export_csv/`)
                    .then((resp) => {
                      if (resp.status === 200) {
                        setModal((prevState) => {
                          return {
                            ...prevState,
                            open: true,
                          }
                        })
                      }
                    })
                    .catch((err: Error) => {
                      Bugsnag.notify(err)
                      console.error(err)
                    })
                    .finally(() => {
                      setModal((prevState) => {
                        return {
                          ...prevState,
                          isLoading: false,
                        }
                      })
                    })
                }
              }}
            >
              <FaFileCsv />
            </IconButton>
            {setRegistrationModal && funding && (
              <>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  sx={{ color: theme.palette.primary.main }}
                >
                  <IoMdPersonAdd />
                </IconButton>

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  {funding.map((funder) => (
                    <MenuItem
                      key={funder.code}
                      onClick={() => {
                        setRegistrationModal(`${funder.code?.toLowerCase() ?? ''}`)
                        handleClose()
                      }}
                    >
                      Inscription {funder.name}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Grid container sx={{ p: 2, minHeight: { xs: `calc(100vh - 56px)`, sm: `calc(100vh - 64px)` } }}>
          {children}
        </Grid>
      </Grid>
    </HelmetProvider>
  )
}

export default ProviderLayout
