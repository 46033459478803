import {
  FormType,
  NormalFormData,
  ApprenticeshipFormType,
  ApprenticeshipFormData,
  EnterpriseFormData,
  EnterpriseFormType,
  RemunerationType,
} from './sharedTypes'

export const sluggify = (word: string) => {
  return word
    .toString()
    .toLowerCase()
    .trim() // delete trailing & leading spaces
    .replace('ç', 'c')
    .normalize('NFD') //normalize
    .replace(/[\u0300-\u036f]/g, '') // replace diacritics
    .replace(/[^a-zA-Z0-9_. ]/g, ' ') //replace all special characters except spaces, underscores and dots
    .replace(/ /g, '_') //replace all spaces by _
}

export const sleep = (s: number) => new Promise((r) => setTimeout(r, s * 1000))

type Keys = (keyof NormalFormData)[]

const infoFields: Keys = ['first_name', 'last_name', 'mobile_phone', 'email', 'profession', 'rgpd']
const FIFPLFields: Keys = ['budget', 'attestation_urssaf', 'birthdate']
const PersoFields: Keys = ['perso_cgu_cgv', 'perso_program', 'perso_signature', 'perso_checkbox']
const DPCFields: Keys = ['dpc_rpps']
const CPFFields: Keys = ['cpf_starting_date', 'mobile_phone']
const recoFields: Keys = ['first_name', 'last_name', 'mobile_phone', 'email']
const externalDPCInscriptionFields: Keys = [
  'first_name',
  'last_name',
  'mobile_phone',
  'email',
  'profession',
  // 'exercice',
  'dpc_rpps',
]
const externalCPFInscriptionFields: Keys = [
  'first_name',
  'last_name',
  'mobile_phone',
  'email',
  'cpf_starting_date',
  'cpf_price',
]
const externalFIFPLInscriptionFields: Keys = [
  'first_name',
  'last_name',
  'mobile_phone',
  'email',
  'profession',
  // 'exercice',
  'budget',
  'attestation_urssaf',
  'birthdate',
]

export const isStepValid = (stepName: string, form: FormType) => {
  switch (stepName) {
    case 'external_inscription_dpc':
      return (
        externalDPCInscriptionFields.every((val) =>
          val === 'profession' ? form.data[val].code !== '' : form.data[val],
        ) &&
        !externalDPCInscriptionFields.some((key) => Object.keys(form.errors).includes(key)) &&
        form.data.product?.product_code !== ''
      )
    case 'external_inscription_cpf':
      return (
        externalCPFInscriptionFields.every((val) =>
          val === 'profession' ? form.data[val].code !== '' : form.data[val],
        ) &&
        !externalCPFInscriptionFields.some((key) => Object.keys(form.errors).includes(key)) &&
        form.data.product?.product_code !== ''
      )
    case 'external_inscription_fifpl':
      return (
        externalFIFPLInscriptionFields.every((val) =>
          val === 'profession' ? form.data[val].code !== '' : form.data[val],
        ) &&
        form.data.session?.global_id &&
        !externalFIFPLInscriptionFields.some((key) => Object.keys(form.errors).includes(key)) &&
        form.data.product?.product_code !== ''
      )
    case 'information':
      return (
        infoFields.every((val) => (val === 'profession' ? form.data[val]?.code !== '' : form.data[val])) &&
        !infoFields.some((key) => Object.keys(form.errors).includes(key))
      )
    case 'financement': {
      switch (form.data.funding_organism?.code) {
        case 'FIFPL':
          return (
            FIFPLFields.every((val) => form.data[val]) &&
            form.data.session?.global_id &&
            !FIFPLFields.some((key) => Object.keys(form.errors).includes(key))
          )
        case 'ALMA':
          return (
            PersoFields.every((val) => form.data[val]) &&
            !PersoFields.some((key) => Object.keys(form.errors).includes(key))
          )
        case 'DPC': {
          const clone = [...DPCFields]

          return clone.every((val) => form.data[val]) && !clone.some((key) => Object.keys(form.errors).includes(key))
        }
        case 'CPF':
          return (
            CPFFields.every((val) => form.data[val]) && !CPFFields.some((key) => Object.keys(form.errors).includes(key))
          )
        default:
          return false
      }
    }
    case 'recommandation':
      return (
        recoFields.every((val) => form.data[val]) && !recoFields.some((key) => Object.keys(form.errors).includes(key))
      )
    default:
      return false
  }
}

type ApprenticeshipKeys = (keyof ApprenticeshipFormData)[]

const apprenticeshipInfoFields: ApprenticeshipKeys = [
  'first_name',
  'last_name',
  'mobile_phone',
  'email',
  'gender',
  'nationality',
  'nir',
  'street',
  'postal_code',
  'city',
  'country',
  'birth_date',
  'birth_department',
  'birth_city',
  'birth_country',
  'legal_representative_email',
  'legal_representative_first_name',
  'legal_representative_last_name',
  'legal_representative_mobile_phone',
  'legal_representative_street',
  'legal_representative_postal_code',
  'legal_representative_city',
  'legal_representative_country',
  'move_out_city',
  'move_out_postal_code',
]

const apprenticeshipEnterpriseFields: ApprenticeshipKeys = [
  'apprenticeship_company_name',
  'apprenticeship_company_street',
  'apprenticeship_company_postal_code',
  'apprenticeship_company_city',
  'apprenticeship_company_country',
  'apprenticeship_company_responsable_first_name',
  'apprenticeship_company_responsable_last_name',
  'apprenticeship_company_responsable_email',
  'apprenticeship_company_responsable_mobile_phone',
]

const apprenticeshipProfileFields: ApprenticeshipKeys = ['curriculum_vitae']

const apprenticeshipSituationFields: ApprenticeshipKeys = [
  'social_regime',
  'situation_before_contract',
  'last_student_year_outcome',
  'last_diploma_prepared',
  'last_diploma_title',
  'last_diploma_name',
  'authorized_contact_with_companies',
  'engaged_to_follow_training',
  'former_salary',
  'former_weekly_work_duration',
  // CONTRAT PRO
  'pole_emploi_registration_number',
  'pole_emploi_duration',
]

export const isApprenticeshipStepValid = (stepName: string, form: ApprenticeshipFormType) => {
  switch (stepName) {
    case 'information': {
      let clone = [...apprenticeshipInfoFields]

      if (form.data.want_to_move_out === false) {
        clone = clone.filter((key) => !['move_out_city', 'move_out_postal_code'].includes(key))
      }

      if (form.data.has_legal_representative === false) {
        clone = clone.filter(
          (key) =>
            ![
              'legal_representative_email',
              'legal_representative_first_name',
              'legal_representative_last_name',
              'legal_representative_mobile_phone',
              'legal_representative_street',
              'legal_representative_postal_code',
              'legal_representative_city',
              'legal_representative_country',
            ].includes(key),
        )
      }
      if (form.data.is_born_abroad === true) {
        clone = clone.filter((key) => !['birth_department'].includes(key))
      } else if (form.data.is_born_abroad === false) {
        clone = clone.filter((key) => !['birth_country'].includes(key))
      }

      return clone.every((val) => form.data[val]) && !clone.some((key) => Object.keys(form.errors).includes(key))
    }

    case 'enterprise':
      return (
        apprenticeshipEnterpriseFields.every((val) => form.data[val]) &&
        !apprenticeshipEnterpriseFields.some((key) => Object.keys(form.errors).includes(key))
      )

    case 'profile':
      return (
        apprenticeshipProfileFields.every((val) => form.data[val]) &&
        !apprenticeshipProfileFields.some((key) => Object.keys(form.errors).includes(key))
      )

    case 'situation': {
      let clone = [...apprenticeshipSituationFields]

      if (
        form.data.apprenticeship_type === 'Apprentissage' ||
        (form.data.apprenticeship_type === 'ContratPro' &&
          (form.data.is_registered_to_pole_emploi === null || form.data.is_registered_to_pole_emploi === false))
      )
        clone = clone.filter((key) => !['pole_emploi_registration_number', 'pole_emploi_duration'].includes(key))

      if (form.data.apprenticeship_type !== 'Apprentissage') {
        if (form.data.is_former_apprenticeship === false)
          clone = clone.filter((key) => !['former_salary', 'former_weekly_work_duration'].includes(key))

        clone = clone.filter(
          (key) =>
            !['social_regime', 'last_student_year_outcome', 'last_diploma_prepared', 'last_diploma_title'].includes(
              key,
            ),
        )
      }

      if (form.data.is_former_apprenticeship === false)
        clone = clone.filter((key) => !['former_salary', 'former_weekly_work_duration'].includes(key))

      console.log(clone, 'clone')

      return clone.every((val) => form.data[val]) && !clone.some((key) => Object.keys(form.errors).includes(key))
    }

    default:
      return false
  }
}

type EnterpriseKeys = (keyof EnterpriseFormData)[]

const enterpriseInfoFields: EnterpriseKeys = [
  'cerfa_company_name',
  'cerfa_immatriculation_place',
  'cerfa_siret',
  'cerfa_company_street',
  'cerfa_company_postal_code',
  'cerfa_company_city',
  'cerfa_company_country',
  'cerfa_company_email',
  'cerfa_company_phone',
  'cerfa_employer_type',
  'cerfa_employer_specific',
  'cerfa_naf',
  'cerfa_total_employees',
  'cerfa_signatory_first_name',
  'cerfa_signatory_last_name',
  'cerfa_signatory_function',
  'cerfa_opco',

  // CONTRAT PRO
  'cerfa_urssaf',
  'cerfa_prevention_organism',
  'cerfa_project_number',
]

const enterpriseTutorFields: EnterpriseKeys = [
  'cerfa_tutor_last_name',
  'cerfa_tutor_first_name',
  'cerfa_tutor_birth_date',
  'cerfa_tutor_email',
  'cerfa_tutor_mobile_phone',
  'cerfa_tutor_current_job',
  'cerfa_tutor_highest_diploma',
  'cerfa_tutor_highest_level_code',
]

const enterpriseContractFields: EnterpriseKeys = [
  'cerfa_contract_type',
  'cerfa_contract_starting_date',
  // 'cerfa_contract_ending_date',
  'cerfa_formation_starting_date',
  'cerfa_weekly_work_duration',
  // 'cerfa_remuneration_first_year_starting_date',
  // 'cerfa_remuneration_first_year_ending_date',
  'cerfa_remuneration_first_year_percentage',
  'cerfa_remuneration_first_year_type',
  'cerfa_remuneration_first_year_gross_salary',
  'cerfa_supplementary_pension_fund',

  // CONTRAT PRO
  'cerfa_contract_nature',
  'cerfa_job_title',
  'cerfa_classification',
  'cerfa_level',
  'cerfa_hierarchical_coefficient',
  'cerfa_trial_period_duration',
]

export const isEnterpriseStepValid = (stepName: string, form: EnterpriseFormType) => {
  switch (stepName) {
    case 'information': {
      return true
      let clone = [...enterpriseInfoFields]

      if (form.data.apprenticeship_type !== 'Apprentissage')
        clone = clone.filter((key) => !['cerfa_employer_type', 'cerfa_employer_specific'].includes(key))

      if (form.data.apprenticeship_type !== 'ContratPro')
        clone = clone.filter(
          (key) => !['cerfa_urssaf', 'cerfa_prevention_organism', 'cerfa_project_number'].includes(key),
        )

      if (form.data.apprenticeship_type === 'ContratPro' && form.data.cerfa_particular_employer === false)
        clone = clone.filter((key) => !['cerfa_urssaf'].includes(key))

      return clone.every((val) => form.data[val]) && !clone.some((key) => Object.keys(form.errors).includes(key))
    }
    case 'tutor': {
      return true
      return (
        enterpriseTutorFields.every((val) => form.data[val]) &&
        !enterpriseTutorFields.some((key) => Object.keys(form.errors).includes(key))
      )
    }

    case 'contract': {
      return true

      let clone = [...enterpriseContractFields]
      if (form.data.apprenticeship_type === 'Apprentissage') {
        clone = clone.filter(
          (key) =>
            ![
              'cerfa_contract_nature',
              'cerfa_job_title',
              'cerfa_classification',
              'cerfa_level',
              'cerfa_hierarchical_coefficient',
              'cerfa_trial_period_duration',
            ].includes(key),
        )
      }

      return clone.every((val) => form.data[val]) && !clone.some((key) => Object.keys(form.errors).includes(key))
    }

    default:
      return false
  }
}

export const secondsToDHMS = (seconds: number, displaySeconds: boolean = true) => {
  /* Take a number of seconds and return a string with format day, hour, minutes, seconds */
  seconds = Math.round(seconds)
  if (isNaN(seconds)) return ' - '
  if (seconds === 0) return '0s'
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  const hDisplay = h > 0 ? h.toString() + 'h' : ''
  const mDisplay = m > 0 ? m.toString() + 'm' : ''
  const sDisplay = s > 0 && displaySeconds ? s.toString() + 's' : ''
  if (h > 0) {
    return
  }
  const resp = hDisplay + mDisplay + sDisplay
  if (resp === '') return ' - '
  return resp
}

export const secondsToHMS = (seconds: number, displaySeconds: boolean = false) => {
  const time = Math.round(seconds)
  if (isNaN(time)) return ' - '
  if (time === 0) return '0s'

  let h = Math.floor(time / 3600)

  let m = displaySeconds
    ? // If seconds are displayed, the minutes are not rounded because
      // the digits after the decimal will be considered in the seconds calculation
      Math.floor((time % 3600) / 60)
    : // else the minutes have to be rounded to keep the info of the decimals
      Math.round((time % 3600) / 60)

  // check if rounding minutes will make hours roll over
  if (m === 60) {
    h = h + 1
    m = m - 60
  }

  const s = (time % 3600) % 60
  const hDisplay = h > 0 ? h.toString() + 'h' : ''
  const mDisplay =
    h > 0
      ? m.toLocaleString('fr', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      : m.toLocaleString('fr', {
          minimumIntegerDigits: 1,
          useGrouping: false,
        }) + `${h === 0 ? ' min' : 'min'}`
  const sDisplay = s > 0 && displaySeconds ? s.toString() + `${m === 0 ? ' s' : 's'}` : ''
  const resp = hDisplay + mDisplay + sDisplay
  if (resp === '') return ' - '
  return resp
}

export const getRemuneration = (
  birthdate: string | null,
  former_weekly_work_duration: number | null,
  former_salary: number | null,
  start_date: string,
  weekly_work_duration: string,
) => {
  console.log('birthdate', birthdate)
  console.log('former_weekly_work_duration', former_weekly_work_duration)
  console.log('former_salary', former_salary)
  console.log('start_date', start_date)
  console.log('weekly_work_duration', weekly_work_duration)
  if (!birthdate || !start_date || !weekly_work_duration || new Date(birthdate) >= new Date(start_date)) {
    console.log('birthdate,start_date,weekly_work_duration', birthdate, start_date, weekly_work_duration)
    console.log('RETURN UNDEFINED')
    return undefined
  }

  const baseRem = {
    '35': 1766.92,
    '39': 1989.04,
  }

  let rem = baseRem[weekly_work_duration as keyof typeof baseRem]

  if (former_salary && former_weekly_work_duration) {
    const formerHourlyPay = former_salary / (21.67 * (former_weekly_work_duration / 5))
    const expectedCurrentHourlyPay = rem / (21.67 * (parseInt(weekly_work_duration) / 5))
    if (formerHourlyPay > expectedCurrentHourlyPay) {
      console.log('formerHourlyPay > expectedCurrentHourlyPay')
      rem = formerHourlyPay * (parseInt(weekly_work_duration) / 5) * 21.67
    }
  }

  const respMapping = [
    {
      start_age: 0,
      end_age: 17,
      years: [0.27, 0.39, 0.55],
    },
    {
      start_age: 18,
      end_age: 20,
      years: [0.43, 0.51, 0.67],
    },
    {
      start_age: 21,
      end_age: 25,
      years: [0.53, 0.61, 0.78],
    },
    {
      start_age: 26,
      end_age: Infinity,
      years: [1, 1, 1],
    },
  ]

  const contractStartDate = new Date(start_date)
  const birthday = new Date(birthdate)
  const years = Array(3)
    .fill(0)
    .reduce((acc: RemunerationType[], _, i) => {
      Array(12)
        .fill(0)
        .map((_, index) => {
          const monthStartDate = new Date(`${contractStartDate.getFullYear() + i}-${index + 1}-01`)
          const monthStartAge = new Date(monthStartDate.getTime() - birthday.getTime()).getFullYear() - 1970
          const respBracket = respMapping.find((el) => el.start_age <= monthStartAge && el.end_age >= monthStartAge)
          let monthRem = ((respBracket?.years[i] ?? 0) * rem).toFixed(2)
          if (contractStartDate.getMonth() > index && i === 0) {
            monthRem = '0'
          }

          // if (contractStartDate.getMonth() == index && i === 0) {
          // //prorata temporis first month ?
          //   monthRem
          // }

          const obj = {
            year: i,
            month: index,
            monthPCT: respBracket?.years[i],
            monthStartAge: monthStartAge,
            monthRem: parseFloat(monthRem),
          }
          acc.push(obj)
        })
      return acc
    }, [])
  console.log('years', years)
  return years
}

export const addPrefixSpecifier = (word?: string) => {
  if (word) {
    console.log(word[0].toLocaleLowerCase())
    if (['a', 'e', 'y', 'u', 'i', 'o'].includes(word[0].toLocaleLowerCase())) {
      return `l'${word}`
    }
    return `le ${word}`
  }
  return word
}

export const days = [
  {
    value: 'monday',
    label: 'Lundi',
  },
  {
    value: 'tuesday',
    label: 'Mardi',
  },
  {
    value: 'wednesday',
    label: 'Mercredi',
  },
  {
    value: 'thursday',
    label: 'Jeudi',
  },
  {
    value: 'friday',
    label: 'Vendredi',
  },
  {
    value: 'saturday',
    label: 'Samedi',
  },
  {
    value: 'sunday',
    label: 'Dimanche',
  },
]
