import { Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { MdHelpOutline } from 'react-icons/md'

type ValueType = string | null | boolean | undefined | number
type TypeType = 'date' | 'boolean' | 'currency' | undefined
type FieldPropsType = {
  label: string
  value: ValueType
  type?: TypeType
  tooltip?: string | null
}

const Field = ({ label, value, type, tooltip }: FieldPropsType) => {
  const theme = useTheme()
  const formatedValue = (value: ValueType, type: TypeType): string => {
    if (!value) return '-'
    switch (type) {
      case 'date':
        if (!value || typeof value === 'boolean') return '-'
        return new Date(value).toLocaleDateString('fr')
      case 'currency':
        if (!value || typeof value !== 'number') return '-'
        return new Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR' }).format(value)
      default:
        return value.toString() || '-'
    }
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="body1" sx={{ fontWeight: 'bold', color: theme.palette.text.disabled }}>
          {label}
          {tooltip && (
            <Tooltip title={tooltip} placement="right">
              <IconButton size="small">
                <MdHelpOutline />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
        <Typography variant="body2">{formatedValue(value, type)}</Typography>
      </Grid>
    </>
  )
}

export default Field
