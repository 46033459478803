import { useEffect, useState } from 'react'
import {
  Grid,
  Link,
  Paper,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  useTheme,
  Typography,
  TablePagination,
  MenuItem,
  Menu,
  IconButton,
  LinearProgress,
  InputAdornment,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material'
import { client, refreshToken } from '../../utils/client'
import Bugsnag from '@bugsnag/js'
import { useNavigate } from 'react-router-dom'
import { MdMoreVert, MdCheck, MdClose, MdFilterAlt, MdSearch } from 'react-icons/md'
import { InscriptionListRespType, AlertType } from '../../utils/sharedTypes'

type FilterType = {
  submitted: null | boolean
  validated: null | boolean
  email: null | string
  product: null | string
  funding_organism: null | string
  global_search: '' | string
}

const InscriptionTable = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [state, setState] = useState<InscriptionListRespType>({
    count: 0,
    next: '',
    previous: '',
    results: [],
  })
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })

  const [url, setUrl] = useState('')
  const [anchorSubmitted, setAnchorSubmitted] = useState<null | HTMLElement>(null)
  const [anchorValidated, setAnchorValidated] = useState<null | HTMLElement>(null)

  const [filters, setFilters] = useState<FilterType>({
    submitted: null,
    validated: null,
    email: null,
    product: null,
    funding_organism: null,
    global_search: '',
  })

  useEffect(() => {
    let url = `/registration/admin/?page=${currentPage + 1}`
    if (filters.submitted !== null) {
      url = url + `&submitted=${filters.submitted.toString()}`
    }
    if (filters.validated !== null) {
      url = url + `&validated=${filters.validated.toString()}`
    }
    if (filters.global_search !== '') {
      url = url + `&global_search=${filters.global_search.toString()}`
    }
    setUrl(url)
  }, [currentPage, filters])

  const getRegistrations = () => {
    client
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access') || ''}`,
        },
      })
      .then((resp) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const data = resp.data as InscriptionListRespType
        setState(data)
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error('err', err)
        refreshToken(getRegistrations)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (!isLoading && url) {
      setIsLoading(true)
      getRegistrations()
    }
    return () => {
      setIsLoading(false)
    }
  }, [url])

  const validate = (id: number) => {
    client
      .patch(
        `registration/admin/${id}/validate/`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` } },
      )
      .then((resp) => {
        if (resp.status === 200) {
          setAlert({ severity: 'error', message: 'Erreur lors de la validation' })
          getRegistrations()
        } else {
          setAlert({ severity: 'error', message: 'Erreur lors de la validation' })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
        setAlert({ severity: 'error', message: 'Erreur lors de la validation' })
      })
  }

  const discard = (id: number) => {
    client
      .patch(
        `registration/admin/${id}/discard/`,
        {},
        { headers: { Authorization: `Bearer ${localStorage.getItem('access') || ''}` } },
      )
      .then((resp) => {
        if (resp.status === 200) {
          setAlert({ severity: 'info', message: 'Inscription refusée' })
          getRegistrations()
        } else {
          setAlert({ severity: 'error', message: 'Erreur lors du refus' })
        }
      })
      .catch((err: Error) => {
        setAlert({ severity: 'error', message: 'Erreur lors du refus' })
        refreshToken(() => {
          discard(id)
        })
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  return (
    <Paper>
      <Grid container alignItems="center" sx={{ p: 1 }}>
        <Grid item sx={{ width: '100%' }}>
          <TextField
            placeholder="Recherche"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch size="20" />
                </InputAdornment>
              ),
            }}
            value={filters.global_search}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              setFilters((prevState) => {
                return {
                  ...prevState,
                  global_search: e?.target.value,
                }
              })
            }}
            sx={{
              width: '200px',
              input: {
                fontSize: 16,
              },
              '&:focus-within': {
                width: '100%',
              },
              transition: 'width 0.25s',
            }}
          />
        </Grid>
      </Grid>
      {isLoading ? <LinearProgress /> : <div style={{ height: '4px' }}></div>}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">ID</TableCell>
              <TableCell align="left">Prénom</TableCell>
              <TableCell align="left">Nom</TableCell>
              <TableCell align="left">Produit</TableCell>
              <TableCell align="left">Financement</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Téléphone</TableCell>
              <TableCell align="left">Attestation</TableCell>
              <TableCell align="center">
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  Soumis
                  <IconButton
                    sx={{ p: '4px' }}
                    onClick={(e) => {
                      setAnchorSubmitted(e.currentTarget)
                    }}
                  >
                    {filters.submitted !== null ? (
                      <MdFilterAlt size="20" style={{ color: theme.palette.primary.main }} />
                    ) : (
                      <MdMoreVert size="20" style={{ color: theme.palette.grey[500] }} />
                    )}
                  </IconButton>
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                  Validé
                  <IconButton
                    sx={{ p: '4px' }}
                    onClick={(e) => {
                      setAnchorValidated(e.currentTarget)
                    }}
                  >
                    {filters.validated !== null ? (
                      <MdFilterAlt size="20" style={{ color: theme.palette.primary.main }} />
                    ) : (
                      <MdMoreVert size="20" style={{ color: theme.palette.grey[500] }} />
                    )}
                  </IconButton>
                </Grid>
              </TableCell>
              <TableCell align="left">Création</TableCell>
              <TableCell align="left">Source</TableCell>
              <TableCell align="left">Opportunité SF</TableCell>
              <TableCell align="left">Tache SF</TableCell>
              <TableCell align="center">Valider</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.results.length > 0 ? (
              state.results.map((result) => (
                <TableRow
                  onClick={() => {
                    navigate(`/admin/registration/${result.id}`)
                  }}
                  key={result.global_id}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      backgroundColor: theme.palette.grey[200],
                    },
                  }}
                >
                  <TableCell align="left">{result.id}</TableCell>
                  <TableCell align="left">{result.first_name || '-'}</TableCell>
                  <TableCell align="left">{result.last_name || '-'}</TableCell>
                  <TableCell align="left">{result.product?.name || '-'}</TableCell>
                  <TableCell align="left">{result.funding_organism?.code || '-'}</TableCell>
                  <TableCell align="left">{result.email || '-'}</TableCell>
                  <TableCell align="left">{result.mobile_phone || '-'}</TableCell>
                  <TableCell align="left">
                    {result?.attestation_urssaf ? (
                      <Link
                        href={result.attestation_urssaf}
                        target="_blank"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          if (result.attestation_urssaf) {
                            window.open(result.attestation_urssaf, '_blank')
                          }
                        }}
                      >
                        Attestation
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {result.submitted ? (
                      <MdCheck size="20" style={{ color: theme.palette.success.main }} />
                    ) : (
                      <MdClose size="20" style={{ color: theme.palette.error.main }} />
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {result.validated ? (
                      <MdCheck size="20" style={{ color: theme.palette.success.main }} />
                    ) : (
                      <MdClose size="20" style={{ color: theme.palette.error.main }} />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {result.created_at
                      ? new Date(result.created_at).toLocaleDateString('fr', { hour: '2-digit', minute: '2-digit' })
                      : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {result.entrypoint_url ? (
                      <Link href={result.entrypoint_url} target="_blank">
                        {result.entrypoint_url}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {result.sf_opportunity_id ? (
                      <Link
                        href={`https://walterlearning.lightning.force.com/lightning/r/Opportunity/${result.sf_opportunity_id}/view`}
                        target="_blank"
                      >
                        {result.sf_opportunity_id}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell align="left">
                    {result.sf_task_id ? (
                      <Link
                        href={`https://walterlearning.lightning.force.com/lightning/r/Task/${result.sf_task_id}/view`}
                        target="_blank"
                      >
                        {result.sf_task_id}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Grid container wrap="nowrap" direction="row">
                      <Grid item>
                        <Button
                          disabled={
                            result.validated === true ||
                            result.discarded === true ||
                            result?.funding_organism?.code === 'DPC'
                          }
                          sx={{ width: 65, mr: 1 }}
                          size="small"
                          variant="outlined"
                          color="success"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            validate(result.id)
                          }}
                        >
                          Valider
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          sx={{ width: 65 }}
                          size="small"
                          variant="outlined"
                          disabled={
                            result.validated === true ||
                            result.discarded === true ||
                            result?.funding_organism?.code === 'DPC'
                          }
                          color="error"
                          onClick={(e) => {
                            if (result.id) {
                              e.preventDefault()
                              e.stopPropagation()
                              discard(result.id)
                            }
                          }}
                        >
                          Refuser
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={13} align="center" style={{ height: '250px', color: theme.palette.text.disabled }}>
                  <Grid container direction="column" justifyContent="center">
                    <Grid item>
                      {!isLoading && (
                        <Typography variant="h6" sx={{ fontStyle: 'italic' }}>
                          Aucune inscription
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[state.results.length]}
          component="div"
          count={state.count}
          rowsPerPage={state.results.length}
          page={currentPage}
          onPageChange={(_event, newPage) => {
            window.scrollTo(0, 0)
            setCurrentPage(newPage)
          }}
        />
      </TableContainer>

      <Menu
        anchorEl={anchorSubmitted}
        open={Boolean(anchorSubmitted)}
        onClose={() => {
          setAnchorSubmitted(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                submitted: true,
              }
            })
            setAnchorSubmitted(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.submitted ? 'visible' : 'hidden' }} /> Soumis
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                submitted: false,
              }
            })
            setAnchorSubmitted(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.submitted === false ? 'visible' : 'hidden' }} />{' '}
          Abandonné
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                submitted: null,
              }
            })
            setAnchorSubmitted(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.submitted === null ? 'visible' : 'hidden' }} /> Tout
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={anchorValidated}
        open={Boolean(anchorValidated)}
        onClose={() => {
          setAnchorValidated(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                validated: true,
              }
            })
            setAnchorValidated(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.validated ? 'visible' : 'hidden' }} /> Validé
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                validated: false,
              }
            })
            setAnchorValidated(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.validated === false ? 'visible' : 'hidden' }} /> En
          attente
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFilters((prevState) => {
              return {
                ...prevState,
                validated: null,
              }
            })
            setAnchorValidated(null)
          }}
        >
          <MdCheck style={{ marginRight: 5, visibility: filters.validated === null ? 'visible' : 'hidden' }} /> Tout
        </MenuItem>
      </Menu>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Paper>
  )
}

export default InscriptionTable
