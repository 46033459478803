import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { Formtitle, Layout } from '../../components'
import { EnterpriseFormType } from '../../utils/sharedTypes'
import { useNavigate } from 'react-router-dom'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'
import { MdNavigateNext } from 'react-icons/md'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'
import { isEnterpriseStepValid } from '../../utils/misc'

type InformationPropsType = {
  form: EnterpriseFormType
  setForm: React.Dispatch<React.SetStateAction<EnterpriseFormType>>
}

export default function Information({ form, setForm }: InformationPropsType) {
  const navigate = useNavigate()

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/enterprise/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../maitre-apprentissage/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string> | number) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'cerfa_company_name':
        case 'cerfa_immatriculation_place':
        case 'cerfa_company_street':
        case 'cerfa_company_postal_code':
        case 'cerfa_company_city':
        case 'cerfa_company_country':
        case 'cerfa_convention_number':
        case 'cerfa_total_employees':
        case 'cerfa_signatory_first_name':
        case 'cerfa_signatory_last_name':
        case 'cerfa_signatory_function':
        case 'cerfa_opco':
          if (!value) {
            errors[key] = 'Ce champ ne peut pas être vide.'
          }
          break

        case 'cerfa_siret':
          if (!value || (value as string).length !== 14) {
            errors[key] = 'Veuillez saisir un numéro de SIRET valide.'
          }
          break

        case 'cerfa_naf':
          if (!/^\d{4}[A-Z]{1}$/.test(value as string)) {
            errors[key] = 'Veuillez saisir un code NAF valide.'
          }
          break

        case 'cerfa_company_email':
          if (!emailValidator(value as string)) {
            errors[key] = 'Veuillez saisir une adresse mail valide.'
          }
          break

        case 'cerfa_company_phone':
          if (!phoneNumberValidator(value as string)) {
            errors[key] = 'Veuillez saisir un numéro de téléphone valide.'
          }
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Informations sur l'entreprise"
        subtitle="Veuillez renseigner les informations de votre entreprise."
      />
      <Grid item container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom et prénom ou dénomination"
            variant="outlined"
            id="company_name"
            required
            value={form.data.cerfa_company_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_name', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_name)}
            helperText={form.errors.cerfa_company_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Lieu d'immatriculation"
            variant="outlined"
            id="cerfa_immatriculation_place"
            required
            value={form.data.cerfa_immatriculation_place}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_immatriculation_place', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_immatriculation_place)}
            helperText={form.errors.cerfa_immatriculation_place}
          />
        </Grid>

        {form.data.apprenticeship_type === 'ContratPro' && (
          <>
            <Grid container item alignItems="center">
              <Checkbox
                id="cerfa_particular_employer"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.cerfa_particular_employer ?? false}
                onClick={() => {
                  handleChange('cerfa_particular_employer', !form.data.cerfa_particular_employer)
                }}
              />
              <Typography>Particulier - employeur</Typography>
            </Grid>

            {form.data.cerfa_particular_employer && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Numéro URSSAF"
                  variant="outlined"
                  id="cerfa_urssaf"
                  required
                  value={form.data.cerfa_urssaf}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('cerfa_urssaf', e?.target.value)
                  }}
                  error={Boolean(form.errors.cerfa_urssaf)}
                  helperText={form.errors.cerfa_urssaf}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Numéro de SIRET"
            variant="outlined"
            id="cerfa_siret"
            required
            value={form.data.cerfa_siret}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_siret', e?.target.value.replace(/[^0-9]/g, ''))
            }}
            error={Boolean(form.errors.cerfa_siret)}
            helperText={form.errors.cerfa_siret}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Adresse du siège social"
            variant="outlined"
            id="cerfa_company_street"
            required
            value={form.data.cerfa_company_street}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_street', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_street)}
            helperText={form.errors.cerfa_company_street}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Code postal"
            variant="outlined"
            id="cerfa_company_postal_code"
            required
            value={form.data.cerfa_company_postal_code}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_postal_code', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_postal_code)}
            helperText={form.errors.cerfa_company_postal_code}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Ville"
            variant="outlined"
            id="cerfa_company_city"
            required
            value={form.data.cerfa_company_city}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_city', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_city)}
            helperText={form.errors.cerfa_company_city}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Pays"
            variant="outlined"
            id="cerfa_company_country"
            required
            value={form.data.cerfa_company_country}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_country', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_country)}
            helperText={form.errors.cerfa_company_country}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Adresse mail"
            variant="outlined"
            id="cerfa_company_email"
            required
            value={form.data.cerfa_company_email}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_email', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_email)}
            helperText={form.errors.cerfa_company_email}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Numéro de téléphone"
            variant="outlined"
            id="cerfa_company_phone"
            required
            value={form.data.cerfa_company_phone}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_company_phone', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_company_phone)}
            helperText={form.errors.cerfa_company_phone}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Numéro de convention collective"
            variant="outlined"
            id="cerfa_convention_number"
            required
            value={form.data.cerfa_convention_number}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_convention_number', e?.target.value.replace(/[^0-9]/g, ''))
            }}
            error={Boolean(form.errors.cerfa_convention_number)}
            helperText={form.errors.cerfa_convention_number}
          />
        </Grid>

        {form.data.apprenticeship_type === 'Apprentissage' && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Type d'employeur</InputLabel>
                <Select
                  fullWidth
                  label="Type d'employeur"
                  variant="outlined"
                  id="apprenticeship_company_name"
                  value={form.data.cerfa_employer_type}
                  onChange={(e) => handleChange('cerfa_employer_type', e.target.value)}
                >
                  <ListSubheader>Privé</ListSubheader>
                  <MenuItem value="11">
                    Entreprise inscrite au répertoire des métiers ou au registre des entreprises pour l’Alsace-Moselle
                  </MenuItem>
                  <MenuItem value="12">Entreprise inscrite uniquement au registre du commerce et des sociétés</MenuItem>
                  <MenuItem value="13">
                    Entreprises dont les salariés relèvent de la mutualité sociale agricole
                  </MenuItem>
                  <MenuItem value="14">Profession libérale</MenuItem>
                  <MenuItem value="15">Association</MenuItem>
                  <MenuItem value="16">Autre employeur privé</MenuItem>

                  <ListSubheader>Public</ListSubheader>
                  <MenuItem value="21">
                    Service de l’Etat (administrations centrales et leurs services déconcentrés de la fonction publique
                    d’Etat)
                  </MenuItem>
                  <MenuItem value="22">Commune</MenuItem>
                  <MenuItem value="23">Département</MenuItem>
                  <MenuItem value="24">Région</MenuItem>
                  <MenuItem value="25">Etablissement public hospitalier</MenuItem>
                  <MenuItem value="26">Etablissement public local d’enseignement</MenuItem>
                  <MenuItem value="27">Etablissement public administratif de l’Etat</MenuItem>
                  <MenuItem value="28">
                    Etablissement public administratif local (y compris établissement public de coopération
                    intercommunale EPCI)
                  </MenuItem>
                  <MenuItem value="29">Autre employeur public</MenuItem>
                  <MenuItem value="30">Etablissement public industriel et commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Employeur spécifique</InputLabel>
                <Select
                  label="Employeur spécifique"
                  variant="outlined"
                  id="cerfa_specific_employer"
                  value={form.data.cerfa_employer_specific}
                  onChange={(e) => {
                    handleChange('cerfa_employer_specific', e.target.value)
                  }}
                >
                  <MenuItem value="1">Entreprise de travail temporaire</MenuItem>
                  <MenuItem value="2">Groupement d’employeurs</MenuItem>
                  <MenuItem value="3">Employeur saisonnier</MenuItem>
                  <MenuItem value="4">Apprentissage familial : l’employeur est un ascendant de l’apprenti</MenuItem>
                  <MenuItem value="0">Aucun de ces cas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        {form.data.apprenticeship_type === 'ContratPro' && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Organisme de prévoyance"
                variant="outlined"
                id="cerfa_prevention_organism"
                required
                value={form.data.cerfa_prevention_organism}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_prevention_organism', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_prevention_organism)}
                helperText={form.errors.cerfa_prevention_organism}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                label="Numéro de projet"
                variant="outlined"
                id="cerfa_project_number"
                required
                value={form.data.cerfa_project_number}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_project_number', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_project_number)}
                helperText="A renseigner uniquement dans le cas d'un contrat de professionnalisation associant des actions de validation des acquis de l'expérience mentionné à l'article 11 de la loi n° 2022-1598 du 21 décembre 2022. Ce numéro figure sur la notification d’acceptation du projet délivrée au porteur de projet par la délégation générale à l'emploi et à la formation professionnelle (DGEFP)"
              />
            </Grid> */}
          </>
        )}

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Code NAF"
            variant="outlined"
            id="cerfa_naf"
            required
            value={form.data.cerfa_naf}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_naf', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_naf)}
            helperText={form.errors.cerfa_naf}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Effectif total salariés de l'entreprise"
            variant="outlined"
            id="cerfa_total_employees"
            required
            value={form.data.cerfa_total_employees}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_total_employees', e?.target.value.replace(/[^0-9]/g, ''))
            }}
            error={Boolean(form.errors.cerfa_total_employees)}
            helperText={form.errors.cerfa_total_employees}
            InputProps={{
              endAdornment: <span>salariés</span>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Prénom du signataire"
            variant="outlined"
            id="cerfa_signatory_first_name"
            required
            value={form.data.cerfa_signatory_first_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_signatory_first_name', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_signatory_first_name)}
            helperText={form.errors.cerfa_signatory_first_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom du signataire"
            variant="outlined"
            id="cerfa_signatory_last_name"
            required
            value={form.data.cerfa_signatory_last_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_signatory_last_name', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_signatory_last_name)}
            helperText={form.errors.cerfa_signatory_last_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Qualité du signataire"
            variant="outlined"
            id="cerfa_signatory_function"
            required
            value={form.data.cerfa_signatory_function}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_signatory_function', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_signatory_function)}
            helperText={form.errors.cerfa_signatory_function}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom de l'OPCO concerné"
            variant="outlined"
            id="cerfa_opco"
            required
            value={form.data.cerfa_opco}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_opco', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_opco)}
            helperText={form.errors.cerfa_opco}
          />
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isEnterpriseStepValid('information', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}
