import { Grid, Typography } from '@mui/material'
import { FormType } from '../utils/sharedTypes'

type Props = {
  form: FormType
}

export default function FundingSummaryDPC({ form }: Props) {
  const hasProducts = form.data.products.length > 1 && form.data.product === null
  const listing_price = form.data.funding_organism_registrations.reduce((acc, r) => acc + r.product_listing_price, 0)

  const personalFunding =
    form.data.personal_funding !== null ? form.data.personal_funding : form.data.default_personal_funding ?? 0
  const totalPartOrga = form.data.funding_organism_registrations.reduce((acc, r) => acc + Number(r.part_orga), 0)
  const totalCompensation = form.data.funding_organism_registrations.reduce((acc, r) => acc + Number(r.compensation), 0)

  return (
    <Grid item container spacing={1}>
      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item sx={{ maxWidth: '50%' }}>
          <Typography variant="body2">Prix {hasProducts ? 'total de mon parcours de' : 'de ma'} formation</Typography>
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(listing_price) || '-'}
        </Typography>
      </Grid>

      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item sx={{ maxWidth: '50%' }}>
          <Typography variant="body2">Montant {hasProducts ? 'total' : ''} pris en charge par l'ANDPC</Typography>
        </Grid>

        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(
            totalPartOrga ??
              form.data.product_catalog?.funding_price ??
              form.data.product_catalog?.listing_price ??
              listing_price,
          ) || '-'}
        </Typography>
      </Grid>

      {form.data.default_personal_funding &&
        (form.data.personal_funding !== null ? form.data.personal_funding - form.data.default_personal_funding : 0) !=
          0 && (
          <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
            <Grid item sx={{ maxWidth: '50%' }}>
              <Typography variant="body2">Remise commerciale</Typography>
            </Grid>
            <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 400 }}>
              {Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: 2,
              }).format(
                form.data.personal_funding !== null
                  ? form.data.personal_funding - form.data.default_personal_funding
                  : 0,
              ) || '-'}
            </Typography>
          </Grid>
        )}

      <Grid
        item
        container
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-around"
        alignItems={{ xs: 'center', md: 'end' }}
        wrap="nowrap"
        mt={3}
      >
        <Grid item>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 18, fontWeight: 500 }}>
            A payer maintenant
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 25, fontWeight: 500 }}>
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }).format(
              form.data.personal_funding !== null
                ? form.data.personal_funding
                : form.data.default_personal_funding ?? 0,
            )}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 18, fontWeight: 500 }}>
            Vos indemnités {totalCompensation - personalFunding > 0 && 'nettes'}
            <sup>*</sup>
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: 25, fontWeight: 500 }}>
            {Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 2,
            }).format(
              totalCompensation - personalFunding > 0 ? totalCompensation - personalFunding : totalCompensation,
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Typography variant="caption" textAlign="center" mt={2}>
          <sup>*</sup>
          {totalCompensation - personalFunding > 0 ? (
            <>
              Estimation des indemnités perçues en cas de validation de{' '}
              {hasProducts ? `vos ${form.data.products.length} formations` : 'votre formation'}
              {personalFunding > 0 && " après déduction du montant payé aujourd'hui"}
            </>
          ) : (
            <>
              Estimation des indemnités perçues en cas de validation de{' '}
              {hasProducts ? `vos ${form.data.products.length} formations` : 'votre formation'}
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  )
}
