import { Grid, Typography, Button, Divider, Link, Switch, FormControlLabel, Checkbox, useTheme } from '@mui/material'
import { FormType } from '../utils/sharedTypes'
// import { StartingDate } from '.'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ModalContract from './ModalContract'
import { useNavigate } from 'react-router-dom'
import { MdNavigateBefore, MdNavigateNext, MdOutlineZoomIn } from 'react-icons/md'
import { client } from '../utils/client'
import Bugsnag from '@bugsnag/js'
import { isStepValid } from '../utils/misc'

type PersoFinPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const PersoFin = ({ form, setForm }: PersoFinPropsType) => {
  const navigate = useNavigate()
  const [documents, setDocuments] = useState<{ [key: string]: string }>({})
  const [modal, setModal] = useState<{
    open: boolean
    html_document: string
    has_button: boolean
  }>({
    open: false,
    html_document: '',
    has_button: false,
  })
  const theme = useTheme()
  const handleChange = (key: string, value: number | string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    client
      .get(`/registration/${form.data.global_id}/cgu/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            cgu: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/alma_cgv/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            cgv: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/alma_contract/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            contract: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }, [form.data.funding_organism?.code])

  const submitFinancement = () => {
    client
      .patch(`/registration/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../recapitulatif/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const numberOfInstallments = () => {
    if (!form.data.product) {
      return 0
    }

    if (form.data.product.listing_price < 299) {
      return 2
    }
    if (form.data.product.listing_price < 399) {
      return 3
    }
    return 4
  }

  return (
    <Grid container direction="column">
      <Grid
        container
        wrap="nowrap"
        sx={{
          gap: 2.5,
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
        }}
      >
        <Grid item container direction="column" alignItems="flex-start">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
            }}
          >
            Prix de la formation
          </Typography>
          <Typography variant="caption">Exonération de TVA - Art. 261 du CGI</Typography>
          <Typography
            variant="h3"
            sx={{
              fontFamily: 'Work Sans',
              fontWeight: 400,
            }}
          >
            {Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
              form.data.product?.listing_price || 0,
            )}
          </Typography>
        </Grid>
        <Grid item container>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
            }}
          >
            Échéances
          </Typography>
          <Grid
            item
            justifyContent="start"
            container
            spacing={1}
            sx={{
              my: {
                xs: 1,
              },
            }}
          >
            {Array.from(Array(numberOfInstallments()).keys()).map((el) => {
              return (
                <Grid item key={el}>
                  <Button
                    variant={form.data.alma_installments_count === el + 1 ? 'contained' : 'outlined'}
                    fullWidth
                    onClick={() => {
                      handleChange('alma_installments_count', el + 1)
                    }}
                    sx={{
                      textTransform: 'none',
                      height: 56,
                    }}
                  >
                    {`${el + 1}x`}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      {form.data.alma_installments_count > 0 && form.data.product && form.data.product.listing_price && (
        <>
          <Grid
            item
            container
            direction="column"
            sx={{ background: 'rgba(0,0,0,0.045)', p: 2, borderRadius: '4px', mt: 2 }}
          >
            {Array.from(Array(form.data.alma_installments_count).keys()).map((_, index) => {
              return (
                <Grid container alignItems="center" justifyContent="space-between" mb={0.6} key={index}>
                  <Typography variant="caption">{index === 0 ? "Aujourd'hui" : `Dans ${index} mois`} </Typography>
                  <Typography variant="caption">
                    {Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(
                      (form.data.product?.listing_price || 0) / form.data.alma_installments_count,
                    )}
                  </Typography>
                </Grid>
              )
            })}

            <Grid container alignItems="center" justifyContent="space-between">
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                }}
              >
                Total TTC
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                }}
              >
                {Intl.NumberFormat('fr', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 }).format(
                  form.data.product.listing_price,
                )}
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4.5 }} />

          <Grid container direction="column">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Documents contractuels
            </Typography>

            <Grid
              container
              flexWrap="nowrap"
              sx={{
                p: 2,
                background: form.data.perso_program ? `${theme.palette.success.main}1A` : 'transparent',
                border: form.data.perso_program
                  ? `1px solid ${theme.palette.success.main}1A`
                  : '1px solid rgba(0,0,0,0.12)',
                borderRadius: '4px',
                mt: 2,

                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
              }}
            >
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Consultez{' '}
                  <Link href={form.data.product?.program_url} target="_blank">
                    le programme de la formation
                  </Link>
                </Typography>
              </Grid>

              <Divider
                sx={{
                  width: {
                    md: 0,
                    xs: '100%',
                  },

                  height: {
                    md: 0,
                    xs: '1px',
                  },

                  my: {
                    md: 0,
                    xs: 2,
                  },
                }}
              />

              <Grid item container justifyContent="end">
                <FormControlLabel
                  required
                  value={form.data.perso_program}
                  control={
                    <Switch
                      color="success"
                      checked={form.data.perso_program}
                      onChange={(e) => handleChange('perso_program', e.target.checked)}
                    />
                  }
                  sx={{
                    fontWeight: form.data.perso_program ? 500 : 400,
                  }}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: form.data.perso_program ? 500 : 400,
                      }}
                    >
                      Lu et approuvé
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              flexWrap="nowrap"
              sx={{
                p: 2,
                background: form.data.perso_cgu_cgv ? `${theme.palette.success.main}1A` : 'transparent',
                border: form.data.perso_cgu_cgv
                  ? `1px solid ${theme.palette.success.main}1A`
                  : '1px solid rgba(0,0,0,0.12)',
                borderRadius: '4px',
                mt: 2,

                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
              }}
            >
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Acceptez les{' '}
                  <Link
                    onClick={() => {
                      setModal({
                        open: true,
                        html_document: documents.cgv,
                        has_button: false,
                      })
                    }}
                  >
                    Conditions Générales de Vente
                  </Link>{' '}
                  et les{' '}
                  <Link
                    onClick={() => {
                      setModal({
                        open: true,
                        html_document: documents.cgu,
                        has_button: false,
                      })
                    }}
                  >
                    Conditions Générales d'Utilisation
                  </Link>
                </Typography>
              </Grid>

              <Divider
                sx={{
                  width: {
                    md: 0,
                    xs: '100%',
                  },

                  height: {
                    md: 0,
                    xs: '1px',
                  },

                  my: {
                    md: 0,
                    xs: 2,
                  },
                }}
              />
              <Grid item container justifyContent="end">
                <FormControlLabel
                  required
                  value={form.data.perso_cgu_cgv}
                  control={
                    <Switch
                      color="success"
                      checked={form.data.perso_cgu_cgv}
                      onChange={(e) => handleChange('perso_cgu_cgv', e.target.checked)}
                    />
                  }
                  sx={{
                    fontWeight: form.data.perso_cgu_cgv ? 500 : 400,
                  }}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: form.data.perso_cgu_cgv ? 500 : 400,
                      }}
                    >
                      Lu et approuvé
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Grid
              sx={{
                width: '100%',
                height: '300px',
                mt: 2,
                py: 6,
                px: 10,
                borderRadius: '4px 4px 0 0',
                background: 'rgba(0,0,0,0.54)',

                overflow: 'hidden',
                position: 'relative',

                cursor: 'pointer',
              }}
              onClick={() => {
                setModal({
                  open: true,
                  html_document: documents.contract,
                  has_button: true,
                })
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'rgba(0,0,0,0.54)',
                  position: 'absolute',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: '4px',
                    background: 'white',
                  }}
                >
                  <MdOutlineZoomIn size="30px" />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  background: 'white',
                  borderRadius: '4px',
                  py: 2,
                  px: 4,
                }}
                dangerouslySetInnerHTML={{
                  __html: documents.contract?.replace(/margin/g, ''),
                }}
              ></Grid>
            </Grid>
            <Grid
              container
              flexWrap="nowrap"
              sx={{
                p: 2,
                background: form.data.perso_signature ? `${theme.palette.success.main}1A` : 'transparent',
                border: form.data.perso_signature
                  ? `1px solid ${theme.palette.success.main}1A`
                  : '1px solid rgba(0,0,0,0.12)',
                borderRadius: '0 0 4px 4px',
                cursor: 'pointer !important',

                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
              }}
              onClick={() =>
                setModal({
                  open: true,
                  html_document: documents.contract,
                  has_button: true,
                })
              }
            >
              <Grid container direction="column">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Signez votre contrat de formation
                </Typography>
                <Typography variant="caption">Je lis et signe le contrat de formation</Typography>
              </Grid>

              <Divider
                sx={{
                  width: {
                    md: 0,
                    xs: '100%',
                  },

                  height: {
                    md: 0,
                    xs: '1px',
                  },

                  my: {
                    md: 0,
                    xs: 2,
                  },
                }}
              />
              <Grid item container justifyContent="end">
                <FormControlLabel
                  required
                  control={<Switch color="success" disabled checked={form.data.perso_signature} />}
                  sx={{
                    fontWeight: form.data.perso_signature ? 500 : 400,
                  }}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: form.data.perso_signature ? 500 : 400,
                      }}
                    >
                      Lu et approuvé
                    </Typography>
                  }
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 4.5 }} />

            <Grid item container alignItems="start" wrap="nowrap">
              <Checkbox
                id="perso_checkbox"
                checked={form.data.perso_checkbox}
                onClick={() => {
                  handleChange('perso_checkbox', !form.data.perso_checkbox)
                }}
              />
              <Typography variant="body2">
                Je demande l'accès immédiat à la formation et renonce à mon droit de rétractation. Je comprends que les
                conditions d'annulation décrites dans les CGV s'appliquent.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <ModalContract
        modal={modal}
        setModal={setModal}
        isSigningUp={() => {
          if (!modal.has_button) return
          handleChange('perso_signature', true)
          handleChange('alma_contract', documents.contract)
          handleChange('alma_contract_signed_at', new Date().toISOString())
          setModal({
            open: false,
            html_document: '',
            has_button: false,
          })
        }}
      />

      <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          disabled={location.pathname.includes('/information')}
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate(-1)
          }}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          disabled={!isStepValid('financement', form)}
          sx={{
            height: {
              xs: 45,
            },
          }}
          endIcon={<MdNavigateNext />}
          onClick={submitFinancement}
        >
          Suivant
        </Button>
      </Grid>
    </Grid>
  )
}

export default PersoFin
