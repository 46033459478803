import { Grid, Modal } from '@mui/material'
import InscriptionTable from '../../components/external_provider/InscriptionTable'
import ProviderLayout from '../../components/external_provider/ProviderLayout'
import { useEffect, useState } from 'react'
import DPCInscriptionForm from '../../components/external_provider/DPCInscriptionForm'
import CPFInscriptionForm from '../../components/external_provider/CPFInscriptionForm'
import FIFPLInscriptionForm from '../../components/external_provider/FIFPLInscriptionForm'

import { ExternalInscriptionProductType, FunderType } from '../../utils/sharedTypes'
import { useParams } from 'react-router-dom'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'

const Dashboard = () => {
  const { provider_id } = useParams()
  const [registrationModal, setRegistrationModal] = useState('')
  const [externalInscriptionProducts, setExternalInscriptionProducts] = useState<{
    [key: string]: ExternalInscriptionProductType[]
  }>({})

  const [refetch, setRefetch] = useState(false)
  const [funding, setFunding] = useState<FunderType[]>([])

  useEffect(() => {
    if (provider_id) {
      client
        .get(`/registration/external/${provider_id}/funding_organisms/`)
        .then((resp) => {
          const data = resp.data as FunderType[]
          setFunding(data)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }, [provider_id])

  useEffect(() => {
    if (provider_id && funding) {
      funding.forEach((funder) => {
        client
          .get(`/registration/external/${provider_id}/products/?funding_organism=${funder.code as string}`)
          .then((resp) => {
            const data = resp.data as ExternalInscriptionProductType[]
            setExternalInscriptionProducts((prevState) => {
              return {
                ...prevState,
                [funder.code as string]: data,
              }
            })
          })
          .catch((err: Error) => {
            Bugsnag.notify(err)
            console.error(err)
          })
      })
    }
  }, [funding])

  return (
    <ProviderLayout setRegistrationModal={setRegistrationModal} funding={funding}>
      <Grid item xs={12}>
        <InscriptionTable refetch={refetch} />
        <Modal
          open={registrationModal === 'dpc'}
          onClose={() => {
            setRegistrationModal('')
          }}
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            <DPCInscriptionForm
              products={externalInscriptionProducts['DPC']}
              setRefetch={setRefetch}
              refetch={refetch}
              setClose={() => {
                setRegistrationModal('')
              }}
            />
          </>
        </Modal>
        <Modal
          open={registrationModal === 'cpf'}
          onClose={() => {
            setRegistrationModal('')
          }}
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            <CPFInscriptionForm
              products={externalInscriptionProducts['CPF']}
              setRefetch={setRefetch}
              refetch={refetch}
              setClose={() => {
                setRegistrationModal('')
              }}
            />
          </>
        </Modal>
        <Modal
          open={registrationModal === 'fifpl'}
          onClose={() => {
            setRegistrationModal('')
          }}
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            <FIFPLInscriptionForm
              products={externalInscriptionProducts['FIFPL']}
              setRefetch={setRefetch}
              refetch={refetch}
              setClose={() => {
                setRegistrationModal('')
              }}
            />
          </>
        </Modal>
      </Grid>
    </ProviderLayout>
  )
}

export default Dashboard
