import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormType } from '../utils/sharedTypes'
import { client } from '../utils/client'
import {
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import ModalContract from './ModalContract'
import Bugsnag from '@bugsnag/js'
import { MdOutlineZoomIn } from 'react-icons/md'

type Props = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
  reload: boolean
}

export default function StripeContract({ form, setForm, reload }: Props) {
  const formatter = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })

  const theme = useTheme()
  const [documents, setDocuments] = useState<{ [key: string]: string }>({})
  const [modal, setModal] = useState<{
    open: boolean
    html_document: string
    has_button: boolean
  }>({
    open: false,
    html_document: '',
    has_button: false,
  })

  useEffect(() => {
    if (!form.data.global_id) return
    client
      .get(`/registration/${form.data.global_id}/stripe_contract/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            stripe_contract: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/stripe_cgv/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            stripe_cgv: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    client
      .get(`/registration/${form.data.global_id}/cgu/`)
      .then((resp) => {
        setDocuments((prevState) => {
          return {
            ...prevState,
            cgu: resp.data as string,
          }
        })
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })

    return () => {
      setDocuments({})
    }
  }, [form.data.global_id, reload])

  const handleChange = (key: string, value: string | boolean | string[]) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  const signContract = () => {
    client
      .post(`/registration/${form.data.global_id}/sign_stripe_contract/`)
      .then(() => {
        setModal({
          open: false,
          html_document: '',
          has_button: false,
        })
        handleChange('stripe_contract_signed_at', new Date().toISOString())
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  return (
    <Grid>
      <ModalContract modal={modal} setModal={setModal} isSigningUp={signContract} />
      <Grid
        container
        direction="column"
        sx={{
          width: '100%',
          mt: 2,
        }}
      >
        <Grid>
          <Typography variant="h5" fontWeight="500">
            Votre échéancier
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Paiement en {form.data.installment_schedule?.installments_count} fois
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ p: 2 }}>#</TableCell>
                  <TableCell sx={{ p: 2 }} align="left">
                    Date
                  </TableCell>
                  <TableCell sx={{ p: 2 }} align="left">
                    Montant
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form?.data?.installment_schedule?.installments.map((installment, index) => (
                  <TableRow
                    key={installment.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, paddingY: 2 }}
                  >
                    <TableCell component="th" scope="row" sx={{ p: 2 }}>
                      {index + 1}
                    </TableCell>
                    <TableCell align="left" sx={{ p: 2 }}>
                      {new Date(installment.due_date).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </TableCell>
                    <TableCell align="left" sx={{ p: 2 }}>
                      {formatter.format(installment.payment_amount)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2} align="right" sx={{ fontWeight: 'bold', paddingY: 2 }}>
                    Total:
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: 'bold', paddingY: 2 }}>
                    {formatter.format(parseInt(form?.data?.installment_schedule?.total_amount ?? ''))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {documents.stripe_cgv && documents.cgu && (
          <Grid
            container
            flexWrap="nowrap"
            sx={{
              p: 2,
              background: form.data.stripe_cgu_cgv ? `${theme.palette.success.main}1A` : 'transparent',
              border: form.data.stripe_cgu_cgv
                ? `1px solid ${theme.palette.success.main}1A`
                : '1px solid rgba(0,0,0,0.12)',
              borderRadius: '4px',
              mt: 4,

              flexDirection: {
                md: 'row',
                xs: 'column',
              },
            }}
          >
            <Grid container direction="column">
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                }}
              >
                Acceptez les{' '}
                <Link
                  onClick={() => {
                    setModal({
                      open: true,
                      html_document: documents.stripe_cgv,
                      has_button: false,
                    })
                  }}
                >
                  Conditions Générales de Vente
                </Link>{' '}
                et les{' '}
                <Link
                  onClick={() => {
                    setModal({
                      open: true,
                      html_document: documents.cgu,
                      has_button: false,
                    })
                  }}
                >
                  Conditions Générales d'Utilisation
                </Link>
              </Typography>
            </Grid>

            <Divider
              sx={{
                width: {
                  md: 0,
                  xs: '100%',
                },

                height: {
                  md: 0,
                  xs: '1px',
                },

                my: {
                  md: 0,
                  xs: 2,
                },
              }}
            />
            <Grid item container justifyContent="end">
              <FormControlLabel
                required
                value={form.data.stripe_cgu_cgv}
                control={
                  <Switch
                    color="success"
                    checked={form.data.stripe_cgu_cgv}
                    onChange={(e) => handleChange('stripe_cgu_cgv', e.target.checked)}
                  />
                }
                sx={{
                  fontWeight: form.data.stripe_cgu_cgv ? 500 : 400,
                }}
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: form.data.stripe_cgu_cgv ? 500 : 400,
                    }}
                  >
                    Lu et approuvé
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        )}

        <Grid
          sx={{
            width: '100%',
            height: '300px',
            mt: 2,
            py: 6,
            px: 10,
            borderRadius: '4px 4px 0 0',
            background: 'rgba(0,0,0,0.54)',

            overflow: 'hidden',
            position: 'relative',

            cursor: 'pointer',
          }}
          onClick={() => {
            setModal({
              open: true,
              html_document: documents.stripe_contract || '',
              has_button: true,
            })
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.54)',
              position: 'absolute',
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                width: 60,
                height: 60,
                borderRadius: '4px',
                background: 'white',
              }}
            >
              <MdOutlineZoomIn size="30px" />
            </Grid>
          </Grid>
          <Grid
            sx={{
              background: 'white',
              borderRadius: '4px',
              py: 2,
              px: 4,
            }}
            dangerouslySetInnerHTML={{
              __html: documents.stripe_contract?.replace(/margin/g, ''),
            }}
          />
        </Grid>
        <Grid
          container
          flexWrap="nowrap"
          sx={{
            p: 2,
            background: form.data.stripe_contract_signed_at ? `${theme.palette.success.main}1A` : 'transparent',
            border: form.data.stripe_contract_signed_at
              ? `1px solid ${theme.palette.success.main}1A`
              : '1px solid rgba(0,0,0,0.12)',
            borderRadius: '0 0 4px 4px',
            cursor: 'pointer !important',

            flexDirection: {
              md: 'row',
              xs: 'column',
            },
          }}
          onClick={() =>
            setModal({
              open: true,
              html_document: documents.stripe_contract,
              has_button: true,
            })
          }
        >
          <Grid container direction="column">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
              }}
            >
              Signez votre contrat de formation
            </Typography>
            <Typography variant="caption">Je lis et signe le contrat de formation</Typography>
          </Grid>

          <Divider
            sx={{
              width: {
                md: 0,
                xs: '100%',
              },

              height: {
                md: 0,
                xs: '1px',
              },

              my: {
                md: 0,
                xs: 2,
              },
            }}
          />
          <Grid item container justifyContent="end">
            <FormControlLabel
              required
              control={<Switch color="success" disabled checked={Boolean(form.data.stripe_contract_signed_at)} />}
              sx={{
                fontWeight: form.data.stripe_contract_signed_at ? 500 : 400,
              }}
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: form.data.stripe_contract_signed_at ? 500 : 400,
                  }}
                >
                  Lu et approuvé
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
