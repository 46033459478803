import { secondsToHMS } from '../utils/misc'
import { Field } from '.'
import { InscriptionType, ExternalInscriptionType } from '../utils/sharedTypes'
import { Grid, Theme } from '@mui/material'

const DPCFields = ({ registration }: { registration: InscriptionType | ExternalInscriptionType; theme?: Theme }) => {
  return (
    <Grid>
      <Field label="Mode de financement" value={registration.funding_organism?.code} />
      <Field
        label="Référence de la session"
        value={
          registration?.sf_case && registration.sf_case.dpc_ref_action && registration.sf_case.dpc_ref_session
            ? `${registration.sf_case.dpc_ref_action}-${registration.sf_case.dpc_ref_session}`
            : ''
        }
      />
      <Field type="currency" label="Part organisme" value={registration?.sf_case?.organism_part} />
      <Field type="currency" label="Montant facturé" value={registration?.sf_case?.invoiced_amount} />
      <Field type="date" label="Début de la session" value={registration?.sf_case?.starting_date} />
      <Field type="date" label="Fin de la session" value={registration?.sf_case?.ending_date} />
      <Field
        label="Temps de connexion réalisé"
        value={secondsToHMS(registration?.sf_case?.walter_session_time || 0)}
        tooltip="Mis à jour toutes les heures"
      />
      <Field
        label="Taux de réalisation"
        value={
          registration?.sf_case
            ? registration?.sf_case?.realized_rate
              ? `${Math.round(registration?.sf_case?.realized_rate * 100)}%`
              : '0%'
            : '-'
        }
        tooltip="Taux calculé par rapport à la part organisme"
      />
    </Grid>
  )
}

export default DPCFields
