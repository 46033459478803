import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  MenuItem,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Formtitle, Layout } from '../../components'
import { EnterpriseFormData, EnterpriseFormType, ApprenticeshipAPIResponse } from '../../utils/sharedTypes'
import { useNavigate } from 'react-router-dom'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { DateField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { isEnterpriseStepValid, getRemuneration, days } from '../../utils/misc'
import { useParams } from 'react-router-dom'

type ContractPropsType = {
  form: EnterpriseFormType
  setForm: React.Dispatch<React.SetStateAction<EnterpriseFormType>>
}

export default function Contract({ form, setForm }: ContractPropsType) {
  const navigate = useNavigate()
  const { global_id } = useParams<{ global_id: string }>()
  const [apprenticeData, setApprenticeData] = useState<ApprenticeshipAPIResponse>()

  useEffect(() => {
    const searchObj = window.location.search ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}
    if ((searchObj as { global_id: string }).global_id) {
      client
        .get(`/apprenticeship/public/${(searchObj as { global_id: string }).global_id}/`)
        .then((resp) => {
          const data = resp.data as ApprenticeshipAPIResponse
          setApprenticeData(data)
        })
        .catch((err: Error) => {
          console.error(err)
          Bugsnag.notify(err)
        })
    }
  }, [global_id])

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/enterprise/${form.data.global_id}/submit/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../confirmation/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  useEffect(() => {
    if (apprenticeData && form.data) {
      console.log('form.data.cerfa_weekly_work_duration', form.data.cerfa_weekly_work_duration)
      const rems = getRemuneration(
        apprenticeData.birth_date,
        apprenticeData.former_weekly_work_duration,
        apprenticeData.former_salary,
        form.data.cerfa_contract_starting_date,
        form.data.cerfa_weekly_work_duration,
      )

      const firstMonth = rems?.find(
        (rem) => rem.year === 0 && rem.month === new Date(form.data.cerfa_contract_starting_date).getMonth(),
      )

      const secondYear = rems?.find(
        (rem) => rem.year === 1 && rem.month === new Date(form.data.cerfa_contract_starting_date).getMonth(),
      )

      const firstMonthPCT = firstMonth?.monthPCT
      const secondYearPCT = secondYear?.monthPCT
      if (firstMonthPCT && secondYearPCT) {
        setForm((prevState) => {
          return {
            ...prevState,
            data: {
              ...prevState.data,
              cerfa_remuneration_first_year_percentage: `${firstMonthPCT * 100}`,
              cerfa_remuneration_first_year_type: 'smic',
              cerfa_remuneration_second_year_percentage: `${secondYearPCT * 100}`,
              cerfa_remuneration_second_year_type: 'smic',
              cerfa_remuneration_first_year_gross_salary: `${firstMonth.monthRem}`,
            },
          }
        })
      }
    }
  }, [apprenticeData, form.data.cerfa_weekly_work_duration, form.data.cerfa_contract_starting_date])

  const handleChange = (key: keyof EnterpriseFormData, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'cerfa_weekly_work_duration':
        case 'cerfa_remuneration_first_year_gross_salary':
        case 'cerfa_supplementary_pension_fund':
          if (!value) {
            errors[key] = 'Ce champ ne peut pas être vide.'
          }
          break

        case 'cerfa_contract_starting_date':
        case 'cerfa_formation_starting_date':
        // case 'cerfa_contract_ending_date':
        //   if (!dayjs(value as string).isValid()) {
        //     errors[key] = 'Veuillez entrer une date valide.'
        //   }
        //   break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle title="Informations sur contrat" subtitle="Veuillez renseigner les informations sur le contrat" />
      <Grid item container spacing={2}>
        {form.data.apprenticeship_type === 'ContratPro' && (
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Nature du contrat</InputLabel>
              <Select
                fullWidth
                label="Nature du contrat"
                variant="outlined"
                id="cerfa_contract_nature"
                value={form.data.cerfa_contract_nature}
                onChange={(e) => {
                  handleChange('cerfa_contract_nature', e?.target.value)
                }}
              >
                <MenuItem value="cdi">CDI</MenuItem>
                <MenuItem value="cdd">CDD</MenuItem>
                <MenuItem value="temporary_work">Travail temporaire</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <FormControl fullWidth required>
            <InputLabel>Type de contrat</InputLabel>
            <Select
              fullWidth
              label="Type de contrat"
              variant="outlined"
              id="cerfa_contract_type"
              value={form.data.cerfa_contract_type}
              onChange={(e) => {
                handleChange('cerfa_contract_type', e?.target.value)
              }}
            >
              <ListSubheader>Contrat initial</ListSubheader>
              <MenuItem value="11">Premier contrat d'apprentissage de l'apprenti</MenuItem>
              <ListSubheader>Succession de contrats</ListSubheader>
              <MenuItem value="21">
                Nouveau contrat avec un apprenti qui a terminé son précédent contrat auprès d'un même employeur
              </MenuItem>
              <MenuItem value="22">
                Nouveau contrat avec un apprenti qui a terminé son précédent contrat auprès d'un autre employeur
              </MenuItem>
              <MenuItem value="23">Nouveau contrat avec un apprenti dont le précédent contrat a été rompu</MenuItem>
              <ListSubheader>Avenant : modification des conditions du contrat</ListSubheader>
              <MenuItem value="31">Modification de la situation juridique de l'employeur</MenuItem>
              <MenuItem value="32">Changement d'employeur dans le cadre d'un contrat saisonnier</MenuItem>
              <MenuItem value="33">Prolongation du contrat suite à un echec à l'examen de l'apprenti</MenuItem>
              <MenuItem value="34">
                Prolongation du contrat suite à la reconnaissance de l'apprenti comme travailleur handicapé
              </MenuItem>
              <MenuItem value="35">
                Diplôme supplémentaire préparé par l'apprenti dans le cadre de l'article L. 6222-22-1 du code du travail
              </MenuItem>
              <MenuItem value="36">
                Autres changements : changement de maître d'apprentissage, de durée de travail hebdomadaire, réduction
                de durée, etc.
              </MenuItem>
              <MenuItem value="37">Modification du lieu d'exécution du contrat</MenuItem>
              <MenuItem value="38">Modification du lieu principal de réalisation de la formation théorique</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {form.data.apprenticeship_type === 'ContratPro' && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Emploi occupé pendant le contrat"
                variant="outlined"
                id="cerfa_job_title"
                required
                value={form.data.cerfa_job_title}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_job_title', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_job_title)}
                helperText={form.errors.cerfa_job_title}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <TextField
                fullWidth
                label="Classification de l’emploi dans la collection collective"
                variant="outlined"
                id="cerfa_classification"
                required
                value={form.data.cerfa_classification}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_classification', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_classification)}
                helperText={form.errors.cerfa_classification}
              />
            </Grid> */}

            {/* <Grid item xs={6}>
              <TextField
                fullWidth
                label="Niveau"
                variant="outlined"
                id="cerfa_level"
                required
                value={form.data.cerfa_level}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_level', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_level)}
                helperText={form.errors.cerfa_level}
              />
            </Grid> */}

            {/* <Grid item xs={6}>
              <TextField
                fullWidth
                label="Coefficient hiérarchique"
                variant="outlined"
                id="cerfa_hierarchical_coefficient"
                required
                value={form.data.cerfa_hierarchical_coefficient}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_hierarchical_coefficient', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_hierarchical_coefficient)}
                helperText={form.errors.cerfa_hierarchical_coefficient}
              />
            </Grid> */}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Durée de la période d’essai (en jours)"
                variant="outlined"
                id="cerfa_trial_period_duration"
                required
                value={form.data.cerfa_trial_period_duration}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_trial_period_duration', e?.target.value)
                }}
                error={Boolean(form.errors.cerfa_trial_period_duration)}
                helperText={form.errors.cerfa_trial_period_duration}
              />
            </Grid>
          </>
        )}

        {/* <Grid item xs={12}>
          <DateField
            fullWidth
            id="cerfa_contract_starting_date"
            label="Date de début d'exécution du contrat"
            format="DD/MM/YYYY"
            disableFuture
            required
            value={form.data.cerfa_contract_starting_date ? dayjs(form.data.cerfa_contract_starting_date) : null}
            onChange={(value) => {
              //HACKY
              if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                handleChange('cerfa_contract_starting_date', dayjs(value).format('YYYY-MM-DD'))
              }
            }}
            slots={{
              textField: (params) => (
                <TextField
                  id="cerfa_contract_starting_date_textField"
                  {...params}
                  fullWidth
                  onBlur={() => {
                    if (!dayjs(form.data.cerfa_contract_starting_date).isValid()) {
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            cerfa_contract_starting_date:
                              "Veuillez entrer une date de début d'exécution du contrat valide",
                          },
                        }
                      })
                    }
                  }}
                  error={Boolean(form.errors.cerfa_contract_starting_date)}
                  helperText={form.errors.cerfa_contract_starting_date}
                />
              ),
            }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <DateField
            fullWidth
            id="cerfa_formation_starting_date"
            label="Date de début de formation pratique chez l'employeur"
            format="DD/MM/YYYY"
            disableFuture
            required
            value={form.data.cerfa_formation_starting_date ? dayjs(form.data.cerfa_formation_starting_date) : null}
            onChange={(value) => {
              //HACKY
              if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                handleChange('cerfa_formation_starting_date', dayjs(value).format('YYYY-MM-DD'))
              }
            }}
            slots={{
              textField: (params) => (
                <TextField
                  id="cerfa_formation_starting_date_textField"
                  {...params}
                  fullWidth
                  onBlur={() => {
                    if (!dayjs(form.data.cerfa_formation_starting_date).isValid()) {
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            cerfa_formation_starting_date: 'Veuillez entrer une date de début de formation valide',
                          },
                        }
                      })
                    }
                  }}
                  error={Boolean(form.errors.cerfa_formation_starting_date)}
                  helperText={form.errors.cerfa_formation_starting_date}
                />
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Durée hebdomadaire de travail"
            variant="outlined"
            id="cerfa_weekly_work_duration"
            required
            value={form.data.cerfa_weekly_work_duration}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_weekly_work_duration', e?.target.value.replace(/[^0-9]/g, ''))
            }}
            error={Boolean(form.errors.cerfa_weekly_work_duration)}
            helperText={form.errors.cerfa_weekly_work_duration}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Caisse de retraite complémentaire"
            variant="outlined"
            id="cerfa_supplementary_pension_fund"
            required
            value={form.data.cerfa_supplementary_pension_fund}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_supplementary_pension_fund', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_supplementary_pension_fund)}
            helperText={form.errors.cerfa_supplementary_pension_fund}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid item container>
            <Typography variant="body1" fontWeight="bold">
              Connaissez-vous déjà le jour de cours que vous souhaiteriez pour l'élève ?
            </Typography>

            <Grid container alignItems="center">
              <Checkbox
                id="is_born_abroad"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.cerfa_know_school_days === true}
                onClick={() => {
                  handleChange('cerfa_know_school_days', true)
                }}
              />

              <Typography>Oui</Typography>
            </Grid>
            <Grid container alignItems="center">
              <Checkbox
                id="is_born_abroad"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.cerfa_know_school_days === false}
                onClick={() => {
                  handleChange('cerfa_know_school_days', false)
                  handleChange('cerfa_school_days', '')
                }}
              />

              <Typography>Non</Typography>
            </Grid>
          </Grid>
        </Grid>

        {form.data.cerfa_know_school_days && (
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Jour de cours</InputLabel>
              <Select
                fullWidth
                multiple
                label="Jour de cours"
                variant="outlined"
                id="cerfa_contract_type"
                value={form.data.cerfa_school_days ? form.data.cerfa_school_days.split(',') : []}
                onChange={(e) => {
                  const value = e?.target.value as string[]
                  handleChange('cerfa_school_days', value.join(','))
                }}
                renderValue={(selected) =>
                  days
                    .filter((day) => selected.includes(day.value))
                    .map((day) => day.label)
                    .join(', ')
                }
              >
                {days.map((day) => (
                  <MenuItem key={day.value} value={day.value}>
                    <Checkbox
                      checked={
                        form.data.cerfa_school_days ? form.data.cerfa_school_days.split(',').includes(day.value) : false
                      }
                    />
                    <ListItemText primary={day.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {form.data.cerfa_contract_type && form.data.cerfa_contract_type !== '11' && (
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight="bold" sx={{ my: 2 }}>
              Avenant :
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type de dérogation</InputLabel>
                  <Select
                    fullWidth
                    label="Type de dérogation"
                    variant="outlined"
                    id="cerfa_derogation_type"
                    value={form.data.cerfa_derogation_type}
                    onChange={(e) => {
                      handleChange('cerfa_derogation_type', e?.target.value)
                    }}
                  >
                    <MenuItem value="">Pas de dérogation</MenuItem>
                    <MenuItem value="11">Age de l'apprenti inférieur à 16 ans</MenuItem>
                    <MenuItem value="12">
                      Age supérieur à 29 ans : cas spécifiques prévus dans le code du travail
                    </MenuItem>
                    <MenuItem value="21">Réduction de la durée du contrat ou de la période d'apprentissage</MenuItem>
                    <MenuItem value="22">Allongement de la durée du contrat ou de la période d'apprentissage</MenuItem>
                    <MenuItem value="50">Cumul de dérogations</MenuItem>
                    <MenuItem value="60">Autre dérogation</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Numéro du contrat sur lequel porte l'avenant"
                  variant="outlined"
                  id="cerfa_amendment_contract_number"
                  value={form.data.cerfa_amendment_contract_number}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('cerfa_amendment_contract_number', e?.target.value)
                  }}
                  error={Boolean(form.errors.cerfa_amendment_contract_number)}
                  helperText={form.errors.cerfa_amendment_contract_number}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateField
                  fullWidth
                  id="cerfa_amendment_date"
                  label="Date d'effet de l'avenant"
                  format="DD/MM/YYYY"
                  disableFuture
                  value={form.data.cerfa_amendment_date ? dayjs(form.data.cerfa_amendment_date) : null}
                  onChange={(value) => {
                    //HACKY
                    if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                      handleChange('cerfa_amendment_date', dayjs(value).format('YYYY-MM-DD'))
                    }
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        id="cerfa_amendment_date_textField"
                        {...params}
                        fullWidth
                        onBlur={() => {
                          if (form.data.cerfa_amendment_date && !dayjs(form.data.cerfa_amendment_date).isValid()) {
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                errors: {
                                  ...prevState.errors,
                                  cerfa_amendment_date: "Veuillez entrer une date d'effet d'avenant valide",
                                },
                              }
                            })
                          }
                        }}
                        error={Boolean(form.errors.cerfa_amendment_date)}
                        helperText={form.errors.cerfa_amendment_date}
                      />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" sx={{ my: 2 }}>
            Rémunération :
          </Typography>

          <Grid container gap={2}>
            <Card
              sx={{
                p: 2,
                width: '100%',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="body1" fontWeight="bold">
                    1ère année
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: 1,
                  paddingTop: 0,
                }}
              >
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={form.data.cerfa_remuneration_first_year_percentage}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('cerfa_remuneration_first_year_percentage', e?.target.value)
                  }}
                  error={Boolean(form.errors.cerfa_remuneration_first_year_percentage)}
                  helperText={form.errors.cerfa_remuneration_first_year_percentage}
                />
                <Typography variant="body1">du</Typography>
                <FormControl
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    variant="outlined"
                    id="cerfa_remuneration_first_year_type"
                    value={form.data.cerfa_remuneration_first_year_type}
                    onChange={(e) => handleChange('cerfa_remuneration_first_year_type', e?.target.value)}
                  >
                    <MenuItem value="smic">SMIC</MenuItem>
                    <MenuItem value="smc">SMC</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>

            <Card
              sx={{
                p: 2,
                width: '100%',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="body1" fontWeight="bold">
                    2ème année
                    <Typography variant="caption" sx={{ ml: 0.5 }}>
                      (si applicable)
                    </Typography>
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  gap: 1,
                  paddingTop: 0,
                }}
              >
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                  value={form.data.cerfa_remuneration_second_year_percentage}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('cerfa_remuneration_second_year_percentage', e?.target.value)
                  }}
                  error={Boolean(form.errors.cerfa_remuneration_second_year_percentage)}
                  helperText={form.errors.cerfa_remuneration_second_year_percentage}
                />
                <Typography variant="body1">du</Typography>
                <FormControl
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    variant="outlined"
                    id="cerfa_remuneration_second_year_type"
                    value={form.data.cerfa_remuneration_second_year_type}
                    onChange={(e) => handleChange('cerfa_remuneration_second_year_type', e?.target.value)}
                  >
                    <MenuItem value="smic">SMIC</MenuItem>
                    <MenuItem value="smc">SMC</MenuItem>
                  </Select>
                </FormControl>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Salaire brut mensuel à l'embauche"
            variant="outlined"
            id="cerfa_remuneration_first_year_gross_salary"
            required
            value={form.data.cerfa_remuneration_first_year_gross_salary}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('cerfa_remuneration_first_year_gross_salary', e?.target.value)
            }}
            error={Boolean(form.errors.cerfa_remuneration_first_year_gross_salary)}
            helperText={form.errors.cerfa_remuneration_first_year_gross_salary}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold" sx={{ my: 2 }}>
            Avantage en nature
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Nourriture"
                variant="outlined"
                id="cerfa_benefit_in_kind_food"
                value={form.data.cerfa_benefit_in_kind_food}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_benefit_in_kind_food', e?.target.value.replace(/[^0-9]/g, ''))
                }}
                error={Boolean(form.errors.cerfa_benefit_in_kind_food)}
                helperText={form.errors.cerfa_benefit_in_kind_food}
                InputProps={{
                  endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Logement"
                variant="outlined"
                id="cerfa_benefit_in_kind_accommodation"
                value={form.data.cerfa_benefit_in_kind_accommodation}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('cerfa_benefit_in_kind_accommodation', e?.target.value.replace(/[^0-9]/g, ''))
                }}
                error={Boolean(form.errors.cerfa_benefit_in_kind_accommodation)}
                helperText={form.errors.cerfa_benefit_in_kind_accommodation}
                InputProps={{
                  endAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate({ pathname: './../maitre-apprentissage/', search: location.search })
          }}
        >
          Précédent
        </Button>

        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isEnterpriseStepValid('contract', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}
