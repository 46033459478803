import { Button, Grid } from '@mui/material'
import { Layout, Formtitle } from '../components'
import DPCTuto from '../components/DPCTuto'
import { FormType } from '../utils/sharedTypes'
import { Dispatch, SetStateAction } from 'react'
import { MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

type DPCRegistrationPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const DPCRegistration = ({ form, setForm }: DPCRegistrationPropsType) => {
  const navigate = useNavigate()

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle title="Inscription DPC" />
      <DPCTuto form={form} setForm={setForm} />
      <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          endIcon={form.data.dpc_submitted && <MdNavigateNext />}
          onClick={() => {
            navigate({ pathname: './../recommandation', search: location.search })
          }}
        >
          Incription réalisée
        </Button>
      </Grid>
    </Layout>
  )
}

export default DPCRegistration
