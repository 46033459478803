import {
  Grid,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material'
import { MdOpenInNew } from 'react-icons/md'
import { ProductType } from 'src/utils/sharedTypes'

type ProductSummaryPropsType = {
  products: ProductType[]
}

const ProductSummary = ({ products }: ProductSummaryPropsType) => {
  return (
    <Grid>
      <Typography variant="h5" fontWeight="500" mb={1} mt={2}>
        Votre parcours de formation
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 2 }}>Nom</TableCell>
              <TableCell sx={{ p: 2 }} align="left">
                Description
              </TableCell>
              <TableCell sx={{ p: 2 }} align="left">
                Programme
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              return (
                <TableRow key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, paddingY: 2 }}>
                  <TableCell component="th" scope="row" sx={{ p: 2 }}>
                    {product.name}
                  </TableCell>
                  <TableCell align="left" sx={{ p: 2 }}>
                    {product.description}
                  </TableCell>
                  <TableCell align="left" sx={{ p: 2 }}>
                    <Link href={product.program_url} target="_blank" sx={{ display: 'flex', alignItems: 'center' }}>
                      Programme <MdOpenInNew style={{ marginLeft: '5px' }} />
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default ProductSummary
