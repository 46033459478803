import { useEffect, useState } from 'react'
import {
  Grid,
  CircularProgress,
  Paper,
  Typography,
  Divider,
  Button,
  Link,
  Snackbar,
  Alert,
  Breadcrumbs,
  useTheme,
} from '@mui/material'
import ProviderLayout from '../../components/external_provider/ProviderLayout'
import { client } from '../../utils/client'
import { useParams } from 'react-router-dom'
import Bugsnag from '@bugsnag/js'
import { ExternalInscriptionType, AlertType } from '../../utils/sharedTypes'
import { Field, FIFPLFields, DPCFields } from '../../components'
import StatusLabel from '../../components/external_provider/StatusLabel'
import CPFFields from '../../components/CPFFields'

const Registration = () => {
  const theme = useTheme()
  const { provider_id, registration_id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMagicLink, setIsLoadingMagicLink] = useState(false)
  const [alert, setAlert] = useState<AlertType>({
    severity: undefined,
    message: '',
  })
  const [registration, setRegistration] = useState<ExternalInscriptionType>()

  const getRegistration = () => {
    if (provider_id && registration_id) {
      client
        .get(`/registration/external/${provider_id}/${registration_id}/`)
        .then((resp) => {
          const data = resp.data as ExternalInscriptionType
          setRegistration(data)
          setIsLoading(false)
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (registration_id) {
      getRegistration()
    }
  }, [])

  const discard = (global_id: string) => {
    if (provider_id) {
      client
        .patch(`/registration/external/${provider_id}/${global_id}/discard/`)
        .then((resp) => {
          if (resp.status === 200) {
            setAlert({ severity: 'info', message: 'Inscription supprimée' })
          } else {
            setAlert({ severity: 'error', message: 'Erreur lors du refus' })
          }
        })
        .catch((err: Error) => {
          setAlert({ severity: 'error', message: 'Erreur lors du refus' })
          Bugsnag.notify(err)
          console.error(err)
        })
    }
  }

  const magic_link = (global_id: string) => {
    if (isLoadingMagicLink) return
    if (provider_id) {
      setIsLoadingMagicLink(true)
      client
        .post(`/registration/external/${provider_id}/${global_id}/send_magic_link/`)
        .then((resp) => {
          if (resp.status === 200) {
            setAlert({ severity: 'info', message: 'Magic link envoyé' })
          } else {
            setAlert({ severity: 'error', message: "Erreur lors de l'envoi du magic link" })
          }
        })
        .catch((err: Error) => {
          setAlert({ severity: 'error', message: "Erreur lors de l'envoi du magic link" })
          Bugsnag.notify(err)
          console.error(err)
        })
        .finally(() => {
          setIsLoadingMagicLink(false)
        })
    }
  }

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Grid>
    )
  }

  if (!registration) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100vh',
        }}
      >
        <Grid item xs={12}>
          Inscription inconnue
        </Grid>
      </Grid>
    )
  }

  const isThreeHoursLater = (date: string) => {
    const now = new Date()
    const ending = new Date(date)
    ending.setHours(ending.getHours() + 3)
    return now > ending
  }

  return (
    <ProviderLayout>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href={`/providers/${provider_id!}/`}>
            Mes inscriptions
          </Link>
          <Link underline="hover" color="primary" href={`/providers/${provider_id!}/${registration?.global_id}/`}>
            {registration?.first_name} {registration?.last_name}
            {registration?.product && ` (${registration.product.product_code!})`}
          </Link>
        </Breadcrumbs>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h5">
                    {registration.first_name} {registration.last_name}
                  </Typography>

                  {registration.sf_case?.created_at && isThreeHoursLater(registration.sf_case.created_at) && (
                    <Link
                      component={'button'}
                      onClick={(e) => {
                        if (registration_id) {
                          e.preventDefault()
                          e.stopPropagation()
                          magic_link(registration_id)
                        }
                      }}
                      sx={{
                        fontSize: 14,
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      Envoyer le magic link par mail
                    </Link>
                  )}
                  {registration.product && <Typography variant="h6">{registration.product.product_code!}</Typography>}
                  <Typography variant="caption">
                    créé le{' '}
                    {registration.created_at
                      ? new Date(registration.created_at).toLocaleDateString('fr', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  {registration && (
                    <Typography variant="h5">
                      <StatusLabel registration={registration} />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <Typography variant="h6">Client</Typography>
                <Field label="Prénom" value={registration?.first_name} />
                <Field label="Nom de famille" value={registration?.last_name} />
                <Field label="Email" value={registration?.email} />
                <Field label="Mobile" value={registration?.mobile_phone} />
                <Field label="Profession" value={registration?.profession?.name} />
                {registration && registration?.dpc_rpps && <Field label="RPPS" value={registration?.dpc_rpps} />}
                <Field
                  label="Dernière connexion"
                  value={
                    registration.sf_case?.contact?.last_login
                      ? new Date(registration.sf_case.contact.last_login).toLocaleDateString('fr', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      : '-'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Produit</Typography>
                <Field label="Nom" value={registration?.product?.name} />
                <Field label="Nom de code" value={registration?.product?.product_code} />
                <Field
                  label="Durée totale"
                  value={
                    registration.product_catalog?.duration
                      ? `${registration.product_catalog.duration}h`
                      : registration.product?.duration
                      ? `${registration.product?.duration}h`
                      : 'Non spécifiée'
                  }
                />
                <Field
                  label="Prix catalogue"
                  type="currency"
                  value={
                    registration.product_catalog
                      ? registration.product_catalog.listing_price
                      : registration.product?.listing_price
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Financement / Session</Typography>
                {registration && registration?.funding_organism?.code === 'FIFPL' && (
                  <FIFPLFields registration={registration} theme={theme} />
                )}
                {registration && registration?.funding_organism?.code === 'DPC' && (
                  <DPCFields registration={registration} theme={theme} />
                )}
                {registration && registration?.funding_organism?.code === 'CPF' && (
                  <CPFFields registration={registration} theme={theme} />
                )}
              </Grid>
            </Grid>

            <Grid item sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                disabled={registration.status !== 'Submitted'}
                color="error"
                onClick={(e) => {
                  if (registration_id) {
                    e.preventDefault()
                    e.stopPropagation()
                    discard(registration_id)
                  }
                }}
              >
                Supprimer
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={Boolean(alert.message)}
        autoHideDuration={6000}
        onClose={() => {
          setAlert({ severity: undefined, message: '' })
        }}
      >
        <Alert
          onClose={() => {
            setAlert({ severity: undefined, message: '' })
          }}
          severity={alert.severity || 'info'}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </ProviderLayout>
  )
}

export default Registration
