import { Button, Grid, Modal, Typography } from '@mui/material'
import { useState, useEffect } from 'react'
import { BsPlusLg } from 'react-icons/bs'

type Props = {
  modal: {
    open: boolean
    html_document: string
    has_button: boolean
  }
  setModal: ({ open, html_document, has_button }: { open: boolean; html_document: string; has_button: boolean }) => void
  isSigningUp?: () => void
}

const ModalContract = ({ modal, setModal, isSigningUp }: Props) => {
  const [hasReachedBottom, setHasReachedBottom] = useState<boolean>(false)

  useEffect(() => {
    if (hasReachedBottom) {
      setHasReachedBottom(false)
    }
  }, [modal])

  return (
    <Modal
      open={modal.open}
      onClose={() =>
        setModal({
          open: false,
          html_document: '',
          has_button: false,
        })
      }
      sx={{
        display: 'flex',
        mt: 10,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Grid
          item
          container
          md={10}
          xs={12}
          sx={{
            position: 'relative',
            mx: 'auto',
            top: 0,
            right: {
              md: -15,
              xs: 0,
            },
            justifyContent: 'end',
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 40,
              height: 40,
              position: 'absolute',
              background: 'white',
              borderRadius: '50%',

              top: -15,
              transform: 'rotate(45deg)',

              cursor: 'pointer',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            }}
            onClick={() => {
              setModal({
                open: false,
                html_document: '',
                has_button: false,
              })
            }}
          >
            <BsPlusLg />
          </Grid>
        </Grid>
        <Grid
          item
          md={10}
          xs={12}
          sx={{
            background: 'white',
            borderRadius: '4px 4px 0 0',
            pt: 10,
            pb: 12,
            px: {
              md: 10,
              sm: 5,
              xs: 3,
            },
            mx: {
              md: 'auto',
              xs: 2,
            },
            height: '100%',
            overflowY: 'auto',
          }}
          onScroll={(e) => {
            const bottom =
              Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) <= e.currentTarget.clientHeight + 100
            hasReachedBottom !== bottom && setHasReachedBottom(bottom)
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: modal.html_document.replace(/margin/g, ''),
            }}
          />
        </Grid>

        {modal.has_button && (
          <Grid
            container
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              background: 'white',
              px: {
                md: 15,
                sm: 2,
              },
              py: 3.6,
              position: 'absolute',
              bottom: 0,
              boxShadow: '0px -0px 4px rgba(0, 0, 0, 0.25)',

              justifyContent: {
                md: 'space-between',
                xs: 'center',
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                width: '50%',

                display: {
                  md: 'block',
                  xs: 'none',
                },
              }}
            >
              Vous devez parcourir le document ci-contre jusqu’à la fin afin de pouvoir déverouiller la signature de
              votre contrat de formation. Une fois à la fin vous pourrez cliquer sur « Signer mon contrat de formation
              situé à votre droite »
            </Typography>

            <Button variant="contained" size="large" disabled={!hasReachedBottom} onClick={isSigningUp}>
              Signer mon contrat de formation
            </Button>
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}

export default ModalContract
