import { CallForm } from '.'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FormType } from '../utils/sharedTypes'
import { FormDefaultState } from '../utils/constants'
import { Button, Grid, Typography } from '@mui/material'

type IOtherPropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const Other = ({ form, setForm }: IOtherPropsType) => {
  const [validated, setValidated] = useState(false)
  const [state, setState] = useState<'Prendre rendez-vous' | 'Être rappelé'>('Prendre rendez-vous')

  useEffect(() => {
    setForm((prevState) => {
      return {
        ...prevState,
        steps: prevState.steps.filter((step) => step.url !== 'recapitulatif'),
      }
    })

    return () => {
      setForm((prevState) => {
        return {
          ...prevState,
          steps: FormDefaultState.steps,
        }
      })
    }
  }, [])

  return (
    <>
      <Typography variant="body1" fontWeight="500">
        Un échange avec un conseiller est indispensable pour que nous puissions vous proposer un financement
        personnalisé (CPF, Pôle Emploi, OPCO, financement personnel échelonné, etc.)
      </Typography>
      <Grid item container spacing={1} sx={{ justifyContent: 'flex-start', mt: 2, mb: 4 }}>
        {['Prendre rendez-vous', 'Être rappelé'].map((idn) => {
          return (
            <Grid item key={idn}>
              <Button
                variant={state === idn ? 'contained' : 'outlined'}
                fullWidth
                onClick={() => {
                  setState(idn as 'Prendre rendez-vous' | 'Être rappelé')
                }}
                sx={{
                  height: 56,
                }}
              >
                {idn}
              </Button>
            </Grid>
          )
        })}
      </Grid>

      <CallForm
        form={form}
        setForm={setForm}
        validated={validated}
        setValidated={setValidated}
        instantCall={state === 'Être rappelé'}
      />
    </>
  )
}

export default Other
