import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { clientWithCredentials } from '../../utils/client'
import { RefreshTokenRespDataType } from '../../utils/sharedTypes'
import Bugsnag from '@bugsnag/js'
import { logOut } from '../../utils/client'

const PrivateRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    clientWithCredentials
      .post('auth/refresh/')
      .then((resp) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (resp.status === 200) {
          const data = resp.data as RefreshTokenRespDataType
          localStorage.setItem('access', data.access)
          localStorage.setItem('refresh', data.refresh)
          localStorage.setItem('isLoggedIn', 'true')
          navigate(location.pathname)
        } else {
          logOut()
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error('err', err)
        logOut()
      })
  }, [])

  return localStorage.getItem('isLoggedIn') ? children : <Navigate to="/login" />
}

export default PrivateRoute
