import { useAutoAnimate } from '@formkit/auto-animate/react'
import { Divider, Grid, Typography, useTheme, Button, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { ReactComponent as Retour } from '../assets/Retour.svg'
import { ReactComponent as WalterLearning } from '../assets/WalterLearning.svg'
import { ReactComponent as WalterSanteColor } from '../assets/WalterSanteColor.svg'
import { CallUs, Formtitle } from '../components'
import { client } from '../utils/client'
import { learningPhoneNumber, santePhoneNumber } from '../utils/constants'
import { useStripe } from '@stripe/react-stripe-js'
import { BiError, BiTimer } from 'react-icons/bi'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { StripePublicKey } from '../utils/constants'
import Bugsnag from '@bugsnag/js'

const StripeStatus = ({ clientSecret, magicLink }: { clientSecret: string; magicLink: string }) => {
  const stripe = useStripe()
  const [message, setMessage] = useState<string>('Enregistrement de vos coordonnées bancaires en cours...')
  const [status, setStatus] = useState<'processing' | 'succeeded' | 'requires_payment_method'>('processing')
  const theme = useTheme()

  useEffect(() => {
    if (!stripe) {
      return
    }

    // Retrieve the SetupIntent
    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({ setupIntent }) => {
        switch (setupIntent?.status) {
          case 'succeeded':
            setMessage('Vos données de paiement ont bien été enregistrées')
            setStatus('succeeded')
            break
          case 'processing':
            setMessage('Enregistrement de vos coordonnées bancaires en cours...')
            setStatus('processing')
            break
          case 'requires_payment_method':
            setMessage(
              "Un problème est survenu lors de l'enregistrement de vos coordonnées bancaires. Veuillez essayer un autre mode de paiement",
            )
            setStatus('requires_payment_method')
            break
        }
      })
      .catch((error: Error) => {
        Bugsnag.notify(error)
      })
  })

  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          sx={{
            background:
              status === 'processing'
                ? theme.palette.grey[500]
                : status === 'succeeded'
                ? theme.palette.success.main
                : theme.palette.error.main,
            height: 80,
            width: 80,
            borderRadius: 40,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {status === 'processing' ? (
            <BiTimer color="inherit" size="40" />
          ) : status === 'succeeded' ? (
            <MdCheck size="40" style={{ color: 'white' }} />
          ) : (
            <BiError size="40" style={{ color: 'white' }} />
          )}
        </Grid>
      </Grid>
      <Formtitle
        title={message}
        //   subtitle={'Vos données de paiement ont bien été enregistrées'}
        align="center"
      />
      <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="contained" href={magicLink} target="_blank" disabled={magicLink === ''}>
          {magicLink === '' ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CircularProgress color="inherit" size={20} sx={{ marginRight: '5px' }} /> Session en cours de création
            </div>
          ) : (
            'Accèder à ma formation'
          )}
        </Button>
      </Grid>
    </>
  )
}

const Stripe = () => {
  const searchParams = new URLSearchParams(location.search)
  const stripePromise = loadStripe(StripePublicKey)
  const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret') ?? ''
  const params = Object.fromEntries(searchParams.entries())

  const [magicLink, setMagicLink] = useState('')
  const [animateRef] = useAutoAnimate<HTMLDivElement>()

  const options = {
    clientSecret: clientSecret,
    appearance: {},
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      client
        .get(`/registration/${params.global_id}/magic_link/`)
        .then((resp) => {
          const data = resp.data as { magic_link: string | null }
          if (data.magic_link === null) {
            setMagicLink('')
          } else if (data.magic_link !== 'pending') {
            setMagicLink(data.magic_link)
            clearInterval(interval)
          }
        })
        .catch((err: Error) => {
          Bugsnag.notify(err)
          console.error(err)
        })
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh', p: 2 }} ref={animateRef}>
      <Grid item xs={12} md={6} sx={{ display: 'block', mt: { xs: 15, md: 0 } }}>
        <Elements stripe={stripePromise} options={options}>
          <StripeStatus clientSecret={clientSecret} magicLink={magicLink} />
        </Elements>
      </Grid>

      <Grid
        item
        container
        sx={{
          cursor: 'pointer',
          position: 'absolute',
          top: 0,
          left: 0,
          pt: 2,
          pl: { xs: 2, md: 4 },
          display: 'flex',
        }}
      >
        <Grid item container sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid item>
            {location.pathname.includes('/sante') ? (
              <WalterSanteColor
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com/sante', '_self')
                }}
              />
            ) : (
              <WalterLearning
                height="29px"
                onClick={() => {
                  window.open('https://walter-learning.com', '_self')
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Divider orientation="vertical" sx={{ height: 40, mx: 2 }} />
          </Grid>
          <Grid
            item
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              location.pathname.includes('/sante')
                ? window.open('https://walter-learning.com/sante', '_self')
                : window.open('https://walter-learning.com', '_self')
            }}
          >
            <Retour />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Retour au site
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          mt: 2,
          mr: 4,
          display: { xs: 'none', md: 'block' },
        }}
      >
        <CallUs phone={window.location.href.includes('sante') ? santePhoneNumber : learningPhoneNumber} />
      </Grid>
    </Grid>
  )
}

export default Stripe
