import { FormType } from '../../utils/sharedTypes'
import CpfStatusDontKnow from './CpfStatusDontKnow'
import CpfStatusNo from './CpfStatusNo'
import CpfStatusYes from './CpfStatusYes'

type CpfStatusPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const CpfStatus = ({ form, setForm }: CpfStatusPropsType) => {
  switch (form.data.cpf_idn_status) {
    case 'Oui':
      return <CpfStatusYes form={form} setForm={setForm} />
    case 'Non':
      return <CpfStatusNo form={form} setForm={setForm} />
    case 'Je ne sais pas':
      return <CpfStatusDontKnow form={form} setForm={setForm} />
    default:
      return <></>
  }
}

export default CpfStatus
