//MATERIAL UI
import { Grid, Button, Typography } from '@mui/material'
import config from '../utils/config'

const Login = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Grid item sx={{ mb: 2 }}>
        <Typography variant="h4">{config.APP_NAME}</Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => {
            window.open(config.SSO_URL[import.meta.env.VITE_ENV as keyof typeof config.SSO_URL], '_self')
          }}
        >
          Se connecter
        </Button>
      </Grid>
    </Grid>
  )
}

export default Login
