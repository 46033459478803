const Error = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '0px',
        padding: '0px',
      }}
    >
      <h3 style={{ textAlign: 'center', fontFamily: 'Work-Sans' }}>Quelque chose ne va pas !</h3>
      <p style={{ textAlign: 'center', fontFamily: 'Work-Sans' }}>
        Nous ne pouvons pas répondre à votre demande. Nous avons bien été informés de votre problème. Nous vous
        suggérons de rafraîchir votre navigateur.
        <br />
        Si l’erreur persiste, veuillez contacter nos équipes par email à{' '}
        <a href="mailto:help@walter-learning.com">help@walter-learning.com</a> ou par téléphone au{' '}
        <a href="tel:+33755503532" className="footer-link text-font-size">
          07&#8239;55&#8239;50&#8239;35&#8239;32
        </a>
      </p>
      <a href="/">
        <div>Retour</div>
      </a>
    </div>
  )
}

export default Error
