import { Divider, Grid, Typography, Link, useTheme } from '@mui/material'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormType } from '../utils/sharedTypes'
import { IoWarningOutline } from 'react-icons/io5'
import { HiDuplicate } from 'react-icons/hi'
import { BsCheck } from 'react-icons/bs'

type Props = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
  dpc_action_number?: string
  header?: boolean
}

const DPCTuto = ({ form, dpc_action_number = '', header = true }: Props) => {
  const [copyLink, setCopyLink] = useState(false)
  const theme = useTheme()

  return (
    <Grid
      container
      direction="column"
      sx={{
        scrollMarginTop: '40px',
      }}
    >
      {header && (
        <>
          <Grid
            container
            sx={{ background: theme.palette.warning.light, p: 2, borderRadius: '4px', mb: 4 }}
            wrap="nowrap"
          >
            <Grid item sx={{ mr: 1 }}>
              <IoWarningOutline style={{ color: theme.palette.warning.main }} size="24" />
            </Grid>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body1" sx={{ pb: 1, color: theme.palette.warning.dark, fontWeight: 'bold' }}>
                  Attention
                </Typography>
                <Typography variant="body2" sx={{ color: theme.palette.warning.dark }}>
                  Vous devez effectuer les démarches suivantes sur{' '}
                  <Link href="https://www.agencedpc.fr/" target="_blank">
                    agencedpc.fr
                  </Link>{' '}
                  pour valider votre inscription
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Typography variant="body1" fontWeight="bold">
            Comment valider votre inscription à la formation en 4 étapes clés ?
          </Typography>
        </>
      )}
      <Grid container direction="column" sx={{ mt: 4 }}>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="bold">
              1
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="bold">
              Rendez-vous sur{' '}
              <Link href={'https://www.agencedpc.fr/professionnel/'} target="_blank">
                www.agencedpc.fr
              </Link>
            </Typography>
            <Typography variant="caption">Connectez-vous sur votre compte DPC</Typography>
            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="bold">
              2
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="bold">
              Retrouvez la formation souhaitée
            </Typography>
            <Typography variant="caption">
              Cliquez sur <b>« Recherche action »</b> dans le menu gauche
            </Typography>

            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="bold">
              3
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="bold">
              Copiez la référence de l’action de formation
            </Typography>
            <Typography variant="caption">
              Cliquez sur « <b>Recherche</b> » puis « <b>Détail action de DPC</b> »
            </Typography>

            <Grid container direction="row" wrap="nowrap" mt={2} alignItems="center">
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: theme.palette.primary.light,
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '4px',
                  height: 60,
                  width: {
                    md: 340,
                    xs: '100%',
                  },
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  setCopyLink(true)
                  await navigator.clipboard.writeText(form.data.product?.dpc_action_number ?? dpc_action_number)
                }}
              >
                {form.data.product?.dpc_action_number ?? dpc_action_number}
              </Grid>
              <Link
                ml={2}
                variant="body2"
                onClick={async () => {
                  setCopyLink(true)
                  await navigator.clipboard.writeText(form.data.product?.dpc_action_number ?? dpc_action_number)
                }}
                sx={{
                  display: {
                    md: 'block',
                    xs: 'none',
                  },
                }}
              >
                {copyLink ? (
                  <BsCheck
                    style={{
                      position: 'relative',
                      top: 5,
                      width: 18,
                      height: 18,
                    }}
                  />
                ) : (
                  <HiDuplicate
                    style={{
                      position: 'relative',
                      top: 3,
                      width: 18,
                      height: 18,
                    }}
                  />
                )}
                {copyLink ? 'Copiée avec succès' : 'Copier la référence'}
              </Link>
            </Grid>

            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="bold">
              4
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="bold">
              Choisissez votre session
            </Typography>
            <Typography variant="caption">
              Cliquez sur « <b>S'inscrire</b> » sur la session de votre choix, puis « <b>Valider</b> »
            </Typography>
            <Divider sx={{ my: 2.5 }} />
          </Grid>
        </Grid>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '40px',
              height: '40px',
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '4px',
              background: theme.palette.primary.light,
            }}
            mr={2.5}
          >
            <Typography variant="body1" fontWeight="bold">
              5
            </Typography>
          </Grid>

          <Grid container direction="column">
            <Typography variant="body1" fontWeight="bold">
              Valider votre pré-inscription
            </Typography>
            <Typography variant="caption">
              De retour sur cette page, cliquez sur « <b>Rafraichir</b> » en haut à droite
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2.5 }} />
        <Grid sx={{ mt: 2 }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              mb: 1,
            }}
          >
            Besoin d'aide ? Consultez nos tutoriels ci-dessous
          </Typography>
          <Typography variant="body2">
            Pour vous guider pas à pas dans votre inscription,{' '}
            <Link href="https://info.walter-learning.com/guide/inscription-action-dpc/" target="_blank">
              consultez notre guide en ligne
            </Link>{' '}
            ou visionnez la video ci-dessous
          </Typography>
        </Grid>
        <Grid item className="container" sx={{ mt: 2.5 }}>
          <iframe
            style={{
              borderRadius: '4px',
            }}
            height="100%"
            width="100%"
            src="https://iframe.mediadelivery.net/embed/252149/fb177ab4-3564-40b1-bccf-68017f59872c"
            frameBorder="0"
          />
        </Grid>
        <Divider sx={{ my: 3 }} />
      </Grid>
    </Grid>
  )
}

export default DPCTuto
