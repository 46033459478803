import React from 'react'
import { ApprenticeshipFormData, ApprenticeshipFormType } from '../../utils/sharedTypes'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { DropZone, Formtitle, Layout } from '../../components'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { isApprenticeshipStepValid } from '../../utils/misc'
import Bugsnag from '@bugsnag/js'
import { client } from '../../utils/client'

type ProfilePropsType = {
  form: ApprenticeshipFormType
  setForm: React.Dispatch<React.SetStateAction<ApprenticeshipFormType>>
}

const Profile = ({ form, setForm }: ProfilePropsType) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/public/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../situation/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      if (key.includes('.')) {
        const keys = key.split('.')
        const firstKey = keys.shift() as keyof ApprenticeshipFormData

        let nested = prevState.data[firstKey] as unknown as Record<string, string | boolean | Record<string, string>>
        keys.forEach((k, i) => {
          if (i === keys.length - 1) {
            nested[k] = value
          } else {
            nested = nested[k] as Record<string, string | boolean | Record<string, string>>
          }
        })
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [firstKey]: nested,
          },
        }
      } else {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [key]: value,
          },
        }
      }
    })
  }

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Votre profil"
        subtitle="Remplissez le formulaire ci-dessous pour poursuivre votre inscription"
      />

      <Grid item xs={12} sx={{ width: '100%', mt: 1 }}>
        <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
          Déposez votre lettre de motivation{' '}
          <Typography variant="caption">(si vous en avez une - ce n’est pas obligatoire)</Typography>
        </Typography>
        <DropZone
          required
          maxFiles={1}
          maxSize={10}
          sx={{ minHeight: 100, background: theme.palette.background.default }}
          accept={{
            'image/*': ['.png', '.jpeg'],
            'application/pdf': ['.pdf'],
          }}
          bucketName="walter-inscription"
          folderPath={form.data.global_id}
          onUploaded={(url: string) => {
            handleChange('cover_letter', url)
          }}
        />
      </Grid>

      <Grid item xs={12} sx={{ width: '100%', mt: 2 }}>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            mb: 1,
          }}
        >
          Déposez votre CV
        </Typography>
        <DropZone
          required
          maxFiles={1}
          maxSize={10}
          sx={{ minHeight: 100, background: theme.palette.background.default }}
          accept={{
            'image/*': ['.png', '.jpeg'],
            'application/pdf': ['.pdf'],
          }}
          bucketName="walter-inscription"
          folderPath={form.data.global_id}
          onUploaded={(url: string) => {
            handleChange('curriculum_vitae', url)
          }}
        />
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            if (form.data.has_apprenticeship_company) {
              navigate({ pathname: './../entreprise/', search: location.search })
            } else {
              navigate({ pathname: './../information/', search: location.search })
            }
          }}
        >
          Précédent
        </Button>

        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isApprenticeshipStepValid('profile', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}

export default Profile
