import { Dispatch, SetStateAction } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { FormType } from '../utils/sharedTypes'

type StartingDatePropsType = {
  form: FormType
  setForm: Dispatch<SetStateAction<FormType>>
}

const StartingDate = ({ form, setForm }: StartingDatePropsType) => {
  const handleChange = (key: string, value: string | boolean | string[] | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]
      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  if (form.sessions.length === 0) {
    return <Grid item> Pas de session disponible</Grid>
  }

  return form.data.registration_type == 'PreConfiguredRegistration' ? (
    <Grid item container spacing={2} direction="column">
      <Grid item container spacing={1}>
        {form.sessions
          .filter((s) => s.global_id == form.data.session?.global_id)
          .map((session, index) => {
            return (
              <Grid item key={session.global_id} xs={12} md={6}>
                <Button
                  id={`startLater_${index}`}
                  fullWidth
                  sx={{ height: 56 }}
                  variant={form.data.session?.global_id === session.global_id ? 'contained' : 'outlined'}
                  onClick={() => {
                    handleChange('session', { global_id: session.global_id })
                  }}
                >
                  <Grid container direction="column">
                    <Typography variant="body1" sx={{ color: 'inherit' }}>
                      {index === 0 && new Date(session.starting_date) <= new Date()
                        ? 'Commencer tout de suite'
                        : new Date(session.starting_date).toLocaleDateString('fr', { day: '2-digit', month: 'long' })}
                    </Typography>
                    <Typography variant="body2" sx={{ textTransform: 'lowercase' }}>
                      (Fin le{' '}
                      {session.display_ending_date
                        ? new Date(session.display_ending_date).toLocaleDateString('fr', {
                            day: '2-digit',
                            month: 'long',
                          })
                        : new Date(session.ending_date).toLocaleDateString('fr', {
                            day: '2-digit',
                            month: 'long',
                          })}
                      )
                    </Typography>
                  </Grid>
                </Button>
              </Grid>
            )
          })}
      </Grid>
    </Grid>
  ) : (
    <Grid item container spacing={2} direction="column">
      <Grid item container spacing={1}>
        {form.sessions.slice(0, 4).map((session, index) => {
          return (
            <Grid item key={session.global_id} xs={12} md={6}>
              <Button
                id={`startLater_${index}`}
                fullWidth
                sx={{ height: 56 }}
                variant={form.data.session?.global_id === session.global_id ? 'contained' : 'outlined'}
                onClick={() => {
                  handleChange('session', { global_id: session.global_id })
                }}
              >
                <Grid container direction="column">
                  <Typography variant="body1" sx={{ color: 'inherit' }}>
                    {index === 0 && new Date(session.starting_date) <= new Date()
                      ? 'Commencer tout de suite'
                      : new Date(session.starting_date).toLocaleDateString('fr', { day: '2-digit', month: 'long' })}
                  </Typography>
                  <Typography variant="body2" sx={{ textTransform: 'lowercase' }}>
                    (Fin le{' '}
                    {session.display_ending_date
                      ? new Date(session.display_ending_date).toLocaleDateString('fr', {
                          day: '2-digit',
                          month: 'long',
                        })
                      : new Date(session.ending_date).toLocaleDateString('fr', {
                          day: '2-digit',
                          month: 'long',
                        })}
                    )
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default StartingDate
