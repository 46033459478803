import { Grid, Typography } from '@mui/material'
import { FormType } from '../utils/sharedTypes'
import { funders } from '../utils/constants'

type Props = {
  form: FormType
}

export default function FundingSummaryFIFPL({ form }: Props) {
  const hasProducts = form.data.products.length > 0 && form.data.product === null
  const listing_price = hasProducts
    ? form.data.funding_organism_registrations.reduce((acc, r) => acc + r.product_listing_price, 0)
    : form.data.product
    ? form.data.product.listing_price
    : 0

  return form.data.personal_funding ? (
    <Grid item>
      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="body2">Prix de la formation</Typography>
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(
            (form.data.product_catalog?.funding_price ?? form.data.product_catalog?.listing_price ?? listing_price) +
              form.data.personal_funding,
          ) || '-'}
        </Typography>
      </Grid>

      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="body2">
            Montant pris en charge par le{' '}
            {funders.find((p) => p.code === form.data.funding_organism?.code)?.label || '-'}
          </Typography>
          {form.data.funding_organism?.code === 'FIFPL' && (
            <Typography variant="caption">
              Cette prise en charge par le FIF-PL est conditionnée à la validation de la formation et à l'existence d'un
              budget suffisant.
            </Typography>
          )}
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', textDecorationLine: 'line-through', fontWeight: 500 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(
            form.data.product_catalog?.funding_price ?? form.data.product_catalog?.listing_price ?? listing_price,
          ) || '-'}
        </Typography>
      </Grid>

      <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="body2">Reste à charge</Typography>
        </Grid>
        <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 500 }}>
          {Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 2,
          }).format(form.data.personal_funding) || '-'}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Grid item container direction="row" justifyContent="space-between" wrap="nowrap">
      <Grid item>
        <Typography variant="body2">Prix de la formation</Typography>
        <Typography variant="caption">
          Cette prise en charge par le FIF-PL est conditionnée à la validation de la formation et à l'existence d'un
          budget suffisant.
        </Typography>
      </Grid>
      <Typography variant="body1" sx={{ textAlign: 'right', textDecorationLine: 'line-through', fontWeight: 500 }}>
        {Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 0,
        }).format(
          form.data.product_catalog?.funding_price ?? form.data.product_catalog?.listing_price ?? listing_price,
        ) || '-'}
      </Typography>
    </Grid>
  )
}
