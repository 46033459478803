import { useEffect, useMemo, useState } from 'react'
import { useDropzone, FileRejection } from 'react-dropzone'
import S3 from 'aws-sdk/clients/s3'
import { LinearProgress, Grid, Typography, SxProps, IconButton, useTheme } from '@mui/material'
import { sluggify } from '../utils/misc'
import { TiDeleteOutline } from 'react-icons/ti'

type DropZonePropsType = {
  message?: string
  maxFiles: number
  maxSize: number
  accept: Record<string, string[]>
  bucketName: string
  sx?: SxProps
  onUploaded: (data: string) => void
  folderPath?: string
  required?: boolean
}

const DropZone = ({
  message = 'Déposez votre fichier ou cliquez ici',
  maxFiles,
  maxSize,
  accept,
  bucketName,
  sx,
  onUploaded,
  folderPath,
  required = false,
}: DropZonePropsType) => {
  const theme = useTheme()
  const s3Client = new S3({
    region: 'eu-west-3',
    accessKeyId: 'AKIA4H7OH6OCESEFYDPQ',
    secretAccessKey: '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',
  })

  const errorMapping: Record<string, string> = {
    'file-invalid-type': 'Fichier non valide',
    'file-too-large': 'Fichier trop volumineux',
    'file-too-small': 'Fichier trop petit',
    'too-many-files': 'Fichiers trop nombreux',
  }

  const { isFocused, isDragAccept, isDragReject, acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: maxFiles,
      maxSize: maxSize * 1e6,
      accept: accept,
    })

  const baseStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: '4px!important',
    borderColor: theme.palette.grey[500],
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    ...sx,
  }

  const focusedStyle = {
    borderColor: theme.palette.primary.main,
  }

  const acceptStyle = {
    borderColor: theme.palette.success.light,
  }

  const rejectStyle = {
    borderColor: theme.palette.error.main,
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  const [dropedFiles, setDropedFiles] = useState<File[]>([])
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])
  const [progress, setProgress] = useState<Record<string, number>>({})

  useEffect(() => {
    setDropedFiles((prevState) => {
      if (maxFiles <= prevState.length) return prevState
      // acceptedFiles => add to dropped files if not already there AND not in excess of maxFiles
      const filteredFiles = acceptedFiles.filter((file) => !dropedFiles.map((f) => f.name).includes(file.name))
      return [...prevState, ...filteredFiles]
    })
  }, [acceptedFiles])

  useEffect(() => {
    setRejectedFiles((prevState) => {
      const filteredFiles = fileRejections.filter(
        (fileError) => !rejectedFiles.map((f) => f.file.name).includes(fileError.file.name),
      )
      return prevState?.concat(filteredFiles)
    })
  }, [fileRejections])

  useEffect(() => {
    dropedFiles.map((file) => {
      uploadFile(file)
    })
  }, [dropedFiles])

  const removeFile = (fileName: string) => {
    setDropedFiles((prevState) => {
      return prevState.filter((file) => file.name !== fileName)
    })
    setRejectedFiles((prevState) => {
      return prevState.filter((f) => f.file.name !== fileName)
    })
  }

  const uploadFile = (file: File) => {
    const filePath = folderPath ? `${folderPath}/${sluggify(file.name)}` : sluggify(file.name)
    s3Client
      .putObject({
        ACL: 'public-read',
        Body: file,
        Bucket: bucketName,
        Key: filePath,
        ContentType: file.type,
      })
      .on('httpUploadProgress', (evt) => {
        setProgress((prevState) => {
          return {
            ...prevState,
            [file.name]: Math.round((evt.loaded / evt.total) * 100),
          }
        })
      })
      .on('success', () => {
        console.log('success', `https://${bucketName}.s3.eu-west-3.amazonaws.com/${filePath}`)
        onUploaded(`https://${bucketName}.s3.eu-west-3.amazonaws.com/${filePath}`)
      })
      .send((err) => {
        if (err) console.error(err)
      })
  }

  return (
    <Grid id="dropZone" item container xs={12} sx={style} {...getRootProps({ className: 'DropZone' })}>
      <input {...getInputProps()} />
      {dropedFiles.length === 0 && rejectedFiles.length === 0 && (
        <Grid item container direction="column" alignItems="center" justifyContent="center">
          <Typography variant="body1" style={{ textAlign: 'center', color: theme.palette.text.primary }}>
            {message}
            {required ? ' *' : ''}
          </Typography>
          <Typography variant="caption">{Object.values(accept).flat().join(', ')}</Typography>
        </Grid>
      )}
      {rejectedFiles.length !== 0 && (
        <Grid item container direction="row" justifyContent="center" alignItems="center">
          {rejectedFiles.map((rejection) => {
            return (
              <Grid
                item
                key={rejection.file.name}
                sx={{
                  background: theme.palette.background.default,
                  p: 1,
                  m: 1,
                  borderRadius: '4px!important',
                  position: 'relative',
                }}
              >
                <Typography variant="body2">{rejection.file.name}</Typography>
                {rejection.errors.map((error) => {
                  return (
                    <Typography key={error.code} variant="caption" sx={{ color: theme.palette.error.main }}>
                      {errorMapping[error.code] || 'Erreur fichier'}
                    </Typography>
                  )
                })}
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', top: -12, right: -12 }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeFile(rejection.file.name)
                  }}
                >
                  <TiDeleteOutline />
                </IconButton>
              </Grid>
            )
          })}
        </Grid>
      )}

      {dropedFiles.length !== 0 && (
        <Grid item container direction="row" justifyContent="center" alignItems="center">
          {dropedFiles.map((file) => {
            return (
              <Grid
                item
                key={file.name}
                sx={{
                  background: theme.palette.background.default,
                  padding: theme.spacing(1),
                  margin: theme.spacing(1),
                  borderRadius: '4px!important',
                  position: 'relative',
                }}
              >
                <Typography variant="body2">{file.name}</Typography>
                <LinearProgress variant="determinate" value={progress[file.name] || 0} />
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', top: -12, right: -12, cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    removeFile(file.name)
                  }}
                >
                  <TiDeleteOutline />
                </IconButton>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Grid>
  )
}

export default DropZone
