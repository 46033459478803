import {
  Grid,
  Typography,
  useTheme,
  Link,
  Divider,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { DateField } from '@mui/x-date-pickers'
import { useState, useRef, useEffect } from 'react'
import { MdNavigateNext, MdPhoneEnabled } from 'react-icons/md'
import { FormType } from '../../utils/sharedTypes'
import { isStepValid } from '../../utils/misc'
import { phoneNumberValidator } from '../../utils/validators'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { IoOpenOutline } from 'react-icons/io5'
import CPFLogo from '../../assets/CPFLogo.png'

type CpfStatusYesPropsType = {
  form: FormType
  setForm: React.Dispatch<React.SetStateAction<FormType>>
}

const CpfStatusYes = ({ form, setForm }: CpfStatusYesPropsType) => {
  const navigate = useNavigate()
  const tutorialRef = useRef<HTMLIFrameElement>(null)
  const stepRef = useRef<HTMLDivElement>(null)
  const [validated, setValidated] = useState(false)
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const submitFinancement = () => {
    client
      .patch(`/registration/${form.data.global_id}/submit/`, {
        ...form.data,
        appointment_dt: null,
      })
      .then((resp) => {
        if (resp.status === 200) {
          setValidated(true)
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  useEffect(() => {
    if (validated) {
      stepRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [validated])

  const nextThirthyBusinessDays = Array.from({ length: 30 }, (_, i) => {
    const tmp = new Date()
    tmp.setDate(tmp.getDate() + i)
    return tmp
  }).filter((day) => {
    return day.getDay() !== 0 && day.getDay() !== 6
  })

  const listOfDays = nextThirthyBusinessDays.filter((_, index) => {
    return index < 14
  })

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break
        default:
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  return (
    <>
      <Grid>
        <Typography variant="body1" fontWeight="500">
          Merci de renseigner le numéro de téléphone lié à votre CPF
        </Typography>

        <TextField
          type="tel"
          fullWidth
          id="mobile_phone"
          label="N° de mobile"
          variant="outlined"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <MdPhoneEnabled size="20" />
              </InputAdornment>
            ),
          }}
          value={form.data.mobile_phone}
          onChange={(e: React.FocusEvent<HTMLInputElement>) => {
            handleChange('mobile_phone', e?.target.value)
          }}
          error={Boolean(form.errors.mobile_phone)}
          helperText={form.errors.mobile_phone}
          sx={{
            my: 2,
          }}
          disabled={validated}
        />

        <Typography variant="body1" fontWeight="500">
          A quelle date souhaitez-vous démarrer votre formation ? (date minimale :{' '}
          {new Date(listOfDays[listOfDays.length - 1].setDate(listOfDays[listOfDays.length - 1].getDate() + 1))
            .toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })
            .replace(/\s/g, '\u00A0')}
          )
        </Typography>
        <DateField
          id="cpf_starting_date"
          label="Date début de session"
          format="DD/MM/YYYY"
          disablePast
          required
          disabled={validated}
          value={form.data.cpf_starting_date ? dayjs(form.data.cpf_starting_date) : null}
          onChange={(value) => {
            if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
              handleChange('cpf_starting_date', dayjs(value).format('YYYY-MM-DD'))

              if (!dayjs(value).isValid()) {
                setForm((prevState) => {
                  return {
                    ...prevState,
                    errors: {
                      ...prevState.errors,
                      cpf_starting_date: 'Veuillez entrer une date de session valide',
                    },
                  }
                })
              }

              if (dayjs(value).isBefore(dayjs(listOfDays[listOfDays.length - 1]), 'days')) {
                setForm((prevState) => {
                  return {
                    ...prevState,
                    errors: {
                      ...prevState.errors,
                      cpf_starting_date: "La date de session doit être au moins 14 jours ouvrés après aujourd'hui",
                    },
                  }
                })
              }
            }
          }}
          fullWidth
          helperText={form.errors.cpf_starting_date}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                fullWidth
                error={Boolean(form.errors.cpf_starting_date)}
                helperText={form.errors.cpf_starting_date}
              />
            ),
          }}
          sx={{
            my: 2,
          }}
        />

        {!validated && (
          <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button
              variant="contained"
              sx={{
                height: {
                  xs: 45,
                },
              }}
              disabled={!isStepValid('financement', form)}
              onClick={submitFinancement}
            >
              Valider
            </Button>
          </Grid>
        )}
      </Grid>

      {validated && (
        <div ref={stepRef}>
          <Grid
            sx={{
              mt: 2,
            }}
          >
            <Typography variant="body1" fontWeight="500">
              Découvrez toutes les étapes pour vous inscrire à votre formation
            </Typography>
            <Typography variant="caption">
              N'hésitez pas à{' '}
              <Link
                onClick={() => {
                  tutorialRef.current?.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                visualiser le tuto vidéo de pré-inscription Mon Compte Formation - CPF
              </Link>
            </Typography>
          </Grid>

          <Grid container direction="column" sx={{ mt: 4 }}>
            <Grid container direction="row" wrap="nowrap">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                  borderRadius: '4px',
                  background: theme.palette.primary.light,
                  mr: 2.5,
                }}
              >
                <Typography variant="body1" fontWeight="500">
                  1
                </Typography>
              </Grid>

              <Grid container direction="column">
                <Typography variant="body1" fontWeight="500">
                  Accéder à la formation {form.data.product?.name} sur Mon Compte Formation
                </Typography>

                <Grid container direction="row" wrap="nowrap" mt={2} alignItems="center">
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-around"
                    sx={{
                      background: theme.palette.info.light,
                      border: `1px solid ${theme.palette.info.main}`,
                      borderRadius: '4px',
                      height: 60,
                      width: {
                        md: 340,
                        xs: '100%',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    <Link
                      href={`https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/${
                        form.data.product?.cpf_action_string ?? ''
                      }/${form.data.product?.cpf_action_string ?? ''}`}
                      target="_blank"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        mx: 2,
                      }}
                    >
                      {form.data.product?.name}
                      <IoOpenOutline
                        size={24}
                        style={{
                          marginLeft: 10,
                          marginBottom: 4,
                        }}
                      />
                    </Link>
                  </Grid>

                  {!isLargeScreen && (
                    <img
                      src={CPFLogo}
                      alt="Logo CPF"
                      style={{
                        height: 40,
                        objectFit: 'contain',
                        marginLeft: 20,
                      }}
                    />
                  )}
                </Grid>

                <Divider sx={{ my: 2.5 }} />
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap" alignItems={!isLargeScreen ? 'baseline' : 'inherit'}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                  borderRadius: '4px',
                  background: theme.palette.primary.light,
                  mr: 2.5,
                }}
              >
                <Typography variant="body1" fontWeight="500">
                  2
                </Typography>
              </Grid>

              <Grid container direction="column">
                <Typography variant="body1" fontWeight="500">
                  Connectez-vous sur votre CPF, à l'aide de FranceConnect+ et l'Identité Numérique La Poste
                </Typography>
                <Divider sx={{ my: 2.5 }} />
              </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap" alignItems={!isLargeScreen ? 'baseline' : 'inherit'}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                  borderRadius: '4px',
                  background: theme.palette.primary.light,
                  mr: 2.5,
                }}
              >
                <Typography variant="body1" fontWeight="500">
                  3
                </Typography>
              </Grid>

              <Grid container direction="column">
                <Typography variant="body1" fontWeight="500">
                  De retour sur le site du CPF, cliquez sur "Demande de devis" en bas à droite de la page
                </Typography>

                <Divider sx={{ my: 2.5 }} />
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap">
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  minHeight: '40px',
                  borderRadius: '4px',
                  background: theme.palette.primary.light,
                  mr: 2.5,
                }}
              >
                <Typography variant="body1" fontWeight="500">
                  4
                </Typography>
              </Grid>

              <Grid container direction="column">
                <Typography variant="body1" fontWeight="500">
                  Acceptez les conditions et finalisez votre pré-inscription pour suivre la formation{' '}
                  {form.data.product?.name}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2.5 }} />
            <Grid sx={{ mt: 2 }}>
              <Typography
                variant="body1"
                fontWeight="500"
                sx={{
                  mb: 1,
                }}
              >
                Vous êtes perdu(e) ? Visionnez le tutoriel vidéo ci-dessous !
              </Typography>
              <Typography variant="body2">Pour vous accompagner pas à pas dans votre inscription</Typography>
            </Grid>
            <Grid item className="container" sx={{ mt: 2.5 }}>
              <iframe
                style={{
                  borderRadius: '4px',
                }}
                height="100%"
                width="100%"
                src="https://iframe.mediadelivery.net/embed/252149/de90802f-4563-4670-bb6c-5ddcaf387641"
                frameBorder="0"
                ref={tutorialRef}
              />
            </Grid>
            <Divider sx={{ my: 3 }} />

            <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              <Button
                variant="contained"
                disabled={!isStepValid('financement', form)}
                sx={{
                  height: {
                    xs: 45,
                  },
                }}
                endIcon={<MdNavigateNext />}
                onClick={() => {
                  navigate({ pathname: './../recommandation', search: location.search })
                }}
              >
                Valider
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default CpfStatusYes
